import http from "./http.js";

let apiUrl = "https://rechner.waizmannpro.de";

if (
  window.location.hostname == "localhost" ||
  window.location.hostname == "loc.prorechner.de"
) {
  apiUrl = "https://loc.prorechner.de";
}

if (window.location.hostname == "rechner.waizmannpro.de") {
  apiUrl = "https://rechner.waizmannpro.de";
}

export default {
  postChat(payload) {
    return http.post(apiUrl + `/postchat`, { payload });
  },
  postGuest(payload) {
    return http.post(apiUrl + `/postguest`, { payload });
  },
  postEmail(payload) {
    return http.post(apiUrl + `/postemail`, { payload });
  },
  postBerechnung(payload) {
    return http.post(apiUrl + `/postberechnung`, { payload });
  },
  postVergleich(payload) {
    return http.post(apiUrl + `/postvergleich`, { payload });
  },
  postTarif(payload) {
    //return http.post(`https://api.pro.de/tarif`, { payload });
    return http.post(apiUrl + `/posttarif`, { payload });
  },
  postMixed(payload) {
    return http.post(apiUrl + `/postmixed`, { payload });
  },
};
