<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <v-card rounded="0" @click="startRechner('erwachsene')">
          <v-sheet color="#2196f3" height="200px" class="d-flex align-end">
            <v-card-title class="text-white pa-4">
              <span style="font-size: 14px"
                >Schwerpunkt Zahnersatz & Zahnerhalt</span
              ><br />
              Zahnzusatzversicherung <br />Erwachsene
            </v-card-title>
          </v-sheet>

          <v-card-actions>
            <h2>Vergleich starten <sup class="text-green">Tipp</sup></h2>
            <v-spacer></v-spacer>

            <v-btn color="medium-emphasis" icon="mdi-play"></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card rounded="0" @click="startRechner('kind')">
          <v-sheet color="#fecf0c" height="200px" class="d-flex align-end">
            <v-card-title class="text-white pa-4">
              <span style="font-size: 14px"
                >Schwerpunkt KFO & Kinderleistungen</span
              ><br />
              Zahnzusatzversicherung <br />Kinder
            </v-card-title>
          </v-sheet>

          <v-card-actions>
            <h2>Vergleich starten</h2>
            <v-spacer></v-spacer>

            <v-btn color="medium-emphasis" icon="mdi-play"></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card rounded="0" @click="startRechner('60plus')">
          <v-sheet color="#513B83" height="200px" class="d-flex align-end">
            <v-card-title class="text-white pa-4">
              <span style="font-size: 14px"
                >Schwerpunkt Zahnerhalt & Prothetik</span
              ><br />
              Zahnzusatzversicherung <br />60 Plus
            </v-card-title>
          </v-sheet>

          <v-card-actions>
            <h2>Vergleich starten</h2>
            <v-spacer></v-spacer>

            <v-btn color="medium-emphasis" icon="mdi-play"></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import {} from "vue";
  import { useRouter } from "vue-router";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();

  const router = useRouter();

  function startRechner(segment) {
    switch (segment) {
      case "erwachsene":
        router.push("/vergleich/starten");
        break;
      case "kind":
        router.push("/vergleich/starten/kind");
        break;
      case "60plus":
        router.push("/vergleich/starten/60plus");
        break;
      default:
        console.error("Unbekanntes Segment:", segment);
    }
  }
</script>
