<template>
  <div>
    <WaizmannWert
      v-if="berechnung.ber_segment <= 1 && berechnung.ber_algo_view != 2"
      :tarif="tarif"
      :berechnung="berechnung"
    />
    <KinderWert
      v-if="berechnung.ber_segment <= 1 && berechnung.ber_algo_view == 2"
      :tarif="tarif"
      :berechnung="berechnung"
    />
    <WaizmannWertBrille
      v-if="berechnung.ber_segment == 2"
      :tarif="tarif"
      :berechnung="berechnung"
    />
    <WaizmannWertHeil
      v-if="berechnung.ber_segment == 3"
      :tarif="tarif"
      :berechnung="berechnung"
    />
    <WaizmannWertKrank
      v-if="berechnung.ber_segment == 4"
      :tarif="tarif"
      :berechnung="berechnung"
    />
  </div>
</template>

<script setup>
  import { defineProps } from "vue";

  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();
  import WaizmannWert from "@/components/tarif/zahn/WaizmannWert";
  import WaizmannWertBrille from "@/components/tarif/brille/WaizmannWertBrille";
  import WaizmannWertHeil from "@/components/tarif/heilpraktiker/WaizmannWertHeil";
  import WaizmannWertKrank from "@/components/tarif/krank/WaizmannWertKrank";
  import KinderWert from "@/components/tarif/zahn/KinderWert";
  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
