<template>
  <div>
    <v-row v-for="feature in tarifFeatures" :key="feature.id">
      <v-col cols="12" md="4">{{ feature.title }}</v-col>
      <v-col cols="12" md="8">{{
        formatFeatureValue(tarif[feature.value])
      }}</v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
  </div>
</template>

<script setup>
  import { ref, defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });

  function formatFeatureValue(value) {
    if (value === 0) {
      return "Nein.";
    } else if (value === 1) {
      return "Ja.";
    } else {
      return value;
    }
  }

  const tarifFeatures = ref([
    { id: 2, title: "Wartezeit", value: "kh_wz" },
    { id: 3, title: "1/2-Bett-Zimmer", value: "kh_bett" },
    { id: 4, title: "Freie Krankenhauswahl", value: "kh_fkhwahl" },
    {
      id: 5,
      title: "Tagegeld bei Verzicht auf 1/2-Bett-Zimmer",
      value: "kh_tgverzbett",
    },
    {
      id: 6,
      title: "Tagegeld bei Verzicht auf Chefarztbehandlung",
      value: "kh_tgverzca",
    },
    { id: 7, title: "Leistung bei ambulanten OPs", value: "kh_ambops" },
    {
      id: 8,
      title: "Leistung bei Vor- und Nachbehandlungen",
      value: "kh_vornachbeh",
    },
    {
      id: 9,
      title: "Leistung bis zu einem bestimmten Höchstsatz",
      value: "kh_hoechstsatz",
    },
    { id: 10, title: "Leistung bei Kur oder Reha", value: "kh_kurreha" },
    { id: 11, title: "Leistung in Privatklinik", value: "kh_privatklinik" },
    { id: 12, title: "Leistung in Europa", value: "kh_europa" },
    { id: 13, title: "Leistung weltweit", value: "kh_welt" },
    {
      id: 14,
      title: "Leistung für Mehrkosten bei Krankentransport",
      value: "kh_ktransport",
    },
    {
      id: 15,
      title: "Leistung für Krankenrücktransport aus Ausland",
      value: "kh_ktransport_ausland",
    },
    { id: 16, title: "Mitteilungsfrist", value: "kh_frist" },
    { id: 17, title: "Karte zur Anmeldung / Abrechnung", value: "kh_karte" },
  ]);
  console.log(props.tarif);
</script>
