<template>
  <div>
    <span style="font-size: 18px"
      >Als <strong>"Prothesen"</strong> anzugeben sind</span
    >
    <br />
    <ul>
      <li>
        lediglich Prothesen, also <strong>herausnehmbarer Zahnersatz</strong>.
        Hier muss angegeben werden, wie viele Zähne durch derartige
        (Teil-)Prothesen ersetzt sind. Die Anzahl kann einfach gezählt werden,
        wenn die Prothese nicht mehr im Mund befindlich ist.
      </li>
    </ul>
    <span style="font-size: 18px"><strong>"nicht anzugeben"</strong> sind</span>
    <br />
    <ul>
      <li><strong>fehlende</strong>, also nicht ersetzte Zähne</li>
      <li>
        <strong>anderweitig ersetzte Zähne</strong>, wie z. B. durch Implantate,
        Brücken oder Kronen.
      </li>
    </ul>
  </div>
</template>

<script></script>

<style scoped></style>
