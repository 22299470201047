<template>
  <div>
    <v-row>
      <v-col cols="12" md="12"
        ><h2>Zahnzusatzversicherungen & private Krankenversicherung</h2>
        Zahnzusatzversicherungen können nur nur als zusätzliche Versicherung
        abgeschlossen werden, wenn Sie in der gesetzliche Krankenkasse
        versichert werden. Wenn Sie Ihren Versicherungsschutz verbessern wollen,
        können Sie versuchen den "Zahnarzt" Schutz in Ihrer bestehenden privaten
        Krankenversicherung aufzustocken. Die meisten Gesellschaften bieten
        solche Optionen an.
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
