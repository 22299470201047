<template>
  <div>
    <span v-if="tarif.show_begrenzung_vorteil">
      <h2>
        <span class="text-green">Experten-Tipp:</span> Jetzt schneller
        {{ tarif.tarif_int_begrenzung_1 }}€ höhere Erstattung sichern!
      </h2>

      Wenn Sie den Vertrag noch mit Versicherungsbeginn in diesem Kalenderjahr
      abschließen, hat das den Vorteil, daß Sie am 1. Januar des Folgejahres
      bereits im 2. Versicherungsjahr sind.
      <br />
      Dadurch sparen Sie ein Jahr der anfänglichen Summenbegrenzung. Sie können
      dadurch bereits nächstes Jahr
      {{ tarif.tarif_int_begrenzung_2 }} (statt
      {{ tarif.tarif_int_begrenzung_1 }} €) in Anspruch nehmen. Sie gewinnen
      dadurch
      <strong class="text-green"
        >{{ tarif.tarif_int_begrenzung_1 }} € zusätzliche Erstattung</strong
      >. <br /><br />
    </span>

    <h2>Anfängliche Leistungsbegrenzungen im Detail</h2>
    <div class="body-1" v-html="nl2br(tarif.tarif_beschreibung_beg)"></div>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  import { useNl2Br } from "@/composable/useNl2Br";
  const { nl2br } = useNl2Br();
  //   import { useBerechnungStore } from "@/stores/berechnung";
  //   import { useSharedDataStore } from "@/stores/sharedData";
  //   const sharedDataStore = useSharedDataStore();
  //   const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
