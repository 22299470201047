<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2>WaizmannWert - Sehhilfen Durchschnittserstattung</h2>
        Der WaizmannWert Sehhilfen gibt die Durchschnittserstattung einer
        Sehhilfen-Zusatzversicherungen auf Basis eines Warenkorbes von
        Muster-Behandlungsverläufen an.
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
