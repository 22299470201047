<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" class="pl-2">
        <h2>Waizmann KFOWert | KFO Ø-Erstattung</h2>
        Auf Basis eines standardisierten Behandlunsplanes haben wir für die
        jeweiligen kieferorthopädischen Indikationsgruppen die durchschnittliche
        Erstattung ermittelt. Das Berechnungsverfahren berücksichtigt:
        <ul>
          <li>die vertraglich zugesicherten Erstattungs-Prozentsätze</li>
          <li>die vertraglichen Leistungsgrenzen</li>
          <li>die von Tarif zu Tarif unterschiedlichen Erstattungsmethoden</li>
        </ul>
        <br />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" class="text-bold">Waizmann KFO-Wert</v-col>
      <v-col cols="12" md="8">
        <b>{{ tarif.tarif_kwert_gesamt }} %</b> <br />KFO Ø-Erstattung
        <br />
        <span class="caption"
          >Bei Gewichtung KIG 1-2 und KIG 3-5, jeweils zu 50 %.</span
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Ø-Erstattung KIG 1-2</v-col>
      <v-col cols="12" md="8">
        <b>{{ tarif.tarif_kwert_1 }} %</b>
        <br />
        <span class="caption">Hohe Eigenanteile (bis 10.000 €)</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Ø-Erstattung KIG 1-2</v-col>
      <v-col cols="12" md="8">
        <b>{{ tarif.tarif_kwert_3 }} %</b>
        <br />
        <span class="caption"
          >Niedriger Eigenanteile, dafür in der Praxis häufiger (1.500-2000
          €)</span
        >
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
