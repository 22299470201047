<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">1/2-Bett-Zimmmer:</v-col>
      <v-col cols="12" md="8">
        <strong v-if="tarif.kh_bett == 1">Ja</strong>
        <strong v-if="tarif.kh_bett != 1">Nein</strong>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12" md="4">Chefarztbehandlung:</v-col>
      <v-col cols="12" md="8">
        <strong v-if="tarif.kh_ca == 1">Ja</strong>
        <strong v-if="tarif.kh_ca != 1">Nein</strong>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12" md="4">Wartezeit:</v-col>
      <v-col cols="12" md="8">
        {{ tarif.kh_wz }}
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
