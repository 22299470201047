<template>
  <div class="pa-4">
    <v-row>
      <v-col cols="12" md="8">
        <h2 class="text-blue mb-4">
          {{ tarif.un_name }} {{ tarif.tarif_title }} |
          {{ tarif.preis_value }} €
        </h2>
        <WaizmannWert :tarif="tarif" :berechnung="berechnung" />
        <BeschreibungHeader :tarif="tarif" :berechnung="berechnung" />
        <AntragLink class="my-4" :tarif="tarif" :berechnung="berechnung" />

        <LueckeLeistung
          v-if="
            berechnung.ber_segment <= 1 &&
            berechnung.ber_algo_view != 2 &&
            berechnung.ber_alter > 17 &&
            berechnung.ber_luecke > 0 &&
            berechnung.ber_luecke_vers == 1
          "
          :tarif="tarif"
          :berechnung="berechnung"
          class="mt-6"
        />
        <WechselOption
          v-if="berechnung.ber_wechsel == 1 && tarif.tarif_wechsel_angebot > 0"
          :tarif="tarif"
          :berechnung="berechnung"
          class="mt-6"
        />
        <SofortLeistung
          v-if="tarif.sofortversichert"
          :tarif="tarif"
          :berechnung="berechnung"
          class="mt-6"
        />
        <EckDatenZahn
          v-if="berechnung.ber_segment <= 1"
          :tarif="tarif"
          :berechnung="berechnung"
          class="mt-6"
        />

        <ZahnErsatz
          v-if="berechnung.ber_segment <= 1"
          :tarif="tarif"
          :berechnung="berechnung"
          class="mt-6"
        />
        <ZahnErhalt
          v-if="berechnung.ber_segment <= 1"
          :tarif="tarif"
          :berechnung="berechnung"
          class="mt-6"
        />
        <HighTech
          v-if="berechnung.ber_segment <= 1"
          :tarif="tarif"
          :berechnung="berechnung"
          class="mt-6"
        />
        <KfoGross
          v-if="berechnung.ber_segment <= 1"
          :tarif="tarif"
          :berechnung="berechnung"
          class="mt-6"
        />
        <EckDatenBrille
          v-if="berechnung.ber_segment == 2"
          :tarif="tarif"
          :berechnung="berechnung"
          class="mt-6"
        />
        <EckDatenHeil
          v-if="berechnung.ber_segment == 3"
          :tarif="tarif"
          :berechnung="berechnung"
          class="mt-6"
        />
        <EckDatenKrank
          v-if="berechnung.ber_segment == 4"
          :tarif="tarif"
          :berechnung="berechnung"
          class="mt-6"
        />
        <AntragLink class="my-4" :tarif="tarif" :berechnung="berechnung" />
      </v-col>
      <v-col cols="12" md="4"
        ><HighlightsTarif :tarif="tarif" />
        <ClearmentLsk
          v-if="berechnung.ber_segment <= 1 && tarif.tarif_clearment_lsk > 0"
          :tarif="tarif"
        />
        <AvbDownloads
          :tarif="tarif"
          :berechnung="berechnung"
          showSignup="all"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  import BeschreibungHeader from "@/components/tarif/mini/BeschreibungHeader";
  import AntragLink from "@/components/tarif/mini/AntragLink";
  import HighlightsTarif from "@/components/tarif/mini/HighlightsTarif";
  import WaizmannWert from "@/components/tarif/mini/WaizmannWert";
  import EckDatenZahn from "@/components/tarif/mini/zahn/EckDaten";
  import WechselOption from "@/components/tarif/mini/zahn/WechselOption";
  import SofortLeistung from "@/components/tarif/mini/zahn/SofortLeistung";
  import LueckeLeistung from "@/components/tarif/mini/zahn/LueckeLeistung";
  import ZahnErsatz from "@/components/tarif/mini/zahn/ZahnErsatz";
  import ZahnErhalt from "@/components/tarif/mini/zahn/ZahnErhalt";
  import KfoGross from "@/components/tarif/mini/zahn/KfoGross";
  import HighTech from "@/components/tarif/mini/zahn/HighTech";
  import ClearmentLsk from "@/components/tarif/mini/ClearmentLsk";
  import AvbDownloads from "@/components/tarif/mini/AvbDownloads";
  import EckDatenBrille from "@/components/tarif/mini/brille/EckDaten";
  import EckDatenHeil from "@/components/tarif/mini/heil/EckDaten";
  import EckDatenKrank from "@/components/tarif/mini/krank/EckDaten";
  //   import { useBerechnungStore } from "@/stores/berechnung";
  //   import { useSharedDataStore } from "@/stores/sharedData";
  //   const sharedDataStore = useSharedDataStore();
  //   const berechnungStore = useBerechnungStore();
  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log("tarifView" + props.tarif.tarif_id);
</script>
