import { reactive, readonly } from "vue";

const state = reactive({
  tooltips: {
    tarif_waizmannwert: {
      title: "Durchschnittserstattung | 8-Jahres Realwert-Methode",
      text: "Mit der von uns entwickelten 8-Jahres Realwert-Methode ermitteln wir die reale Durchschnittserstattung einer Zahnzusatzversicherung. Die 8-Jahres Realwert-Methode basiert auf einem zahnmedizinischen Warenkorb, der in Zusammenarbeit mit Zahnärzten realitätsnah befüllt wurde.  Er setzt sich aus unterschiedlichen zahnärztlichen Leistungen über einen Zeitraum von jeweils 4 Jahren zusammen und besteht jeweils zu 50% aus Leistungen der Bereiche Zahnersatz und Zahnerhalt. <br><br>  Das Ergebnis der 8-Jahres Realwert-Methode gibt an, wie viel Prozent des Eigenanteils aus diesem Warenkorb die jeweilige Zahnzusatzversicherung erstatten würde.",
    },
    tarif_hightech: {
      title: "Zahnmedizinische Hightech-Leistungen",
      text: "Anzahl der im Tarif eingeschlossenen HighTech-leistungen wie z.B. Laserbehandlung. ",
    },
    tarif_kinderleistungen: {
      title: "Kinder/KFO Leistungen",
      text: "Anzahl der im Tarif eingeschlossenen Kinder/KFO-Leistungen wie z.B. Invisalign.",
    },
    tarif_lsk: {
      title: "Clearment Leistungs-Zertififzierung",
      text: "Je höher die Leistungs-Sicherheitsklasse LSK 1 - 5 desto klarer und genauer wurden die Leistungen der Versicherung definiert. Das erhöht Ihre Leistungssicherheit.",
    },
    tarif_vs: {
      title: "Clearment Vertragsschutz hilft",
      text: "Der Clearment Vertragsschutz hilft Ihnen Ihre Ansprüche durchzusetzen. Bei Problemen mit einer Erstattung hilft Ihnen Clearment Ihr Geld zu bekommen.",
    },
    tarif_aaa: {
      title: "Anonymer AntragAnnahmecheck",
      text: "Sie erfahren sofort online welche Versicherung Ihren Versicherungsantrag annehmen bzw. ablehnen wird. Der von uns entwickelte AntragsAnnahmecheck prüft anonym die internen Annahmebedingungen der Versicherung ab.",
    },
    hp_gesamterstattung_2_4: {
      title: "WaizmannWert Heilpraktiker Durchschnittserstattung",
      text: "Der WaizmannWert Heilpraktiker gibt die Durchschnittserstattung der Naturheilkunde Leistungen auf Basis eines Warenkorbes von Muster-Behandlungsverläufen an. Dieser ist realitätsnah zusammengestellt und setzt sich aus jeweils 4 Jahren mit verschiedenen Erst- und Folgebehandlungen zusammen. Dieser umfasst klassische Anwendungen aus den Bereichen Homöopathie,Akupunktur, Chiropraktik, Osteopathie.   Der WaizmannWert Heilpraktiker gibt die prozentuale Erstattungshöhe des jeweiligen Heilpraktiker-Zusatztarifes in Bezug auf den Warenkorb wieder. ",
    },
    kh_ww: {
      title: "WaizmannWert Krankenhaus-Zusatzversicherung",
      text: "Der Krankenhaus-WaizmannWert ist ein Analyseverfahren zur Bewertung von Krankenhausversicherungen und zeigt die Leistungsstärke der einzelnen Krankenhaus-Tarife übersichtlich und anschaulich. Der KH-WaizmannWert wird aus einem Punktesystem errechnet, bei dem die einzelnen Leistungen einer stationären Zusatzversicherung, wie z.B. Ein-/Zweibettzimmer oder Chefarztbehandlung, mit unterschiedlich hohen Punktwerten be- und gewertet werden. Der Krankenhaus-Zusatztarif mit der höchsten Punktzahl erhält dabei 100% KH-WaizmannWert, die Krankenhausversicherungen mit einer geringeren Punktzahl erhalten entsprechend umgerechnet einen Krankenhaus-WaizmannWert kleiner 100%. ",
    },
    bri_ww: {
      title: "WaizmannWert - Sehhilfen Durchschnittserstattung",
      text: "Der WaizmannWert Sehhilfen gibt die Durchschnittserstattung einer Sehhilfen-Zusatzversicherungen auf Basis eines Warenkorbes von Muster-Behandlungsverläufen an.  ",
    },
    tarif_kwert_gesamt: {
      title: "KFO-Wert - Durchschnittserstattung Kieferorthopädie",
      text: "Dieser Wert gibt die Durchschnittserstattung für kieferorthopädische Leistungen  an. Dieser Wert wurde mit Hilfe des Waizmann Benchmarkverfahrens ermittelt.",
    },
    tarif_kwert_1: {
      title: "Durchschnittserstattung Kieferorthopädie KIG 1-2",
      text: "Dieser Wert gibt die Durchschnittserstattung für kieferorthopädische Leistungen bei KIG 1-2 an. Bei einer Einstufung in KIG 1-2 leistet die GKV nicht.",
    },
    tarif_kwert_3: {
      title: "Durchschnittserstattung Kieferorthopädie KIG 3-5",
      text: "Dieser Wert gibt die Durchschnittserstattung für kieferorthopädische Leistungen bei KIG 3-5 an. Bei einer Einstufung in KIG 3-5 erstattet die GKV einen Teil der Rechnung.",
    },
    laser: {
      title: "Laser-Behandlung",
      text: "Mithilfe eines Lasers werden z. B. Wurzelkanäle effektiv und schmerzfrei desinfiziert und versiegelt",
    },
    mikro: {
      title: "OP-Mikroskop",
      text: "Mithilfe des OP-Mikroskops können kleine begrenzte Gebiete, wie z. B. Wurzelkanäle, stark vergrößert behandelt werden",
    },
    cerec: {
      title: "Cerec",
      text: "Verfahren zur direkten Herstellung von gefrästen Keramik-Restaurationen durch einen Computer",
    },
    dvt: {
      title: "Digitale Volumentomographie",
      text: "Dreidimensionales, bildgebendes Verfahren unter Nutzung von Röntgenstrahlen",
    },
    dna: {
      title: "Bakterien-/DNA-Test",
      text: "Wird im Rahmen einer Parodontitis-Behandlung durchgeführt um zu ermitteln, welche Bakterienstämme für die Erkankung verantwortlich sind",
    },
    nark: {
      title: "Vollnarkose",
      text: "Herbeiführen eines kontrollierten, schlafähnlichen Zustands, bei dem das Bewusstsein und das Schmerzempfinden im gesamten Körper ausgeschaltet werden",
    },
    aku: {
      title: "Akupunktur",
      text: "Nebenwirkungsarme, unterstützenden Behandlungsform ohne zusätzliche Medikamenteneinnahme zur Schmerzlinderung",
    },
    pact: {
      title: "Photoaktivierte Chemotherapie",
      text: "Sehr schonendes Verfahren zur Behandlung von Zahnbett-Erkrankungen mittels Farbstoff und Laser",
    },
    dros: {
      title: "DROS-SChiene",
      text: "Therapeutische Aufbiss-Schiene zur Behandlung von Kiefergelenksproblemen",
    },
    invisa: {
      title: "Invisalign",
      text: "Herausnehmbare und transparente Alternative zur festen Zahnsspange",
    },
    aufb: {
      title: "Aufbiss-Schienen",
      text: " Meistens ist damit die sog. Knirscherschiene gemeint, dient dem Schutz der Zahnsubstanz bei nächtlichem Knirschen",
    },
    vect: {
      title: "VECTOR-Technologie",
      text: "Unterstützende Behandlungsmethode im Rahmen der Behandlung einer Zahnbetterkrankung mittels Ultraschal",
    },
    kig1: {
      title: "KIG 1-2",
      text: "Die Krankenkasse leistet nicht, wenn bei Kindern und Jugendlichen unter 18 Jahren eine Einstufung in die kieferorthoädische Indikationsgruppe (KIG) 1-2 erfolgt",
    },
    kig3: {
      title: "KIG 3-5",
      text: "Die Krankenkasse leistet nicht, wenn bei Kindern und Jugendlichen unter 18 Jahren eine Einstufung in die kieferorthoädische Indikationsgruppe (KIG) 3-5 erfolgt",
    },
    fis: {
      title: "Fissurenversieglung",
      text: " Verschließen der Furchen und Grübchen (Fissuren) der Zähne mittels dünnflüssigem Kunststoff um diese glatter und somit leichter zu putzen sowie weniger anfällig für Karies zu machen        ",
    },
    kpzr: {
      title: "Kinder-Prophylaxe",
      text: "Vorbeugende Maßnahmen zur Verhinderung von Krankheiten der Zähne und des Zahnbettes, wichtigster Bestandteil ist die professionelle Zahnreinigung",
    },
    kfo_funk: {
      title: "Funktionsanlayse KFO",
      text: "Bewährtes Verfahren zur kieferorthopädischen Befunderhebung, Behandlungsvorbereitung und Therapiekontrolle",
    },
    mini_brack: {
      title: "Mini-Brackets",
      text: "Benötigen weniger Fläche auf dem Zahn und sind daher leichter zu reinigen, deshalb ist die Behandlung damit schonender - werden von der GKV nicht übernommen        ",
    },
    kunst_brack: {
      title: "Kunststoff-Brackets",
      text: "Zahnfarbene Brackets aus Kunststoff haben rein ästhetischen Charakter, mindern aufgrund der größeren Ausführung im Vergleich zu Metall-Brackets oft auch den Tragekomfort        ",
    },
    farbl_bogen: {
      title: "farblose Bögen",
      text: "Werden in Verbindung mit zahnfarbenen Kunststoff-Brackets verwendet und haben ebenfalls nur rein ästhetischen Charakter",
    },
    lingua: {
      title: "Lingualtechnik",
      text: "Feste Zahnspange auf der Innenseite der Zähne und deshalb für Außenstehende nicht sichtbar",
    },
    retainer: {
      title: "Retainer",
      text: " Dient der Fixierung erfolgreich kieferorthopädisch behandelter Zähne, gibt es in festsitzender und herausnehmbarer Ausführung",
    },
  },
});

// Funktion zum Abrufen von Tooltip-Daten
function getTooltip(key) {
  return (
    state.tooltips[key] || {
      title: "Information nicht verfügbar",
      text: "Die angeforderte Information konnte nicht gefunden werden.",
    }
  );
}

// Der Store, der exportiert wird, enthält die Methode zum Abrufen der Tooltips
// und gibt den Zustand als schreibgeschützt (readonly) aus.
export function useTooltipStore() {
  return {
    getTooltip,
    tooltips: readonly(state.tooltips),
  };
}
