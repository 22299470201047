<template>
  <div>
    <h2>Hier sind Ihre fehlenden Zähne "mitversichert"</h2>

    <br />

    <v-table>
      <thead>
        <tr style="vertical-align: top">
          <th class="text-left">Tarif</th>
          <th class="text-left">
            <strong>Preis</strong>
            <br />
            <span class="caption">Zuschlag</span>
          </th>
          <th class="text-left">
            <strong>Versicherung Luecke</strong>
          </th>

          <th v-if="isMobile" class="text-left">Mehr</th>
          <th class="text-left">Angebot</th>
        </tr>
      </thead>
      <tbody>
        <tr
          style="vertical-align: top"
          v-for="tarif in alletarife"
          :key="tarif.name"
        >
          <td>
            <router-link v-if="tarif.antraglink" :to="tarif.antraglink"
              ><b
                >{{ tarif.un_name }}
                <span v-if="tarif.empfehlung_type > 0">
                  <sup class="text-green">Tipp</sup>
                </span></b
              > </router-link
            ><br />
            {{ tarif.tarif_title }}
          </td>
          <td>
            <b>{{ tarif.preis_value }}€</b>
            <span v-if="tarif.zuschlag_zahn > 0" class="caption"
              ><br />inkl. {{ tarif.zuschlag_zahn }}€</span
            >
          </td>
          <td>
            <span v-if="berechnung.ber_luecke == 1">
              Ihr
              <strong>fehlender Zahn</strong> ist
            </span>
            <span v-if="berechnung.ber_luecke > 1">
              Ihre
              <strong>{{ berechnung.ber_luecke }} fehlenden Zähne</strong> sind
            </span>

            <span v-if="tarif.LueckeVersicherung == 1">
              <strong class="text-green">kostenlos mitversichert</strong>
              (bestmögliche Option).
            </span>
            <span
              v-if="
                tarif.LueckeVersicherung == 2 || tarif.LueckeVersicherung == 3
              "
            >
              gegen einen Zuschlag von {{ tarif.zuschlag_zahn }} €
              mitversichert. Der Zuschlag ist im Monatsbeitrag von
              {{ tarif.preis_value }} € bereits enthalten.
            </span>

            <span v-if="tarif.LueckeVersicherung == 4"
              >ohne Aufschlag mitversichert. Allerdings verlängern sich die
              anfänglichen Begrenzungen.</span
            >

            <span v-if="!tarif.LueckeVersicherung">
              <strong class="text-red">nicht mitversichert</strong>
            </span>
          </td>

          <td>
            <router-link v-if="tarif.antraglink" :to="tarif.antraglink">
              <b>Infopaket</b>
            </router-link>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  const props = defineProps({
    alletarife: {
      type: Array,
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
