<template>
  <div>
    <span style="font-size: 18px"
      >Mit <strong>"Ja"</strong> beantworten, wenn</span
    >
    <br />
    <br />
    Behandlungen mit dem Zahnarzt <strong>geplant</strong>, von diesem
    <strong>empfohlen</strong> oder aktuell
    <strong>durchgeführt</strong> werden. Zum Beispiel:
    <br />
    <ul>
      <li>der Ersatz fehlender Zähne</li>
      <li>Parodontalbehandlungen</li>
      <li>Wurzelbehandlungen</li>
      <li>
        funktionsanalytische- und therapeutische Maßnahmen zur Behandlung von
        Kiefergelenkserkrankungen
      </li>
      <li>die Erneuerung von bestehenden Füllungen</li>
      <li>Kronenversorgung</li>
      <li>
        die Regulierung einer Zahnfehlstellung mittels kieferorthopädischer
        Maßnahmen
      </li>
      <li>
        <strong class="text-green">Waizmann hilft:</strong> Unser Rechner zeigt
        Ihnen auch Tarife, die mit laufender Behandlung abschließbar sind.
      </li>
    </ul>
    <span style="font-size: 18px"
      >Mit <strong class="text-green">"Nein"</strong> beantworten, wenn</span
    >
    <br />
    <ul>
      <li>derzeit keine Behandlung laufen oder empfohlen wurden</li>
      <li>Sie eine Aufbiss-Schiene tragen</li>
      <li>Sie regelmäßig professionelle Zahnreinigungen durchführen lassen</li>
    </ul>
  </div>
</template>

<script></script>

<style scoped></style>
