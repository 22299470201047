<template>
  <div>
    <span style="font-size: 18px"
      >Mit <strong>"Ja"</strong> beantworten, wenn</span
    >
    <br />
    <ul>
      <li>
        in der Vergangenheit bereits einmal Behandlungsbedarf wegen
        Parodontose/Parodontitis <strong>ärztlich</strong> festgestellt wurde
      </li>
      <li>
        Sie sich aktuell in einer
        <strong>laufenden</strong> Parodontose-Behandlung befinden
      </li>
      <li>
        <strong class="text-green">Waizmann hilft:</strong> Unser Rechner zeigt
        Ihnen Tarife, die auch für vorhandene Aufbiss-Schienen leisten mit
        Parodontose abschließbar sind
      </li>
    </ul>
    <span style="font-size: 18px"
      >Mit <strong class="text-green">"Nein"</strong> beantworten, wenn</span
    >
    <br />
    <ul>
      <li>
        Ihre Parodontose-Behandlung als <strong>abgeschlossen</strong> in der
        Patienenakte vermerkt wurde
      </li>
      <li>
        <strong>keine Nachkontrollen oder Nachbehandlungen (Recall)</strong>
        mehr notwendig sind
      </li>
    </ul>
  </div>
</template>

<script></script>

<style scoped></style>
