<template>
  <v-app id="app">
    <LoaderOverlay />
    <ErrorOverlay />
    <!-- <span style="font-size: 10x"
      >{{ guestRef }} <br />{{ guestStore.guest }}</span
    > -->
    <div
      v-if="!stopMenue"
      class="ml-6 pa-0"
      :style="{
        position: 'fixed',
        top: adjustedTop + 'px',
        height: '74px',
        width: '60px',
        zIndex: 1000,
      }"
    >
      <!-- <v-row>
        <v-col cols="12">
          <v-icon
            @click="berechnungStore.setShowPopApp('menuedialog')"
            class="text-blue"
            style="font-size: 48px; cursor: pointer"
            >mdi-menu</v-icon
          >
        </v-col>
      </v-row> -->
      <v-row v-if="berechnungStore.berechnung">
        <v-col cols="12">
          <v-icon
            @click="berechnungStore.setShowPopApp('alleberechnungen')"
            class="text-blue"
            style="font-size: 48px; cursor: pointer"
            >mdi-cart</v-icon
          >
        </v-col>
      </v-row>
    </div>
    <v-main>
      <v-container fluid>
        <!-- fluid sorgt dafür, dass der Container die volle Breite hat -->
        <v-row justify="start">
          <v-col cols="12" style="max-width: 950px">
            <!-- max-width hier -->
            <router-view style="background-color: #fff; width: 100%" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-dialog v-model="berechnungStore.showPopApp" width="888">
      <v-card class="pa-4">
        <v-row
          ><v-col cols="11"></v-col
          ><v-col cols="1"
            ><v-btn icon @click="berechnungStore.closePopApp">
              <v-icon>mdi-close</v-icon>
            </v-btn></v-col
          ></v-row
        >
        <InfoPaket
          v-if="berechnungStore.showPopAppType == 'infopaket'"
          :tarif="berechnungStore.tarif"
          :berechnung="berechnungStore.berechnung"
        />
        <SofortPop
          v-if="berechnungStore.showPopAppType == 'sofortpop'"
          :tarif="berechnungStore.tarif"
          :berechnung="berechnungStore.berechnung"
        />
        <MenueDefault
          v-if="berechnungStore.showPopAppType == 'menuedialog'"
          :berechnung="berechnungStore.berechnung"
        />
        <erst-info-pop v-if="berechnungStore.showPopAppType == 'erstinfopop'" />
        <AlleBerechnungen
          v-if="berechnungStore.showPopAppType == 'alleberechnungen'"
        />
        <AltersrueckPop
          v-if="berechnungStore.showPopAppType == 'altersrueck'"
        />
        <ClearmentSchutz
          v-if="berechnungStore.showPopAppType == 'clearmentschutz'"
        />

        <ClearmentCheck
          v-if="berechnungStore.showPopAppType == 'clearmentcheck'"
        />
        <TarifOptions
          :tarif="berechnungStore.tarif"
          v-if="berechnungStore.showPopAppType == 'showTarifOptions'"
        />
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="berechnungStore.showPopChat" width="888">
      <ChatWindow v-if="berechnungStore.showPopChat" />
    </v-dialog> -->
  </v-app>
</template>

<script setup>
  import { ref, onMounted, nextTick, watch, watchEffect } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  import { useGuestStore } from "@/stores/guest";

  import LoaderOverlay from "@/components/base/LoaderOverlay.vue";
  import ErrorOverlay from "@/components/base/ErrorOverlay.vue";
  import AlleBerechnungen from "@/components/berechnung/vergleich/AlleBerechnungen";
  import InfoPaket from "@/components/berechnung/info/InfoPaket";
  import ErstInfoPop from "@/components/berechnung/info/ErstInfoPop";
  import AltersrueckPop from "@/components/berechnung/info/AltersrueckPop";
  import SofortPop from "@/components/tarif/info/SofortPop";
  // import MenueRechner from "@/components/berechnung/MenueRechner";
  // import MenueNeu from "@/components/berechnung/MenueNeu";
  import ClearmentSchutz from "@/components/tarif/info/ClearmentSchutz";
  import ClearmentCheck from "@/components/berechnung/info/ClearmentCheck";
  import TarifOptions from "@/components/tarif/TarifOptions";
  //import ChatWindow from "@/components/chat/ChatWindow";
  import { useRoute } from "vue-router";

  import { useLoaderStore } from "@/stores/loaderStore";
  //import { useRoute } from "vue-router";
  import { useErrorStore } from "@/stores/errorStore";
  const errorStore = useErrorStore();
  import BaseCalls from "@/services/BaseCalls";
  const loaderStore = useLoaderStore();
  // const route = useRoute();
  const route = useRoute();

  const berechnungStore = useBerechnungStore();
  const guestStore = useGuestStore();

  import MenueDefault from "@/components/site/MenueDefault.vue";

  // import LoaderOverlay from "@/components/base/LoaderOverlay.vue";
  // import SuccessOverlay from "@/components/base/SuccessOverlay.vue";

  const lastBerechnung = ref(false);

  const stopMenue = ref(false);
  const userId = ref(route.params.userid);
  const guestRef = ref("");
  const updateRef = ref(false);
  const guestIframe = ref(false);
  const tokenIframe = ref(false);
  const vmIframe = ref(false);
  const guestAlreadyThere = ref(false);
  const contentHeight = ref(0);

  const adjustedTop = ref(150);

  window.addEventListener("message", (event) => {
    if (event.data.type === "scrollUpdate") {
      adjustedTop.value = event.data.scrollPosition + 30; // Einfaches Beispiel: direkte Zuweisung
    } else if (event.data.type === "parentUrl") {
      if (event.data.url) {
        guestRef.value = event.data.url;
        console.log("guestRef Event: " + event.data.url);
      }
    } else if (event.data.type === "guestData") {
      if (event.data.token && event.data.guest) {
        guestIframe.value = event.data.guest;
        tokenIframe.value = event.data.token;
        vmIframe.value = event.data.vm;
        getGuestFromIframe();
        //console.log("iframeCookie recevied" + guestIframe.value);
      }
    }
  });

  // Funktion, um die Höhe des Inhalts zu messen
  function measureContentHeight() {
    const contentElement = document.getElementById("app");
    if (contentElement) {
      return contentElement.offsetHeight;
    }
    return 0;
  }

  function sendGuestToParent(vm, guest, token) {
    console.log("sendGuestdatatoParent");
    if (window.parent !== window) {
      const guestData = {
        vm: vm,
        guest: guest,
        token: token,
      };
      window.parent.postMessage({ guestData: guestData }, "*");
    } else {
      console.log("Nicht in einem iframe ausgeführt.");
    }
  }

  function sendHeightToParent(newHeight) {
    window.parent.postMessage(
      {
        frameHeight: newHeight,
      },
      "*"
    );
  }

  async function getLastBerechnung(guest_id) {
    let berSelect = {};
    berSelect.action = "getLastBerechnung";
    berSelect.ber_guest_id = guest_id;
    console.log("get berechnung " + berSelect.ber_guest_id);
    try {
      const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.berechnung) {
        lastBerechnung.value = resp.data.berechnung;
        berechnungStore.setBerechnung(resp.data.berechnung);
        console.log("last berechnung ");
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }

  async function updateGuestRef(guest_id) {
    let select = {};
    select.action = "updateGuestRef" + guest_id;
    console.log("updateGuestRef " + guestRef.value);
    select.guest_id = parseInt(guest_id);
    select.guest_ref = guestRef.value;
    BaseCalls.postChat(select);
    updateRef.value = true;
  }

  async function getGuestFromIframe() {
    let guestSelect = {};
    guestSelect.action = "getGuestFromIframe";
    guestSelect.guest = guestIframe.value;
    guestSelect.token = tokenIframe.value;
    try {
      const resp = await BaseCalls.postGuest(guestSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.guest) {
        console.log("cookieFromIframe: " + resp.data.guest.guest_id);
        guestStore.setGuest(resp.data.guest);
        guestAlreadyThere.value = true;
      } else {
        checkInsertGuest();
      }
    } catch (err) {
      console.log("error guest iframe");
    }
  }

  async function checkInsertGuest() {
    let guestSelect = {};
    console.log("checkInsertGuest + Ref: " + guestRef.value);
    guestSelect.action = "checkInsertGuest";
    guestSelect.guest_user_id = route.params.userid;
    guestSelect.guestRef = guestRef.value;
    console.log("checkInsertGuest " + guestSelect.guest_user_id);
    try {
      const resp = await BaseCalls.postGuest(guestSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.guest) {
        console.log("cookieFromServer: " + resp.data.guest.guest_id);
        guestStore.setGuest(resp.data.guest);
        sendGuestToParent(
          resp.data.guest.guest_user_id,
          resp.data.guest.guest_id,
          resp.data.guest.guest_identity_token
        );
        // updateGuestRef(resp.data.guest.guest_id);
        console.log("last guest");
        getLastBerechnung(resp.data.guest.guest_id);
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }

  function checkMenue() {
    const pattern =
      /^\/(angebot(\/(?!(id\/?\d*))[^\s]*)?|vergleich\/starten(\/.*)?|rechner\/\d+\/starten|startnew\/\d+|start\/\d+)$/;

    if (pattern.test(route.path)) {
      console.log("closeMenu");
      stopMenue.value = true;
    } else {
      console.log("closeMenuFalse");
      stopMenue.value = false;
    }
  }
  watchEffect(() => {
    // Verzögert die Prüfung der Bedingung um 1 Sekunde (1000 Millisekunden)
    setTimeout(() => {
      if (route.params.userid > 0 && !guestAlreadyThere.value) {
        console.log("userid changed effect: " + route.params.userid);
        checkInsertGuest();
      }
    }, 1000);
  });

  watch(
    () => route.params.userid,
    (newUserId) => {
      console.log("userid changed watch: " + newUserId);
      userId.value = newUserId;
    }
  );

  watch(
    () => guestRef.value,
    () => {
      if (
        !updateRef.value &&
        guestRef.value != "" &&
        guestStore.guest.guest_ref == "" &&
        guestStore.guest.guest_id > 0
      ) {
        updateGuestRef(guestStore.guest.guest_id);
      }
    }
  );

  watch(
    () => loaderStore.load,
    () => {
      if (loaderStore.load) {
        console.log("loaderStore true");
        sendHeightToParent(700);
      }
    }
  );
  watch(
    () => errorStore.error,
    () => {
      if (errorStore.error) {
        console.log("error true");
        sendHeightToParent(700);
      }
    }
  );

  watch(
    () => [route.path, route.params, route.query],
    () => {
      checkMenue();
      window.scrollTo(0, 0);
    },
    {}
  );

  onMounted(() => {
    sendHeightToParent(700);

    nextTick(() => {
      contentHeight.value = measureContentHeight();

      watch(contentHeight, (newHeight, oldHeight) => {
        if (
          Math.abs(newHeight - oldHeight) > 50 &&
          !berechnungStore.tarif.showPopYes &&
          !berechnungStore.showPopApp
        ) {
          sendHeightToParent(newHeight);
        }
      });

      watch(
        [
          () => berechnungStore.tarif.showPopYes,
          () => berechnungStore.showPopApp,
        ],
        () => {
          if (berechnungStore.tarif.showPopYes || berechnungStore.showPopApp) {
            sendHeightToParent(750);
          }
          if (
            !berechnungStore.tarif.showPopYes &&
            !berechnungStore.showPopApp
          ) {
            sendHeightToParent(contentHeight.value);
          }
        }
      );
      // Optional: Höhe regelmäßig überprüfen
      setInterval(() => {
        const newHeight = measureContentHeight();
        if (newHeight !== contentHeight.value) {
          contentHeight.value = newHeight;
        }
      }, 1000); // Überprüfen jede Sekunde
    });
  });
</script>

<style>
  .fixedFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f2f2f2;
    color: grey;
    text-align: left;
    padding: 8px 8px 8px 8px;
    z-index: 1000;
  }
  html {
    font-size: 15px !important;
  }
  .caption {
    font-size: 13px !important;
  }

  ul {
    margin-left: 20px;
    margin-top: 4px;
    margin-bottom: 8px;
  }
  ol {
    margin-left: 20px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  a {
    color: #2196f3 !important;
    text-decoration: none !important;
  }
  .redtext {
    color: #e54c39 !important;
  }
  .greentext {
    color: #4caf50 !important;
  }
  .greenground {
    background-color: #4caf50 !important;
  }
  .blackground {
    background-color: #555555 !important;
  }

  .wtb-chat-app {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 350px;

    /* height: 400px; */
    border: 1px solid grey;
    z-index: 1000;
    background: #ccc;
  }
  .logo {
    font-size: 36px;
    text-decoration: none;
    font-weight: bold;
  }
  .logoSmall {
    font-size: 24px;
    text-decoration: none;
    font-weight: bold;
  }
  .sonder {
    font-size: 36px;
    text-decoration: none;
  }
  .logosmall {
    font-size: 24px;
    text-decoration: none;
    font-weight: bold;
  }
  .header_btn_mobil {
    text-transform: none !important;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
  }
  .header_btn {
    text-transform: none !important;
    font-size: 16px;
  }
  .nocaps {
    text-transform: none !important;
  }
  .v-navigation-drawer__border {
    display: none;
  }
  a {
    color: #0000ee; /* Blaue Farbe */
    text-decoration: underline; /* Unterstreichen des Textes */
    cursor: pointer; /* Ändert den Cursor in einen Zeiger */
  }
  #app {
    color: #555555 !important;
  }
  .layout {
    color: #555555 !important;
  }
  .blue {
    color: #305fa5;
  }
  .blackText {
    color: #555555 !important;
  }
  .titleh1 {
    font-size: 24px;
    color: #555555 !important;
    margin-bottom: 4px;
  }
  .divider {
    border-bottom: 1px solid #2196f3 !important;
    margin-top: 12px;
    margin-bottom: 24px;
  }
  .titleh2 {
    font-size: 16px;
    color: #555555 !important;
  }
  .baseText {
    font-size: 16px;
  }
  .greyText {
    color: #0000008a !important;
  }
  .textWhite {
    color: #ffffff !important;
  }
  .greyTextSize {
    font-size: 13px !important;
    color: #0000008a !important;
  }
  .blueTitle {
    font-size: 16px;
    color: #2196f3 !important;
  }
  .titleHome {
    font-size: 32px;
  }
  .headline {
    font-size: 24px !important;
  }

  .blueground {
    background-color: #2196f3;
  }

  .greenground {
    background-color: #4caf50;
  }
  .yellowground {
    background-color: #fecf0c;
  }
  .orangeground {
    background-color: #ff8000;
  }
  .turkground {
    background-color: #088a85;
  }
  .cianground {
    background-color: #00bcd4;
  }
  .yellowcolor {
    color: #fecf0c;
  }
  .purpleground {
    background-color: #513b83;
  }
  .purplecolor {
    color: #513b83;
  }
  .greyground {
    background-color: #f2f2f2;
  }

  .noUnderline {
    text-decoration: none !important;
  }
  .button_caps {
    text-transform: none !important;
  }
  .wordwrap {
    word-break: normal;
  }
  a:link {
    text-decoration: none !important;
  }
  h1 {
    color: #555555 !important;
  }
  h2 {
    color: #555555 !important;
  }
  h3 {
    color: #555555 !important;
  }
</style>

