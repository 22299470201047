<template>
  <v-row>
    <v-col cols="2">
      <v-checkbox class="py-0 my-0" v-model="mitClearment"></v-checkbox>
    </v-col>

    <v-col cols="9">
      <v-row>
        <v-col cols="12" md="10" class="pt-6">
          <h3>
            Clearment Vertragsschutz
            <sup class="text-green">Tipp</sup>
          </h3>
          Bei Abschluss über uns erhalten Sie den Clearment Vertragsschutz
          <strong class="text-green">kostenlos</strong>.
          <br />
          <a @click="berechnungStore.setShowPopApp('clearmentschutz')">
            Ihre Vorteile durch den Clearment Vertragsschutz.
          </a>
        </v-col>
        <v-col cols="2" v-if="!sharedDataStore.isMobile()">
          <img :src="require('@/assets/clearment/small.png')" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup>
  import { ref, defineEmits, watch } from "vue";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();

  const emit = defineEmits(["updatedClearment"]);

  const mitClearment = ref(true);
  watch(mitClearment, (newValue) => {
    emit("updatedClearment", newValue); // Emit das Update Event mit dem neuen Wert
  });
</script>
