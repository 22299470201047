// Styles
import "@mdi/font/css/materialdesignicons.css"; // Importieren der Material Design Icons
import "vuetify/styles"; // Importieren der Vuetify-Styles

// Vuetify
import { createVuetify } from "vuetify";

// Erstellen und Exportieren der Vuetify-Instanz mit der Konfiguration
export default createVuetify({
  theme: {
    defaultTheme: "myTheme", // Standard-Theme setzen
    themes: {
      myTheme: {
        colors: {
          yellow: "#fecf0c", // Setzt die Basisgelbfarbe
          turk: "#088A85",
        },
      },
      light: {
        colors: {
          primary: "#2196f3", // Primärfarbe hier einstellen
          // Fügen Sie hier weitere Farbanpassungen hinzu
        },
      },
      dark: {
        colors: {
          primary: "#BBDEFB", // Primärfarbe für den Dunkelmodus
          // Fügen Sie hier weitere Farbanpassungen hinzu
        },
      },
    },
  },
  // Weitere Vuetify-Optionen hier einfügen
});
