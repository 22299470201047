<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <h2>
          <span class="redtext">Clearment VetragsCheck</span> | Vertragsqualität
          im Vergleich
        </h2>
        Mit Hilfe des neu entwickelten Clearment VertragsCheck können Sie
        erstmalig die Vertragsqualität von Zahnzusatzversicherungen miteinander
        vergleichen. Die Vertragsqualität gibt an wie kundenfreundlich ein
        Versicherungsvertrag formuliert ist.
        <h3 class="redtext mt-4">Clearment hinterfrägt das „Kleingedruckte“</h3>
        Clearment legt den Versicherungen 157 Detailfragen zur Beantwortung vor.
        Diese Fragen gehen in Ihrer Genauigkeit über die Allgemeinen
        Versicherungsbedingungen hinaus. Anhand dieser Antworten wird die sog.
        Vertragsqualität ermittelt. Beispiele aus dem Clearment Fragenkatalog:
      </v-col>
    </v-row>
    <v-row class="my-2">
      <v-col cols="12" md="9">
        <ul>
          <li>
            Leistet der Tarif auch noch in vertraglich zugesicherter Höhe, wenn
            im Rahmen weiterer
            <b class="redtext">Gesundheitsreformen</b> die Leistungen der
            gesetzlichen Krankenversicherung reduziert werden?
          </li>
          <li>
            Wird auf das
            <b class="redtext">ordentliche Kündigungsrecht</b> seitens des
            Versicherers verzichtet?
          </li>
          <li>
            Ist der Tarif mit
            <b class="redtext">Altersrückstellungen kalkuliert</b>, kommt es
            hinsichtlich des Alters also zu keinen altersbedingten
            Beitragsanpassungen?
          </li>
          <li>
            Sind <b class="redtext">pauschale Abrechnungen</b>, ohne den
            Richtlinien der Gebührenordnung für Zahnzte zu entsprechen,
            erstattungsfähig?
          </li>
          <li>
            Sind die Leistungen an eine
            <b class="redtext">Vorleistung</b> der gesetzlichen Krankenkasse
            gebunden?
          </li>
          <li>
            Gibt es Leistungen, für die von Beginn an vom
            <b class="redtext">Versicherungsschutz ausgeschlossen</b> sind?
          </li>
          <li>
            Wird für medizinisch notwendige
            <b class="redtext">knochenaufbauende (augmentative) Maßnahmen</b>
            im Rahmen einer Implantatversorgung tarifgemäß nach Zahnersatz
            geleistet?
          </li>
          <li>
            Sind <b class="redtext">teilweise Lückenschlüsse</b>, also Lücken,
            die nicht ganz geschlossen sind, wo aber kein Zahnersatz mehr hinein
            passt, als fehlende Zähne anzugeben?
          </li>
          <li>
            Können bei Abschluss bereits fehlende,
            <b class="redtext">nicht ersetzte Zähne mitversichert</b>
            werden, wenn eine Versorgung dieser Zähne zum Zeitpunkt des
            Abschlusses weder angeraten noch medizinisch notwendig ist?
          </li>

          <li>
            Ist eine Erstattung über dem
            <b class="redtext">Höchstsatz der GOZ / GOÄ</b> (3,5-facher Satz)
            versichert?
          </li>
          <li>
            Wie hoch ist die
            <b class="redtext">Leistung für Material- und Laborkosten</b> im
            Zahnersatz-Bereich oder gibt es ein Preis-/Leistungsverzeichnis?
          </li>
          <li>
            <b class="redtext">und noch weitere 150 detailierte Fragen.....</b>
          </li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
