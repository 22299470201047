<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <h1 class="text-green">Ihr Antrag &amp; Infopaket</h1>
        <h2 class="text-green">
          {{ tarif.un_name }} {{ tarif.tarif_title }} wird versendet
        </h2>
        Sie erhalten Ihre Unterlagen per E-Mail und Post. Während der normalen
        Bürozeiten dauert dies in der Regel nur 1-2 Stunden. Sie erhalten keine
        Anrufe von uns.
        <br />
        <br />
        <strong>So geht es jetzt weiter:</strong>
        <ol>
          <li>
            unser Service-Team prüft für Sie, ob Ihr Antrag angenommen werden
            würde (anonym)
          </li>
          <li>
            danach erhalten Sie einen vorbereiteten Versicherungsantrag per Post
            und E-Mail
          </li>
          <li>zusätzlich erhalten Sie die ausführlichen Tarif-Informationen</li>

          <li>
            Sie brauchen den Antrag nur noch unterschreiben und an uns
            zurücksenden
          </li>
        </ol>
        <br />
        <h2>Bitte laden Sie jetzt diese Unterlagen herunter</h2>
        <AvbDownloads v-if="tarif" :tarif="tarif" showSignup="all" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
  import { ref, onMounted, getCurrentInstance } from "vue";
  import AvbDownloads from "@/components/tarif/mini/AvbDownloads";
  import BaseCalls from "@/services/BaseCalls";
  const { proxy } = getCurrentInstance();
  import { useLoaderStore } from "@/stores/loaderStore";
  import { useRoute } from "vue-router";
  const loaderStore = useLoaderStore();
  const route = useRoute();

  const tarif = ref(false);
  const berechnung = ref(false);

  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }
  async function getVergleich() {
    setLoading(true);
    let berSelect = {};
    berSelect.action = "getTarifAntragBerechnung";
    berSelect.ber_identity_token = route.params.token;
    berSelect.tarif_id = route.params.tarif_id;
    berSelect.showPreisJahrePlus = 1;
    console.log("getVergleich " + berSelect.tarif_id);
    try {
      const resp = await BaseCalls.postTarif(berSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.berechnung) {
        tarif.value = resp.data.tarif;
        berechnung.value = resp.data.berechnung;
        setLoading(false);
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }

  onMounted(() => {
    getVergleich();
    proxy.$updateDocumentHead("Es hat geklappt", "Es hat geklappt");
  });
</script>
