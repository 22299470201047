<template>
  <div>
    <v-row>
      <v-col cols="12" md="12"
        ><h2>
          Was ist bei einem Wechsel der Zahnzusatzversicherung zu beachten?
        </h2>

        Im Bereich der Zahnzusatzversicherung werden ständig neue Tarife
        entwickelt. Es lohnt sich den Versicherungsschutz von Zeit zu Zeit
        genauer unter die Lupe zu nehmen. Ein Wechsel sollte in Betracht gezogen
        werden, wenn:
        <ul>
          <li>Ihr Tarif weniger als 50% bei Zahnersatz leistet,</li>
          <li>
            einzelne Erstattungsbereiche stark begrenzt sind (z.B. Implantate,
            Inlays, Verblendungen),
          </li>
          <li>
            wichtige Leistungsbereiche, wie z.B. Knochenaufbau,
            Wurzelbehandlungen oder Prophylaxe nicht mitversichert sind oder
          </li>
          <li>
            eine extreme Beitragserhöhung durchgeführt bzw. regelmäßig
            Beitragserhöhungen mitgeteilt werden.
          </li>
        </ul>
        Unter Umständen gibt es einen anderen Tarif, der den Bedürfnissen eher
        entspricht als der bereits abgeschlossene Zahnzusatztarif. Wenn Sie
        einen Tarif gefunden haben, in den Sie wechseln möchten, sollten Sie
        vorher folgende 4 Fragen abklären: - Laufen gerade zahnärztliche
        Behandlungen oder sind solche angeraten bzw. geplant? - Wann und wie ist
        der bestehende Vertrag kündbar? - Funktioniert die Aufnahme in den neuen
        Tarif problemlos ? - Versichert der bestehende Tarif Zusatzleistungen
        mit, die beim Wechsel verloren gehen?<br /><br />
        <h2>
          Laufen gerade zahnärztliche Behandlungen oder sind solche angeraten /
          geplant?
        </h2>
        Ihr Zahnarzt hat bereits eine Behandlung begonnen oder eine konkrete
        Behandlung angeraten. Dann ist ein Wechsel auf keinen Fall sinnvoll bzw.
        empfehlenswert. Sie würden den unter Umständen bestehenden
        Versicherungsschutz für diese Behandlung verlieren. Lassen Sie sich am
        besten von Ihrem Zahnarzt das "Nichtvorliegen" eines zahnärztlichen
        Behandlungsbedarfes schriftlich bestätigen. Nutzen Sie dazu dieses
        kostenlose Wechsel-Formular [PDF].Bitte beachten Sie: Das Tragen einer
        Schiene oder eines Retainers gilt als so genannte “Passivtherapie” und
        ist versicherungsrechtlich so lange als laufende Maßnahme zu werten, bis
        diese/er nicht mehr getragen werden muss. Erst dann kann der Zahnarzt
        die Behandlung als erfolgreich abgeschlossen bezeichnen. Sollte eine
        Erkrankung des Zahnhalteapparats vorliegen (wie z. B. Parodontose oder
        Parodontitis), ist in den meisten Fällen von einem Wechsel abzuraten.
        <br /><br />
        <h2>Wann und wie ist der bestehende Vertrag kündbar?</h2>
        Die meisten Versicherer haben Mindestvertragslaufzeiten von 1 bis 2
        Jahren. Eine Kündigung ist erstmals nach Ablauf dieser
        Mindestvertragslaufzeiten - meist mit einer dreimonatigen
        Kündigungsfrist - möglich. Vorsicht: Einige Anbieter berechnen das
        Versicherungsjahr exakt nach dem Abschlusszeitpunkt. Das erste
        Versicherungsjahr endet entsprechend genau 12 Monate nach dem Abschluss.
        Andere Anbieter arbeiten mit einem so genannten “Rumpfjahr”. Das
        bedeutet, dass das erste Versicherungsjahr bereits am 31.12. des
        Abschlussjahres endet. Danach entspricht das Versicherungsjahr dem
        Kalenderjahr. Eine Kündigung wäre in diesem Fall dann jeweils zum 31.12.
        möglich. Der Versicherungsvertrag muss stets schriftlich gekündigt
        werden. Dabei muss das Kündigungsschreiben die Unterschriften des
        Versicherungsnehmers sowie aller versicherter Personen über 16 Jahren
        tragen. Schicken Sie eine Kündigung am besten immer per Einschreiben
        direkt an den Versicherer.
        <br /><br />
        <h2>Funktioniert die Annahme in den neuen Tarif problemlos?</h2>
        Mit unserem Online-Annahmecheck erhält man nach Beantwortung der
        Gesundheitsfragen und Eingabe der persönlichen Daten sofort eine 100%
        Annahmegarantie. Das bedeutet, dass die Versicherer, die im
        Online-Rechner als abschließbar angezeigt werden, dann auch auf jeden
        Fall die Anträge annehmen und rechtgültige Verträge zustande kommen. Das
        ist sehr wichtig, weil eine bereits zuvor erfolgte Ablehnung des Antrags
        bei vielen anderen Versicherern angegeben werden müsste, was eine
        weitere Antragsstellung unnötig erschweren würde. Viele Versicherer
        fragen im Antrag nach zuvor bereits abgelehnten Anträgen und lehnen in
        der Folge dann ebenfalls ab.
        <br /><br />
        <h2>
          Versichert der bestehende Tarif Zusatzleistungen mit, die beim Wechsel
          verloren gehen?
        </h2>
        Die so genannten Kombi- oder Mischtarife beinhalten neben den reinen
        Zahnleistungen auch zusätzliche Leistungen für z. B. Behandlungen beim
        Heilpraktiker, Sehhilfen oder Kurtagegeld. Durch die Kündigung eines
        solchen Tarifs und den Abschluss einer reinen Zahnzusatzversicherung
        würden diese zusätzlichen Leistungen verloren gehen. Diese müssten dann
        bei einem anderen Versicherer oder in einem separaten Tarif neu
        versichert werden. Da in diesem Fall dann meist ausführliche
        Gesundheitsfragen gestellt werden, kann es schnell zu einer Ablehnung
        des Antrags kommen. Daher sollte man vorab unbedingt prüfen, ob bei
        einem Wechsel Leistungen verloren gehen würden, die unter Umständen
        aufgrund eventueller Vorerkrankungen nicht mehr versichert werden
        könnten.
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
