<template>
  <div>
    <h2>Vergleich zahnärztlicher Hightech-Leistungen</h2>

    <v-table>
      <thead>
        <tr>
          <th>Tarif</th>
          <th>
            <TooltipHelper toolkey="laser">
              <template v-slot:toolTipText>
                <a>Laser</a>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="mikro">
              <template v-slot:toolTipText>
                <a>Mikros.</a>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="cerec">
              <template v-slot:toolTipText>
                <a>Cerec</a>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="dvt">
              <template v-slot:toolTipText>
                <a>DVT</a>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="dna">
              <template v-slot:toolTipText>
                <a>DNA</a>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="nark">
              <template v-slot:toolTipText>
                <a>Nark.</a>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="aku">
              <template v-slot:toolTipText>
                <a>AKU.</a>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="pact">
              <template v-slot:toolTipText>
                <a>Pact.</a>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="dros">
              <template v-slot:toolTipText>
                <a>DROS</a>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="invisa">
              <template v-slot:toolTipText>
                <a>Invisa.</a>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="aufb">
              <template v-slot:toolTipText>
                <a>Aufb.</a>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="vect">
              <template v-slot:toolTipText>
                <a>Vect.</a>
              </template>
            </TooltipHelper>
          </th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="t in alletarife" :key="t.tarif_id">
          <td>
            <strong>
              <router-link v-if="t.antraglink" :to="t.antraglink">
                <a>{{ t.un_name }}</a>
              </router-link>
              <span v-if="!t.antraglink">{{ t.un_name }}</span>
              <span v-if="t.empfehlung_type > 0">
                <sup class="text-green">Tipp</sup>
              </span>
            </strong>
            <br />
            {{ t.tarif_title }}
          </td>
          <td>
            {{ t.nice_tarif_leistung_trend_laser == "Ja" ? "Ja" : "Nein" }}
          </td>
          <td>
            {{ t.nice_tarif_leistung_trend_opmikro == "Ja" ? "Ja" : "Nein" }}
          </td>
          <td>
            {{ t.nice_tarif_leistung_trend_cerec == "Ja" ? "Ja" : "Nein" }}
          </td>
          <td>
            {{ t.nice_tarif_leistung_trend_dvt == "Ja" ? "Ja" : "Nein" }}
          </td>
          <td>
            {{ t.nice_tarif_leistung_trend_dna == "Ja" ? "Ja" : "Nein" }}
          </td>
          <td>
            {{ t.nice_tarif_leistung_trend_narkose == "Ja" ? "Ja" : "Nein" }}
          </td>
          <td>
            {{ t.nice_tarif_leistung_trend_aku == "Ja" ? "Ja" : "Nein" }}
          </td>
          <td>
            {{ t.nice_tarif_leistung_trend_pact == "Ja" ? "Ja" : "Nein" }}
          </td>
          <td>
            {{ t.nice_tarif_leistung_trend_dros == "Ja" ? "Ja" : "Nein" }}
          </td>
          <td>
            {{ t.nice_tarif_leistung_trend_invisa == "Ja" ? "Ja" : "Nein" }}
          </td>
          <td>
            {{ t.nice_tarif_leistung_trend_aufbiss == "Ja" ? "Ja" : "Nein" }}
          </td>
          <td>
            {{ t.nice_tarif_leistung_trend_vector == "Ja" ? "Ja" : "Nein" }}
          </td>
          <td>
            <strong>
              <router-link v-if="t.antraglink" :to="t.antraglink">
                <a class="noUnderline">Antrag &amp; Infopaket</a>
              </router-link>
            </strong>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  import TooltipHelper from "@/components/base/TooltipHelper.vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
    alletarife: {
      type: Array,
    },
  });
  console.log(props.tarif);
</script>
