<template>
  <div>
    <v-row>
      <v-col cols="12" md="12"
        ><h2>
          Aufgrund Ihrer Angaben konnten wir keine Tarife finden, bei denen die
          laufenden Behandlungen mitversichert sind
        </h2>
        Manche dieser sog. "Sofort-Tarife" leisten z.B. nur wenn bereits
        bestehende Zahnlücken geschlossen werden müssen.
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
