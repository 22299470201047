<template>
  <div>
    <span style="font-size: 18px"
      >Als <strong>"Kronen und Implantate"</strong> anzugeben sind</span
    >
    <br />
    <ul>
      <li>
        die Anzahl der durch <strong>Kronen bzw. Teilkronen</strong> ersetzten
        Zähne
      </li>
      <li>
        die durch <strong>Implantate ersetzten Zähne</strong>, da auf einem
        Implantat grundsätzlich als sogenannte Suprakonstruktion eine Krone
        sitzt.
      </li>
    </ul>
    <span style="font-size: 18px"
      ><strong class="text-green">"nicht anzugeben"</strong> sind</span
    >
    <br />
    <ul>
      <li><strong>Inlays bzw. Einlagefüllungen</strong></li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "kronen-info",
    components: {},
  };
</script>

<style scoped></style>
