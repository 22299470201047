<template>
  <div>
    <h2>Clearment Leistungszertifzierung</h2>
    <em> Je klarer desto “sicherer”. </em><br /><br />
    Eine Erstattung zu versprechen ist das eine, im Leistungsfall wirklich zu
    erstatten das andere. Oft beschreibt das Kleingedruckte der Versicherung die
    Leistung nur sehr ungenau, was zu einem Interpretationsspielraum im
    Leistungsfall führt. Deshalb haben wir 2008 begonnen uns diese ungenau
    definierten Leistungen mit Hilfe eines Fragebogens von der Versicherung
    bestätigen zu lassen. Mit dem Ziel, dass Sie sich auf Ihre
    Versicherungsleistung verlassen können.
    <v-row>
      <v-col cols="12" md="2"><b class="text-green">LSK 5</b></v-col>
      <v-col cols="12" md="10"
        >Zertifiziert nach Leistungskatalog Waizmann.2023 Umfang: 157 Leistungs-
        und Prozessfragen. Es wurde eine detaillierte Aufschlüsselung der
        KFO-Leistung in den KIG-Stufen 1-2 und 3-5 vorgenommen und Fragen zur
        Sofort-Leistung und zur PZR-Flatrate mit aufgenommen. Erstmalig wurde
        mit festgelegten Antwort-Optionen gearbeitet um höchst mögliche
        Leistungssicherheit zu erzielen.</v-col
      >
    </v-row>
    <v-row>
      <v-col cols="12" md="2"><b class="text-orange">LSK 3</b></v-col>
      <v-col cols="12" md="10"
        >Zertifiziert nach Leistungskatalog Waizmann.2017 Umfang: 54
        Leistungsfragen plus zusätzliche Spezialfragen zu Prophylaxe- und
        Hightech-Leistungen (z.B. CEREC-Behandlung | DROS-Schiene |
        Invisalign)</v-col
      >
    </v-row>
    <v-row>
      <v-col cols="12" md="2"><b>LSK 1</b></v-col>
      <v-col cols="12" md="10"
        >Zertifiziert nach Leistungskatalog Waizmann.2008 Umfang: 27 Fragen
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
