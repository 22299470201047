<template>
  <div>
    <span v-if="!showHilfe">
      <h3>
        <a @click="showHilfe = !showHilfe">Sie benötigen Hilfe?</a>
      </h3>
      In nur wenigen Minuten ist alles erledigt...
      <a @click="showHilfe = !showHilfe">lesen Sie hier mehr....</a>
    </span>
    <div v-if="showHilfe">
      <h3 class="text-green">So beantragen Sie Ihre Zusatzversicherung</h3>

      <ul>
        <li>
          Wählen Sie aus unseren Tarif-Empfehlungen einen Tarif aus und fordern
          Sie das Informations- und Angebotspaket an (100 % kostenlos). In
          diesem Schritt müssen Sie auch Ihre persönlichen Daten eingeben.
        </li>

        <li>
          Sie erhalten per Post ein Infopaket von uns; füllen Sie den
          vorbereiteten Antrag an den markierten Stellen aus und unterschreiben
          Sie diesen; schicken Sie diesen dann an uns zurück.
        </li>

        <li>
          Wir überprüfen Ihren Antrag (doppelter Annahmecheck) und leiten diesen
          an die Versicherung weiter.
        </li>

        <li>
          Danach erhalten Sie Ihre Versicherungspolice von der Versicherung.
        </li>

        <li>Danach haben Sie noch eine Widerrufsfrist von 2 Wochen.</li>
      </ul>
    </div>
  </div>
</template>

<script setup>
  import { ref, defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  const showHilfe = ref(false);
  console.log(props.tarif);
</script>
