<template>
  <div v-if="waizmannwert.v1">
    <v-row>
      <v-col
        cols="2"
        class="d-none d-sm-flex"
        style="height: 140px; width: 120px"
      >
        <v-row style="height: 100%; background-color: #2196f3" class="pa-3">
          <v-col cols="12">
            <span style="font-size: 36px; font-weight: bold; color: #fff">
              {{ tarif.tarif_waizmannwert }}%
            </span>
            <br />
            <span style="color: #fff">Ø-Erstattung</span>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="10" class="pl-4">
        <h2>Waizmann 8-Jahres Realwert-Methode</h2>
        Die Waizmann 8-Jahres Realwert-Methode gibt die reale
        <b>Durchschnittserstattung</b> einer Zahnzusatzversicherung über einen
        8-jährigen Beobachtungszeitraum an. Im Gegensatz zu üblichen
        Testverfahren, ist die Berechnung für jeden
        <strong>nachprüfbar</strong> und
        <strong>100 % manipulationssicher</strong>. Dies ist einer der Gründe
        wieso die Waizmanntabelle von immer mehr Zahnärzten in Deutschland
        empfohlen wird. <br /><br />
      </v-col>
    </v-row>
    <br />
    <br />
    <h2>8 Jahres Realwert-Methode | Rechenweg</h2>
    Die {{ tarif.un_name }} {{ tarif.tarif_title }} leistet über 8 Jahre
    betrachtet im Durchschnitt {{ tarif.tarif_waizmannwert }} %. Basis für die
    Berechnung bildet der unten abgebildete Behandlungsverlauf.

    <v-table dense>
      <thead>
        <tr style="vertical-align: top">
          <th style="width: 10px">Jahr</th>
          <th>Leistung</th>

          <th>Zahnarzt-Rechnung</th>
          <th style="width: 100px">GKV Leistung</th>
          <th style="width: 100px">
            <strong class="text-green">ZZV Leistung</strong>
          </th>
          <th>Eigenanteil</th>
        </tr>
      </thead>
      <tbody>
        <tr style="vertical-align: top">
          <td>1.</td>
          <td>Professionelle Zahnreinigung zweimal jährlich</td>

          <td align="right">160,- €</td>
          <td align="right">0,- €</td>
          <td align="right">{{ waizmannwert.v1 }},- €</td>
          <td align="right">{{ waizmannwert.ea1 }} ,- €</td>
        </tr>

        <tr style="vertical-align: top">
          <td>1.</td>
          <td>Einsetzen einer dreiflächigen Füllung in Adhäsivtechnik</td>

          <td align="right">200,- €</td>
          <td align="right">50,- €</td>
          <td align="right">{{ waizmannwert.v2 }},- €</td>
          <td align="right">{{ waizmannwert.ea2 }} ,- €</td>
        </tr>

        <tr>
          <td>2.</td>
          <td>Professionelle Zahnreinigung zweimal jährlich</td>

          <td align="right">160,- €</td>

          <td align="right">0,- €</td>
          <td align="right">{{ waizmannwert.v3 }},- €</td>
          <td align="right">{{ waizmannwert.ea3 }},- €</td>
        </tr>

        <tr>
          <td>2.</td>
          <td>
            Wurzelkanalbehandlung inklusive medikamentöse Einlage und Füllung
          </td>

          <td align="right">750,- €</td>
          <td align="right">0,- €</td>
          <td align="right">{{ waizmannwert.v4 }},- €</td>
          <td align="right">{{ waizmannwert.ea4 }},- €</td>
        </tr>

        <tr>
          <td>2.</td>
          <td>Einsetzen von zwei dreiflächigen Füllung in Adhäsivtechnik</td>

          <td align="right">400,- €</td>
          <td align="right">100,- €</td>
          <td align="right">{{ waizmannwert.v5 }},- €</td>
          <td align="right">{{ waizmannwert.ea5 }} ,- €</td>
        </tr>

        <tr>
          <td>3.</td>
          <td>Professionelle Zahnreinigung zweimal jährlich</td>

          <td align="right">160,- €</td>
          <td align="right">0,- €</td>
          <td align="right">{{ waizmannwert.v6 }},- €</td>
          <td align="right">{{ waizmannwert.ea6 }},- €</td>
        </tr>

        <tr>
          <td>3.</td>
          <td>
            Einsetzen einer Einlagefüllung (Inlay) mehr als zweiflächig
            inklusive Labor
          </td>

          <td align="right">650,- €</td>
          <td align="right">50,- €</td>
          <td align="right">{{ waizmannwert.v7 }},- €</td>
          <td align="right">{{ waizmannwert.ea7 }},- €</td>
        </tr>

        <tr>
          <td>3.</td>
          <td>
            Einsetzen einer Vollkrone in Stufenpräparation inklusive
            Keramikverblendung
          </td>

          <td align="right">800,- €</td>
          <td align="right">150,- €</td>
          <td align="right">{{ waizmannwert.v8 }},- €</td>
          <td align="right">{{ waizmannwert.ea8 }} ,- €</td>
        </tr>

        <tr>
          <td>4.</td>
          <td>Professionelle Zahnreinigung zweimal jährlich</td>

          <td align="right">160,- €</td>
          <td align="right">0,- €</td>
          <td align="right">{{ waizmannwert.v9 }},- €</td>
          <td align="right">{{ waizmannwert.ea9 }},- €</td>
        </tr>

        <tr>
          <td>4.</td>
          <td>
            Einsetzten eines Implantats inklusive Suprakonstruktion und
            chirurgischem Eingriff
          </td>

          <td align="right">2100,- €</td>
          <td align="right">520,- €</td>
          <td align="right">{{ waizmannwert.v10 }},- €</td>
          <td align="right">{{ waizmannwert.ea10 }},- €</td>
        </tr>

        <tr>
          <td>4.</td>
          <td>Einsetzen von zwei dreiflächigen Füllung in Adhäsivtechnik</td>

          <td align="right">400,- €</td>
          <td align="right">100,- €</td>
          <td align="right">{{ waizmannwert.v11 }},- €</td>
          <td align="right">{{ waizmannwert.ea11 }},- €</td>
        </tr>

        <tr>
          <td>5.</td>
          <td>Professionelle Zahnreinigung zweimal jährlich</td>

          <td align="right">160,- €</td>
          <td align="right">0,- €</td>
          <td align="right">{{ waizmannwert.b1 }},- €</td>
          <td align="right">{{ waizmannwert.eb1 }} ,- €</td>
        </tr>

        <tr>
          <td>5.</td>
          <td>Einsetzen einer dreiflächigen Füllung in Adhäsivtechnik</td>

          <td align="right">200,- €</td>
          <td align="right">50,- €</td>
          <td align="right">{{ waizmannwert.b2 }},- €</td>
          <td align="right">{{ waizmannwert.eb2 }} ,- €</td>
        </tr>

        <tr>
          <td>6.</td>
          <td>Professionelle Zahnreinigung zweimal jährlich</td>

          <td align="right">160,- €</td>

          <td align="right">0,- €</td>
          <td align="right">{{ waizmannwert.b3 }},- €</td>
          <td align="right">{{ waizmannwert.eb3 }},- €</td>
        </tr>

        <tr>
          <td>6.</td>
          <td>
            Wurzelkanalbehandlung inklusive medikamentöse Einlage und Füllung
          </td>

          <td align="right">750,- €</td>
          <td align="right">0,- €</td>
          <td align="right">{{ waizmannwert.b4 }},- €</td>
          <td align="right">{{ waizmannwert.eb4 }},- €</td>
        </tr>

        <tr>
          <td>6.</td>
          <td>Einsetzen von zwei dreiflächigen Füllung in Adhäsivtechnik</td>

          <td align="right">400,- €</td>
          <td align="right">100,- €</td>
          <td align="right">{{ waizmannwert.b5 }},- €</td>
          <td align="right">{{ waizmannwert.eb5 }} ,- €</td>
        </tr>

        <tr>
          <td>7.</td>
          <td>Professionelle Zahnreinigung zweimal jährlich</td>

          <td align="right">160,- €</td>
          <td align="right">0,- €</td>
          <td align="right">{{ waizmannwert.b6 }},- €</td>
          <td align="right">{{ waizmannwert.eb6 }},- €</td>
        </tr>

        <tr>
          <td>7.</td>
          <td>
            Einsetzen einer Einlagefüllung (Inlay) mehr als zweiflächig
            inklusive Labor
          </td>

          <td align="right">650,- €</td>
          <td align="right">50,- €</td>
          <td align="right">{{ waizmannwert.b7 }},- €</td>
          <td align="right">{{ waizmannwert.eb7 }},- €</td>
        </tr>

        <tr>
          <td>7.</td>
          <td>Vollkrone in Stufenpräparation inklusive Keramikverblendung</td>

          <td align="right">800,- €</td>
          <td align="right">150,- €</td>
          <td align="right">{{ waizmannwert.b8 }},- €</td>
          <td align="right">{{ waizmannwert.eb8 }} ,- €</td>
        </tr>

        <tr>
          <td>8.</td>
          <td>Professionelle Zahnreinigung zweimal jährlich</td>

          <td align="right">160,- €</td>
          <td align="right">0,- €</td>
          <td align="right">{{ waizmannwert.b9 }},- €</td>
          <td align="right">{{ waizmannwert.eb9 }},- €</td>
        </tr>

        <tr>
          <td>8.</td>
          <td>
            Einsetzten eines Implantats inklusive Suprakonstruktion und
            chirurgischem Eingriff
          </td>

          <td align="right">2100,- €</td>
          <td align="right">520,- €</td>
          <td align="right">{{ waizmannwert.b10 }},- €</td>
          <td align="right">{{ waizmannwert.eb10 }},- €</td>
        </tr>

        <tr>
          <td>8.</td>
          <td>Einsetzen von zwei dreiflächigen Füllung in Adhäsivtechnik</td>

          <td align="right">400,- €</td>
          <td align="right">100,- €</td>
          <td align="right">{{ waizmannwert.b11 }},- €</td>
          <td align="right">{{ waizmannwert.eb11 }},- €</td>
        </tr>
        <tr style="vertical-align: top">
          <td colspan="2">
            <b>Summe</b>
          </td>

          <td align="right">
            <b>9.940,- €</b>
          </td>

          <td align="right">
            <b>1.940,- €</b>
          </td>
          <td align="right">
            <b>
              {{ waizmannwert.endgesamt }},-€
              <br />
              = {{ tarif.tarif_waizmannwert }} %
            </b>
          </td>
          <td align="right">
            <b>{{ waizmannwert.restgesamt }},- €</b>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script setup>
  import { defineProps, onMounted, ref } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
    wwprop: {
      type: Object,
      default: () => ({
        v1: false,
      }),
    },
  });

  const waizmannwert = ref(false);

  async function getWaizmannwert() {
    let berSelect = {};
    berSelect.action = "getTarifWaizmannwert";
    berSelect.ww_wtb_id = props.tarif.tarif_id;
    try {
      const resp = await BaseCalls.postTarif(berSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.waizmannwert) {
        waizmannwert.value = resp.data.waizmannwert;
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }

  onMounted(() => {
    if (!props.wwprop.value) {
      console.log("ww");
      getWaizmannwert();
    } else {
      waizmannwert.value = props.wwprop.value;
    }
  });
  console.log(props.tarif);
</script>
