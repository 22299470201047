<template>
  <div>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row>
      <v-col cols="12" md="3">
        <b>Clearment</b>
        <span class="caption"> Vertragsschutz </span>
      </v-col>
      <v-col cols="12" md="7">
        <div style="border-bottom: 1px solid #f2f2f2">
          <span v-if="tarif.tarif_clearment_vs == 1"
            ><b class="text-green">Vertragsschutz kostenlos</b> |

            <a @click="berechnungStore.setShowPop(tarif, 'vertragsschutz')"
              ><b>Info</b></a
            ></span
          >
          <span v-if="tarif.tarif_clearment_vs == 0"
            ><b class="text-red">Vertragsschutz nicht verfügbar</b></span
          >
          <span class="caption"
            ><br />
            Kostenlos während der gesamten Vertragslaufzeit</span
          >
        </div>
      </v-col>

      <v-col cols="12" md="2">
        <img width="50px" :src="require('@/assets/clearment/small.png')" />
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
