<template>
  <div>
    <v-row>
      <v-col cols="12" md="4" xs="12">
        <v-select
          label="Tag"
          background-color="white"
          v-model="selDay"
          :items="days"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4" xs="12">
        <v-select
          label="Monat"
          background-color="white"
          v-model="selMonth"
          :items="months"
          item-value="id"
          item-title="name"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4" xs="12">
        <v-select
          label="Jahr"
          background-color="white"
          v-model="selYear"
          :items="years"
          item-value="id"
          item-title="name"
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { ref, watch, computed, defineProps, defineEmits } from "vue";

  const props = defineProps({
    day: {
      type: Number,
      default: new Date().getDate(),
    },
    month: {
      type: Number,
      default: new Date().getMonth() + 1, // +1 da JavaScript Monate von 0 bis 11 zählt
    },
    year: {
      type: Number,
      default: new Date().getFullYear(),
    },
  });

  const emit = defineEmits(["update:day", "update:month", "update:year"]);

  const selDay = ref(props.day);
  const selMonth = ref(props.month);
  const selYear = ref(props.year);

  const days = computed(() => {
    const daysInMonth = new Date(selYear.value, selMonth.value, 0).getDate();
    return Array.from({ length: daysInMonth }, (_, i) => i + 1);
  });

  const months = [
    { id: 1, name: "Januar" },
    { id: 2, name: "Februar" },
    { id: 3, name: "März" },
    { id: 4, name: "April" },
    { id: 5, name: "Mai" },
    { id: 6, name: "Juni" },
    { id: 7, name: "Juli" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "Oktober" },
    { id: 11, name: "November" },
    { id: 12, name: "Dezember" },
  ];

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i);

  watch(selDay, (newValue) => {
    emit("update:day", newValue);
  });

  watch(selMonth, (newValue) => {
    emit("update:month", newValue);
  });

  watch(selYear, (newValue) => {
    emit("update:year", newValue);
  });
</script>
