<template>
  <div class="my-8" style="border-bottom: 4px solid #f2f2f2">
    <v-row style="border-bottom: 2px solid #f2f2f2" class="mb-2 pb-2">
      <v-col cols="3" class="pb-0">
        <RankInfo :showMobile="true" :tarif="tarif" :berechnung="berechnung" />
      </v-col>
      <v-col cols="9" class="pb-0">
        <v-row
          ><v-col cols="12">
            <h3 style="font-size: 18px; font-weight: bold">
              {{ tarif.un_name }}
              <v-icon
                v-if="tarif.empfehlung_type > 0"
                class="text-green"
                style="font-size: 18px"
              >
                mdi-star
              </v-icon>
            </h3>
            <v-row>
              <v-col cols="8">{{ tarif.tarif_title }} </v-col>
              <v-col cols="4"
                ><b class="text-blue">{{ tarif.preis_value }}€</b>
              </v-col>
            </v-row>
          </v-col></v-row
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <HighlightsTarif :tarif="tarif" />

        <ul>
          <li v-for="(item, index) in filteredItems" v-bind:key="index">
            {{ item.value }}
          </li>
          <li>
            <a @click="berechnungStore.setShowPop(tarif, 'tarifbeschreibung')"
              >Tarifbeschreibung</a
            >
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row class="px-2 pb-2">
      <v-col v v-if="tarif.antraglink">
        <div>
          <v-btn
            :to="tarif.antraglink"
            elevation="1"
            rounded="0"
            :color="berechnung.fontColorVue"
            class="button_caps"
            style="color: white !important; font-size: 16px"
          >
            Infopaket anfordern
          </v-btn>
        </div>
        <div
          class="pl-1 mt-2"
          style="font-size: 12px"
          v-if="berechnung.ber_segment <= 1"
        >
          Clearment Vertragsschutz kostenlos
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps, computed } from "vue";
  import RankInfo from "@/components/tarif/mini/RankInfo";
  import HighlightsTarif from "@/components/tarif/mini/HighlightsTarif";
  import { useBerechnungStore } from "@/stores/berechnung";
  const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  const filteredItems = computed(() => {
    let sortType = "erwachsen";
    if (
      props.berechnung.ber_algo_view == 2 &&
      props.berechnung.ber_segment == 1
    ) {
      sortType = "kind";
    }
    return props.tarif.miniLeistungsLog.filter(
      (item) => item.type === sortType
    );
  });
</script>
