<template>
  <div>
    <!-- Zahnersatz -->
    <h3>Zahnersatz-Leistungen: {{ tarif.tarif_leistung_ze }} %</h3>

    <!-- Wartezeit -->
    <v-row>
      <v-col cols="12" md="4">
        <strong>Wartezeit</strong>
        <br />
        <span class="caption">für Zahnersatz</span>
      </v-col>
      <v-col cols="12" md="8">
        <v-divider :thickness="1" class="my-2"></v-divider>
        <strong>{{ tarif.tarif_beschreibung_wz_ze }}</strong>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Inlays -->
    <v-row>
      <v-col cols="12" md="4">
        <strong>Inlays</strong>
        <br />
        <span class="caption">Begrenzungen</span>
      </v-col>
      <v-col cols="12" md="8">
        <v-divider :thickness="1" class="my-2"></v-divider>
        <strong>{{ tarif.tarif_beschreibung_inlay }}</strong>
        <br />
        {{ tarif.tarif_beschreibung_inlay_beg }}
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Implantate -->
    <v-row>
      <v-col cols="12" md="4">
        <strong>Implantate</strong>
        <br />
        <span class="caption">Begrenzungen Anzahl und Betrag</span>
      </v-col>
      <v-col cols="12" md="8">
        <v-divider :thickness="1" class="my-2"></v-divider>
        <strong>{{ tarif.tarif_beschreibung_implantat }}</strong>
        <br />
        {{ tarif.tarif_beschreibung_implantat_beg_anzahl }}
        <br />
        {{ tarif.tarif_beschreibung_implantat_beg_betrag }}
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Knochenaufbau -->
    <v-row>
      <v-col cols="12" md="4">
        <strong>Knochenaufbau</strong>
      </v-col>
      <v-col cols="12" md="8">
        <v-divider :thickness="1" class="my-2"></v-divider>
        <strong>{{ tarif.tarif_beschreibung_knochenaufbau }}</strong>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Funktionsanalyse -->
    <v-row>
      <v-col cols="12" md="4">
        <strong>Funktionsanalyse</strong>
      </v-col>
      <v-col cols="12" md="8">
        <v-divider :thickness="1" class="my-2"></v-divider>
        <strong>{{ tarif.tarif_beschreibung_funktion_ze }}</strong>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Verblendungen -->
    <v-row>
      <v-col cols="12" md="4">
        <strong>Verblendungen</strong>
        <br />
        <span class="caption">Begrenzungen</span>
      </v-col>
      <v-col cols="12" md="8">
        <v-divider :thickness="1" class="my-2"></v-divider>
        <strong>{{ tarif.tarif_beschreibung_verblendung }}</strong>
        <br />
        {{ tarif.tarif_beschreibung_verblendung_beg }}
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
