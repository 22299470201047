<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <h3>
          <span class="text-green">Hinweis:</span> Beitragssprung im nächsten
          Jahr
        </h3>
        Im nächsten Jahr erhöht sich der Beitrag von {{ tarif.preis_value }}€
        auf {{ tarif.preis_value_plus_1 }}€. Danach bleiben die Beiträge wieder
        mehrere Jahre stabil.
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  const props = defineProps({
    tarif: {
      type: Object,
    },
  });
  console.log(props.tarif);
</script>
