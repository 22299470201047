<template>
  <v-container>
    <!-- {{ element.del_id }} | {{ element.del_token }} | Konto: {{ saveKonto }} |
    signSaved: {{ signSaved }} | BerId: | SignDone: {{ showSignDone }}
    {{ berechnung.ber_id }} | KontoFehlt: {{ element.del_konto_fehlt_noch }} ->
    {{ signKontoFehltNoch }} | SignFehlt noch:
    {{ element.del_sign_fehlt_noch }} -> {{ signFehltNoch }} | scaleFactor:
    {{ scaleFactor }} -->
    <!-- {{ berechnung.ber_kunden_id }} - {{ berechnung.ber_abs_id }} -
    {{ tarif.tarif_id }} -->
    <!-- {{ element }} -->
    <v-alert type="success" v-if="showSignDone">
      <h2 class="text-white">
        Unterschrift {{ showSignType.title }} erfolgreich gespeichert
      </h2>
    </v-alert>
    <div v-if="!showSignDone" class="pa-4" style="background-color: #f2f2f2">
      <v-row
        v-if="
          element.del_sign_type == 'kontoSign' && element.del_konto_fehlt_noch
        "
        class="my-2"
      >
        <v-col>
          <h2>Von welchen Konto sollen die Beiträge abgebucht werden?</h2>
          <!-- {{ kontoDaten }} | {{ kunde.kontoSaved }} -->
          <v-radio-group class="ma-0" v-model="abweichenederKontoinhaber">
            <v-row>
              <v-col cols="6">
                <v-radio
                  key="1"
                  :label="'Konto-Inhaber: ' + kunde.antName"
                  :value="false"
                ></v-radio>
              </v-col>
              <v-col cols="6">
                <v-radio
                  key="0"
                  label="Beiträge werden von anderer Person bezahlt"
                  :value="true"
                ></v-radio>
              </v-col>
            </v-row>
          </v-radio-group>
          <div v-if="abweichenederKontoinhaber">
            <v-text-field
              @click="errorKonto = false"
              label="Kontoinhaber"
              v-model="kontoDaten.kontoinhaber"
              outlined
            />
            <v-text-field
              label="Beziehung zum Kontoinhaber z.B.: Vater, Ehemann, etc."
              v-model="kontoDaten.beziehung"
              @click="errorKonto = false"
              outlined
            />
            <v-text-field
              label="Strasse und Hausnummer Kontoinhaber"
              v-model="kontoDaten.kontoinhaberstrasse"
              @click="errorKonto = false"
              outlined
            />
            <v-text-field
              label="PLZ und Ort Kontoinhaber"
              v-model="kontoDaten.kontoinhaberort"
              @click="errorKonto = false"
              outlined
            />
          </div>

          <v-text-field
            label="IBAN"
            v-model="kontoDaten.iban"
            @click="errorKonto = false"
            outlined
          />
          <v-text-field label="BIC" v-model="kontoDaten.bic" outlined />

          <v-text-field
            label="Name der bank"
            v-model="kontoDaten.bank"
            outlined
          />

          <v-row
            class="mt-2"
            v-if="
              !element.del_sign_fehlt_noch &&
              element.del_sign_type == 'kontoSign'
            "
          >
            <v-col>
              <v-btn class="nocaps" color="blue" @click="saveSignature"
                >Jetzt Bankdaten speichern</v-btn
              >
            </v-col>
          </v-row>
          <v-alert
            type="error"
            color="red"
            class="my-2 text-white red"
            v-if="errorKonto"
          >
            <h2 class="text-white">Bitte prüfen Sie Ihre Kontodaten</h2>
            {{ errorMessage }}
          </v-alert>
        </v-col>
      </v-row>
      <div class="my-2" v-if="!sharedDataStore.isDesktop()">
        <h3>Unterschrift {{ signNr }}:</h3>
        <h1>
          <span class="text-blue">{{ showSignType.title }}</span>
        </h1>
        <v-btn
          style="font-size: 18px"
          color="blue"
          class="nocaps"
          @click="showSignMobil = !showSignMobil"
        >
          jetzt unterschreiben
        </v-btn>
        <v-dialog v-model="showSignMobil">
          <div style="background-color: #fff" class="pa-4">
            <v-row>
              <v-col cols="12">
                <h2>
                  Unterschreiben Sie hier -
                  <span class="text-green">drehen</span> Sie Ihr Smartphone
                </h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div :style="stylePad">
                  <VueSignaturePad
                    :options="signaturePadOptions"
                    ref="signaturePad"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-btn
                  color="blue"
                  @click="saveSignature"
                  style="font-size: 20px"
                  class="nocaps mb-4"
                  >speichern</v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn
                  color="grey"
                  @click="clearSignature"
                  style="font-size: 20px"
                  class="nocaps mb-4"
                  >löschen</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-dialog>
      </div>
      <div
        class="my-2"
        v-if="
          sharedDataStore.isDesktop() &&
          !signSaved &&
          element.del_sign_fehlt_noch
        "
      >
        <v-row class="mb-1">
          <v-col cols="12">
            <h2>
              Unterschrift {{ signNr }}:
              <span class="text-green">{{ showSignType.title }}</span>
            </h2>

            {{ showSignType.desc }}.
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="10">
            <h3>Unterschreiben Sie hier - mit Mouse,Trackpad oder Finger</h3>

            <div :style="stylePad">
              <VueSignaturePad
                :options="signaturePadOptions"
                ref="signaturePad"
              />
            </div>

            Keine Sorge. Ihre Unterschrift muss nicht perfekt sein.

            <v-row class="mt-2 align-start justify-start" v-if="!signSaved">
              <v-col>
                <v-btn class="nocaps" color="blue" @click="saveSignature">
                  Jetzt Unterschrift speichern
                </v-btn>
              </v-col>
              <v-col class="pr-4">
                <v-btn color="grey" class="nocaps" @click="clearSignature"
                  >löschen</v-btn
                >
              </v-col>
            </v-row>

            <!-- <div v-if="imageSrc">
              <h3>Vorschau der Unterschrift:</h3>
              <img :src="imageSrc" alt="Unterschriftsvorschau" />
            </div> -->
          </v-col>
          <v-col
            cols="12"
            md="2"
            @click="showAntrag = !showAntrag"
            v-if="sharedDataStore.isDesktop()"
            class="background-image-container"
            :style="backgroundStyle"
          >
            <div class="overlay-text">
              <h2 style="color: #305fa5 !important">Antrag anzeigen</h2>
            </div>
          </v-col>
        </v-row>
      </div>

      <v-alert type="warning" class="my-2" v-if="showError"
        ><h2 class="text-white">Fehler beim Speichern der Unterschrift</h2>
        {{ errorText }}
      </v-alert>
      <!-- <v-row>
        <v-col>
          {{ signatureData }}
        </v-col>
      </v-row> -->
      <v-dialog v-model="showAntrag" width="1200">
        <ShowAntragSign
          @close="showAntrag = false"
          :element="element"
          :tarif="tarif"
          :nr="signNr"
        />
      </v-dialog>
    </div>
  </v-container>
</template>

<script setup>
  import { ref, onMounted, defineProps, computed, defineEmits } from "vue";

  import { VueSignaturePad } from "vue-signature-pad";
  import BaseCalls from "@/services/BaseCalls";
  import ShowAntragSign from "@/components/berechnung/form/ShowAntragSign"; // Importiere die Komponente, falls benötigt
  import { useLoaderStore } from "@/stores/loaderStore";
  //import { useErrorStore } from "@/stores/errorStore";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  //const errorStore = useErrorStore();
  const loaderStore = useLoaderStore();

  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }
  const emit = defineEmits(["update-success", "update-konto"]);
  const props = defineProps({
    element: {
      type: Object,
    },
    kunde: {
      type: Object,
    },
    berechnung: {
      type: Object,
    },
    tarif: {
      type: Object,
    },
    signNr: {
      type: Number,
    },
  });
  const showSignMobil = ref(false);
  const signaturePadOptions = ref({
    // backgroundColor: "rgb(255, 255, 255)",
    backgroundColor: "rgba(0, 0, 0, 0)",
    penColor: "#305fa5",
  });

  const scaleFactor = ref(1.7);
  if (!sharedDataStore.isDesktop()) {
    scaleFactor.value = 1.7;
  }
  const showSignDone = ref(false);
  const signSaved = ref(false);
  const signFehltNoch = ref(props.element.del_sign_fehlt_noch);
  const signKontoFehltNoch = ref(props.element.del_konto_fehlt_noch);
  const signaturePad = ref(null);
  const signatureData = ref("");
  const showPad = ref(true);
  const showAntrag = ref(false);
  const errorKonto = ref(false);
  const saveKonto = ref(props.kunde.kontoSaved);
  const errorMessage = ref("");
  const kontoDaten = ref({
    action: "updateKontoDaten",
    iban: "DE89 3704 0044 0532 0130 00",
    bic: "",
    bank: "",
    kontoinhaber: props.kunde.antName,
    kontoinhaberstrasse: props.kunde.strasse,
    kontoinhaberort: props.kunde.ort,
    beziehung: "",
    abweichend: "nein",
    ber_identity_token: props.berechnung.ber_identity_token,
  });

  // const tarif = ref({ un_name: "Barmenia", tarif_title: "Z100" });
  const showError = ref(false);
  const errorText = ref("");
  const abweichenederKontoinhaber = ref(false);
  const backgroundStyle = computed(() => ({
    backgroundImage: `url('http://druck.waizmanntabelle.de/pdf_antrag/${props.element.del_tarif_id}/${props.element.del_background_image}')`,
    backgroundSize: "cover", // sorgt dafür, dass das Bild den Container vollständig bedeckt
    backgroundPosition: "center", // zentriert das Bild
    width: "100px",
    height: "180px", // oder jede andere gewünschte Höhe
    cursor: "pointer",
  }));

  // function saveSignatureAsPNG() {
  //   const { isEmpty, data } = signaturePad.value.save(); // 'image/png' ist standardmäßig
  //   if (!isEmpty) {
  //     console.log("Signatur gespeichert:", data); // 'data' enthält den Base64-kodierten PNG-String
  //     downloadImage(data, "unterschrift.png");
  //   }
  // }
  const imageSrc = ref("");
  async function saveAndShowSignature() {
    const { isEmpty, data } = signaturePad.value.saveSignature(); // 'image/png' ist standardmäßig
    if (!isEmpty) {
      imageSrc.value = data; // Speichern des Base64-Bild-Strings in der reaktiven Referenz
      const insertData = {
        action: "insertSignupData",
        image: imageSrc.value,
        sign_aid: props.berechnung.ber_kunden_id,
        sign_abs_id: props.berechnung.ber_abs_id,
        sign_scale_factor: scaleFactor.value,
        sign_del_id: props.element.del_id,
        sign_ber_id: props.berechnung.ber_id,
        sign_tarif_id: props.tarif.tarif_id,
        sign_del_width: props.element.del_width,
        sign_del_height: props.element.del_height,
        sign_del_site_id: props.element.del_site_id,
        sign_del_x: props.element.del_x,
        sign_del_y: props.element.del_y,
        sign_antragtoken: props.element.del_token,
        sign_type: props.element.del_type,
      };
      const resp = await BaseCalls.postBerechnung(insertData); // Annahme, BaseCalls ist richtig definiert
      setLoading(false);
      if (resp.data && resp.data.success) {
        console.log("Erfolg: insert", resp.data.success);
        signFehltNoch.value = false;
        showSignDone.value = true;
        signSaved.value = true;
        showPad.value = false; // Verwende `.value` um reaktive Referenz zu aktualisieren
        handleSuccess();
      }
    } else {
      console.log("Keine Unterschrift erfasst.");
    }
  }

  const saveSignature = async () => {
    showSignMobil.value = false;
    if (showError.value) {
      showError.value = false;
    }
    // Extrahiert die Koordinaten der Unterschrift
    if (signFehltNoch.value) {
      signatureData.value = signaturePad.value.toData();
    }
    // errorStore.setError(
    //   true,
    //   "Ihre Kontodaten konnten nicht übernommen werden"
    // );
    console.log("SignatureData", signatureData.value.length);
    if (
      props.element.del_sign_type == "kontoSign" &&
      signKontoFehltNoch.value
    ) {
      if (!kontoDaten.value.iban) {
        errorKonto.value = true;
        errorMessage.value = "Bitte geben Sie Ihre IBAN ein";
        return;
      }

      if (!isValidGermanIBAN(kontoDaten.value.iban)) {
        errorKonto.value = true;
        errorMessage.value = "Bitte geben Sie eine gültige deutsche  IBAN ein";
        return;
      }
      if (
        props.element.del_sign_type == "kontoSign" &&
        abweichenederKontoinhaber.value &&
        kontoDaten.value.kontoinhaber == ""
      ) {
        errorKonto.value = true;
        errorMessage.value = "Bitte geben Sie einen Kontoinhaber ein";
        return;
      }
      if (
        props.element.del_sign_type == "kontoSign" &&
        abweichenederKontoinhaber.value &&
        kontoDaten.value.kontoinhaberort == ""
      ) {
        errorKonto.value = true;
        errorMessage.value =
          "Bitte geben Sie den Wohnort und PLZ des Kontoinhabers ein";
        return;
      }
      if (
        props.element.del_sign_type == "kontoSign" &&
        abweichenederKontoinhaber.value &&
        kontoDaten.value.kontoinhaberstrasse == ""
      ) {
        errorKonto.value = true;
        errorMessage.value =
          "Bitte geben Sie die Strasse und Hausnummer des Kontoinhabers ein";
        return;
      }

      if (
        props.element.del_sign_type == "kontoSign" &&
        abweichenederKontoinhaber.value &&
        !kontoDaten.value.beziehung
      ) {
        errorKonto.value = true;
        errorMessage.value =
          "Bitte geben Sie Ihre Beziehung zum Kontoinhaber ein";
        return;
      }
    }

    if (
      signFehltNoch.value &&
      (!signatureData.value || countKoord(signatureData.value) < 7)
    ) {
      showError.value = true;
      errorText.value = "Bitte unterschreiben Sie zuerst";
      return;
    }

    if (props.element.del_sign_type == "kontoSign") {
      if (signKontoFehltNoch.value) {
        console.log("KontoSign");
        updateKontoDaten();
      }

      if (signFehltNoch.value) {
        console.log("insertSignup");
        await insertSignup();
      }
    } // end KontoSign
    else {
      // normal signUp
      await insertSignup();
    }
    // Optional: Log die Koordinaten in der Konsole, für die weitere Verwendung speichern
    console.log(signatureData.value);
  };

  const handleSuccess = () => {
    if (props.element.del_sign_type != "kontoSign" && signSaved.value) {
      console.log("Emit StandardSign Success" + props.element.del_id);
      showSignDone.value = true;
      const del_success = true; // Beispielwert, passend zu deinen Bedürfnissen
      emit("update-success", del_success, props.element.del_id);
    }
    if (
      props.element.del_sign_type == "kontoSign" &&
      saveKonto.value &&
      signSaved.value
    ) {
      showSignDone.value = true;
      const del_success = true; // Beispielwert, passend zu deinen Bedürfnissen
      emit("update-success", del_success, props.element.del_id);
    }
  };

  const stylePad = computed(() => ({
    width: `${props.element.del_width * scaleFactor.value}px`,
    height: `${props.element.del_height * scaleFactor.value}px`,
    backgroundColor: "#fff",
    border: "2px solid #2196f3",
  }));

  function isValidGermanIBAN(iban) {
    // Entfernen von Leerzeichen und Großschreibung
    iban = iban.replace(/\s+/g, "").toUpperCase();

    // Überprüfen der Länge und des Ländercodes
    if (iban.length !== 22 || !iban.startsWith("DE")) {
      return false;
    }

    // Verschieben der ersten vier Zeichen ans Ende und Ersetzen der Buchstaben
    const rearranged = iban.slice(4) + iban.slice(0, 4);
    const numericIBAN = rearranged
      .split("")
      .map((char) => {
        const code = char.charCodeAt(0);
        return code >= "A".charCodeAt(0) && code <= "Z".charCodeAt(0)
          ? code - "A".charCodeAt(0) + 10
          : char;
      })
      .join("");

    // Modulo-97-Berechnung zur Validierung der Prüfziffer
    let remainder = numericIBAN;
    let block;

    while (remainder.length > 2) {
      block = remainder.slice(0, 9);
      remainder = (parseInt(block, 10) % 97) + remainder.slice(block.length);
    }

    return parseInt(remainder, 10) % 97 === 1;
  }

  function countKoord(data) {
    if (!data) {
      return 0;
    }
    let timeCount = 0;
    data.forEach((item) => {
      // Durchlaufe jedes 'points'-Objekt in jedem Element des Arrays
      item.points.forEach((point) => {
        if (point.time) {
          // Prüfe, ob das 'time' Schlüsselwort existiert
          timeCount++;
        }
      });
    });
    return timeCount;
  }

  // async function checkInsertSign() {
  //   //prestart checkup
  //   let insertData = {
  //     action: "checkInsertSign",
  //     sign_aid: 1,
  //     sign_abs_id: 2,
  //     sign_tarif_id: props.tarif.tarif_id,
  //     sign_antragtoken: props.element.del_token,
  //     sign_type: "standard",
  //     sign_koords: signatureData.value, // Stelle sicher, dass hier die richtigen Daten übergeben werden
  //   };

  //   try {
  //     const resp = await BaseCalls.postBerechnung(insertData); // Annahme, BaseCalls ist richtig definiert
  //     if (resp.data && resp.data.success) {
  //       showSignDone.value = true;
  //       signSaved.value = true;
  //       handleSuccess();
  //     }
  //   } catch (err) {
  //     console.error("Error beim Insert", err.message);
  //   }
  // }

  async function updateKontoDaten() {
    setLoading(true);
    errorKonto.value = false;
    let updateData = kontoDaten.value;
    if (abweichenederKontoinhaber.value) {
      updateData.abweichend = "ja";
    }
    try {
      const resp = await BaseCalls.postBerechnung(updateData); // Annahme, BaseCalls ist richtig definiert

      if (resp.data && resp.data.success) {
        saveKonto.value = true;
        signKontoFehltNoch.value = false;
        emit("update-konto");
        handleSuccess();
      } else {
        saveKonto.value = true;
        signKontoFehltNoch.value = false;
        handleSuccess();
        // errorStore.setError(
        //   true,
        //   "Ihre Kontodaten konnten nicht übernommen werden"
        // );
        emit("update-konto");
      }
      setLoading(false);
    } catch (err) {
      console.error("Error beim Insert", err.message);
    }
  }

  async function insertSignup() {
    setLoading(true);
    console.log("InsertSign Start");
    let insertData = {
      action: "insertSignup",
      sign_aid: props.berechnung.ber_kunden_id,
      sign_abs_id: props.berechnung.ber_abs_id,
      sign_scale_factor: scaleFactor.value,
      sign_del_id: props.element.del_id,
      sign_ber_id: props.berechnung.ber_id,
      sign_tarif_id: props.tarif.tarif_id,
      sign_del_width: props.element.del_width,
      sign_del_height: props.element.del_height,
      sign_del_site_id: props.element.del_site_id,
      sign_del_x: props.element.del_x,
      sign_del_y: props.element.del_y,
      sign_antragtoken: props.element.del_token,
      sign_type: props.element.del_type,
      sign_koords: signatureData.value, // Stelle sicher, dass hier die richtigen Daten übergeben werden
    };
    // setLoading(false);
    // signSaved.value = true;
    // console.log("Erfolg: insert" + insertData.sign_aid);
    // showPad.value = false;
    // handleSuccess();
    try {
      const resp = await BaseCalls.postBerechnung(insertData); // Annahme, BaseCalls ist richtig definiert
      setLoading(false);
      if (resp.data && resp.data.sign_id) {
        console.log("Erfolg: insert", resp.data.sign_id);

        saveAndShowSignature();
      } else {
        showError.value = true;
        errorText.value = resp.data.errortext;
      }
    } catch (err) {
      console.error("Error beim Insert", err.message);
    }
  }

  const showSignType = computed(() => {
    return sharedDataStore.getSignType(props.element, props.kunde, "full");
  });

  const clearSignature = () => {
    signaturePad.value.clearSignature();
    signatureData.value = "";
    showError.value = false;
  };
  onMounted(() => {
    // checkInsertSign();
    console.log("SigPadMounted", signaturePad.value); // Überprüfe, ob die Ref korrekt gesetzt ist
  });
</script>
<style scoped>
  .background-image-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
  }

  .overlay-text {
    z-index: 1;
    font-size: 20px;
  }
</style>
