<template>
  <div>
    <v-card style="min-height: 800px">
      <v-card-title v-show="!sharedDataStore.isMobile()" class="headline">
        Vergleichen Sie 3 Tarife miteinander
      </v-card-title>

      <v-card-text>
        <v-alert
          v-if="sharedDataStore.isMobile()"
          border="top"
          colored-border
          type="info"
          elevation="2"
        >
          Drehen Sie bitte Ihr Handy um diesen Vergleich vollständig nutzen zu
          können.
        </v-alert>

        <v-table class="my-table">
          <thead>
            <tr>
              <th style="font-size: 14px">Tarif</th>
              <th style="width: 300px; font-size: 14px" class="blue--text">
                {{ tarif1.un_name }} {{ tarif1.tarif_title }}
              </th>
              <th style="width: 300px">
                <select
                  class="blue--text"
                  style="padding-left: 4px; font-size: 14px"
                  @change="pushTarifToVergleich($event, 2)"
                >
                  <option value selected>Tarif 2 wählen</option>
                  <option
                    style="padding-left: 10px"
                    v-for="tarif2 in alletarifeFiltered"
                    :value="tarif2.tarif_id"
                    :key="tarif2.tarif_id"
                  >
                    {{ tarif2.un_name }} {{ tarif2.tarif_title }}
                  </option>
                </select>
              </th>
              <th style="width: 300px">
                <select
                  class="blue--text"
                  style="padding-left: 4px; font-size: 14px"
                  @change="pushTarifToVergleich($event, 3)"
                >
                  <option value selected>Tarif 3 wählen</option>
                  <option
                    style="padding-left: 10px"
                    v-for="tarif3 in alletarifeFiltered"
                    :value="tarif3.tarif_id"
                    :key="tarif3.tarif_id"
                  >
                    {{ tarif3.un_name }} {{ tarif3.tarif_title }}
                  </option>
                </select>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Monatlicher Beitrag</td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                <template v-if="tarif">
                  <strong>{{ tarif.preis_value }} €</strong>

                  <span v-if="tarif.empfehlung_type == 3"
                    >| Empfehlung "beste Leistung"</span
                  >
                  <span
                    v-if="
                      tarif.empfehlung_type == 1 || tarif.empfehlung_type == 2
                    "
                    >| Alternativ-Empfehlung
                  </span>

                  <span v-if="tarif.zuschlag_sehhilfen > 0"
                    ><br />inkl.
                    {{ tarif.zuschlag_sehhilfen }}
                    Zuschlag für Sehhilfen</span
                  >
                </template>
              </td>
            </tr>
            <tr>
              <td>
                <strong class="pb-0 mb-0">Infopaket</strong>
                <br />
                <span class="caption m">kostenlos</span>
              </td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                <template v-if="tarif">
                  <v-btn
                    v-if="tarif.antraglink"
                    :href="tarif.antraglink"
                    :color="berechnung.fontColorVue"
                    style="color: white !important"
                    class="button_caps mt-1"
                  >
                    Antrag & Infopaket
                    <sup v-if="tarif.empfehlung_type > 0" class="white--text"
                      >Tipp</sup
                    >
                  </v-btn>
                </template>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Maximale Erstattung</strong><br />
                <span class="caption">Details und Zeiträume</span>
              </td>
              <td
                v-for="tarif in tarifs"
                :key="tarif.tarif_id + '_max_erstattung'"
              >
                <strong>{{ tarif.bri_erstattung_max_eur }} €</strong> |
                {{ tarif.bri_erstattung_max_zeit }}<br />
                {{ tarif.bri_sehhilfe_detail }}
              </td>
            </tr>

            <!-- Anfängliche Begrenzungen -->
            <tr>
              <td>
                <strong>Anfängliche Begrenzungen</strong><br />
                <span class="caption">Erste Jahre</span>
              </td>
              <td
                v-for="tarif in tarifs"
                :key="tarif.tarif_id + '_begrenzungen'"
              >
                {{ tarif.bri_begrenzung_zusatz_erste_jahre }}
              </td>
            </tr>

            <!-- Wartezeiten -->
            <tr>
              <td>
                <strong>Wartezeiten</strong><br />
                <span class="caption">Vertragsbedingungen</span>
              </td>
              <td
                v-for="tarif in tarifs"
                :key="tarif.tarif_id + '_wartezeiten'"
              >
                <span v-if="tarif.bri_wartezeit">{{
                  tarif.bri_wartezeit
                }}</span>
                <span v-else>Keine Wartezeit</span>
              </td>
            </tr>
            <tr>
              <td><strong>Erstattung für Sonnenbrillen</strong></td>
              <td
                v-for="tarif in tarifs"
                :key="tarif.tarif_id + '-sonnenbrillen'"
              >
                {{ tarif.bri_erstattung_sonnenbrille_short }}
              </td>
            </tr>

            <!-- Zeile für Erstattung für Gleitsichtbrille -->
            <tr>
              <td><strong>Erstattung für Gleitsichtbrille</strong></td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id + '-gleitsicht'">
                <span v-if="tarif.bri_gleitsicht == 1">Ja</span>
                <span v-else>Nein</span>
              </td>
            </tr>

            <!-- Zeile für Erstattung für Kontaktlinsen -->
            <tr>
              <td><strong>Erstattung für Kontaktlinsen</strong></td>
              <td
                v-for="tarif in tarifs"
                :key="tarif.tarif_id + '-kontaktlinsen'"
              >
                <span v-if="tarif.bri_erstattung_linse == 1">Ja</span>
                <span v-else>Nein</span>
              </td>
            </tr>

            <!-- Zeile für Erstattung für Prismenbrille -->
            <tr>
              <td><strong>Erstattung für Prismenbrille</strong></td>
              <td
                v-for="tarif in tarifs"
                :key="tarif.tarif_id + '-prismenbrille'"
              >
                {{ tarif.bri_erstattung_prisma_short }}
              </td>
            </tr>

            <!-- Zeile für Erstattung für Glaukom-Vorsorge -->
            <tr>
              <td><strong>Erstattung für Glaukom-Vorsorge</strong></td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id + '-glaukom'">
                {{ tarif.bri_glaukom }}
              </td>
            </tr>

            <!-- Zeile für Erstattung für Lasik -->
            <tr>
              <td><strong>Erstattung für Lasik</strong></td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id + '-lasik'">
                <span v-if="tarif.bri_lasik_leistung == 1">{{
                  tarif.bri_lasik_begrenzung
                }}</span>
                <span v-else>Nein</span>
              </td>
            </tr>
            <tr>
              <td><strong>Erstattung für Vorsorge-Untersuchungen</strong></td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id + '-vorsorge'">
                {{ tarif.bri_zusatz_vorsorge_short }}
              </td>
            </tr>
            <tr>
              <td><strong>Ersattung für Zusatzimpfungen</strong></td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id + '-impfungen'">
                {{ tarif.bri_zusatz_impfung_short }}
              </td>
            </tr>
            <tr>
              <td><strong>Zusätzliche Leistungen</strong></td>
              <td
                v-for="tarif in tarifs"
                :key="tarif.tarif_id + '-zusatzleistungen'"
              >
                <ul>
                  <li v-for="item in tarif.bri_umfang" :key="item.id">
                    <span
                      v-if="item.no_value"
                      class="red--text"
                      v-html="item.leistung"
                    ></span>
                    <span v-else v-html="item.leistung"></span>
                    <span v-if="item.details">
                      ...
                      <a @click="item.show_detail = !item.show_detail"
                        >mehr...</a
                      >
                      <span v-if="item.show_detail" class="pa-8">
                        <br />
                        {{ item.details }}
                      </span>
                    </span>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td><strong>Leistungen für Hörgeräte</strong></td>
              <td
                v-for="tarif in tarifs"
                :key="tarif.tarif_id + '-hoergeraete'"
              >
                {{ tarif.bri_zusatz_hoergeraet_zahl_short }}<br />
                {{ tarif.bri_zusatz_hoergeraet_detail }}
              </td>
            </tr>
            <tr>
              <td
                v-for="tarif in tarifs"
                :key="tarif.tarif_id + '-vertragsmodalitaeten'"
              >
                <strong>Mindestvertragslaufzeit der Brillenversicherung</strong
                ><br />
                {{ tarif.bri_vertrag_Mindestvertragszeit }}
              </td>
            </tr>
            <tr>
              <td><strong>Kündigungsfrist der Brillenversicherung</strong></td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id + '-kuendigung'">
                {{ tarif.tarif_int_kuendigung_details }}<br />
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
  import { ref, onMounted, watch, reactive, computed, defineProps } from "vue";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  // Props declaration with defineProps if needed in <script setup>
  const props = defineProps(["tarif1", "alletarife", "berechnung"]);
  console.log(props.tarif1.tarif_id);
  // State and reactivity
  //   const showSofortPop1 = ref(false);
  //   const showSofortPop2 = ref(false);
  //   const showSofortPop3 = ref(false);
  //   const valueSelected = ref(0);
  //   const tarifNrSelected = ref(0);
  const alletarifeFiltered = ref([]);
  // const showTable = ref(false);
  const showColspan = ref(2);

  const state = reactive({
    select: {
      tarif_id: props.tarif1.tarif_id,
      action: "beitragsentwicklung",
      berechnung: props.berechnung,
      tarif: props.tarif1,
    },
    tarif2: null, // Corrected to 'null'
    tarif3: null, // Corrected to 'null'
  });

  const tarifs = computed(() => {
    let tarifsArray = [props.tarif1]; // Start with tarif1 always present
    if (state.tarif2 !== null) {
      tarifsArray.push(state.tarif2); // Add tarif3 only if tarif2 is present and tarif3 itself is not null
    }
    if (state.tarif3 !== null) {
      tarifsArray.push(state.tarif3); // Add tarif3 only if tarif2 is present and tarif3 itself is not null
    }

    return tarifsArray;
  });

  // Equivalent of the created lifecycle hook
  onMounted(() => {
    filterAlle();
  });

  // Methods
  function filterAlle() {
    alletarifeFiltered.value = props.alletarife.filter(
      (x) => x.tarif_id !== parseInt(props.tarif1.tarif_id)
    );
  }

  function pushTarifToVergleich(event, tarif_nr) {
    if (tarif_nr === 2) {
      const treffer2 = props.alletarife.filter(
        (x) => parseInt(x.tarif_id) === parseInt(event.target.value)
      );
      state.tarif2 = treffer2[0];
    } else if (tarif_nr === 3) {
      const treffer3 = props.alletarife.filter(
        (x) => parseInt(x.tarif_id) === parseInt(event.target.value)
      );
      state.tarif3 = treffer3[0];
    }
  }
  watch(
    () => tarifs.value.length, // This is the source reactive property to watch
    (newLength) => {
      showColspan.value = newLength + 1;
    },
    {
      immediate: true, // This option will trigger the handler immediately with the current value
    }
  );
</script>

<style>
  .my-table td {
    vertical-align: top;
  }
</style>
