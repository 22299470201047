<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <h1 class="text-blue">{{ tarif.versicherung }}</h1>
        <h2>Tarif: {{ tarif.tarif_title }}</h2>
        Ø-Erstattung: {{ tarif.waizmannwert }} | KFO Ø-Erstattung:
        {{ tarif.kfowert }}
      </v-col>
    </v-row>

    <br />
    <v-row v-if="tarif_type == 0">
      <v-col cols="12" md="5"><b>AntragAnnahmecheck</b></v-col>
      <v-col cols="12" md="7"
        ><b v-if="tarif.tarif_int_antrag == 1" class="green--text"
          >Ja, verfügbar</b
        >
        <b v-if="tarif.tarif_int_antrag != 1" class="red--text"
          >Nicht verfügbar</b
        >
      </v-col>
    </v-row>
    <v-row v-if="tarif_type == 0">
      <v-col cols="12" md="5"><b>Clearment Vertragsschutz</b></v-col>
      <v-col cols="12" md="7"
        ><span v-if="tarif.tarif_clearment_vs == 1"
          ><b class="green--text">Ja, verfügbar</b>.<br />
          Bei Abschluss über WaizmannTabelle
          <b class="green--text">kostenlos</b>.</span
        >

        <b v-if="tarif.tarif_int_antrag != 1" class="red--text"
          >Nicht verfügbar</b
        >
      </v-col>
    </v-row>
    <v-row v-if="tarif_type == 0">
      <v-col cols="12" md="5"><b>Clearment Zertifizierung</b></v-col>
      <v-col cols="12" md="7">
        <b :class="tarif.tarif_show_lsk_style">{{
          tarif.tarif_show_lsk_word
        }}</b>
        <lsk-text :tarif="tarif" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" class="pb-0">
        <v-btn
          color="blue"
          style="height: 40px; width: 440px"
          elevated
          tile
          type="submit"
          class="button_caps"
          tonal
          @click="berechnungStore.closeShowPopAppTarif"
        >
          <router-link :to="sharedDataStore.getStartLink(tarif.tarif_type)">
            <span class="text-white">
              <b style="font-size: 18px" v-if="sharedDataStore.isDesktop()">
                jetzt Vergleich & Annahmecheck starten | Tipp
              </b>
              <b style="font-size: 18px" v-if="!sharedDataStore.isDesktop()">
                jetzt Vergleich starten
              </b>
            </span></router-link
          >
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" class="pt-2"
        ><b>Sie erfahren sofort:</b>
        <ul>
          <li>
            Ihren <b>monatlicher Beitrag</b> für die {{ tarif.versicherung }}
            {{ tarif.tarif_title }}
          </li>
          <li v-if="tarif.tarif_type == 0">
            ob die {{ tarif.versicherung }} Ihren Antrag annehmen würde
            (annonymer Annahmecheck)
          </li>
          <li>welche Behandlungen versichert sind</li>
        </ul>
        <b>Sie können jetzt:</b>
        <ol>
          <li>
            <router-link
              :to="sharedDataStore.getStartLink(tarif.tarif_type)"
              @click="berechnungStore.closeShowPopAppTarif"
              ><b>einen Online-Vergleich starten | Tipp</b></router-link
            >
          </li>
          <li>
            <router-link
              @click="berechnungStore.closeShowPopAppTarif"
              :to="
                sharedDataStore.getBeschreibungLink(
                  tarif.tarif_type,
                  tarif.tarif_id
                )
              "
              >ausführliche Tarifbeschreibung lesen</router-link
            >
          </li>
        </ol>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  import { useSharedDataStore } from "@/stores/sharedData";

  const sharedDataStore = useSharedDataStore();
  const berechnungStore = useBerechnungStore();
  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
