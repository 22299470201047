<template>
  <div>
    <h3>Zahnerhalt-Leistungen {{ tarif.tarif_leistung_zb }} %</h3>
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Wartezeit -->
    <v-row>
      <v-col cols="12" md="4">
        <strong>Wartezeit</strong>
        <br />
        <span class="caption">für Zahnerhalt</span>
      </v-col>
      <v-col cols="12" md="8">
        <strong>{{ tarif.tarif_beschreibung_wz_zb }}</strong>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Kunststofffüllungen -->
    <v-row>
      <v-col cols="12" md="4">
        <strong>Kunststofffüllungen</strong>
      </v-col>
      <v-col cols="12" md="8">
        <strong>{{ tarif.tarif_beschreibung_ksf }}</strong>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Funktionsanalyse -->
    <v-row>
      <v-col cols="12" md="4">
        <strong>Funktionsanalyse</strong>
        <br />
        <span class="caption">für Zahnerhalt</span>
      </v-col>
      <v-col cols="12" md="8">
        <strong>{{ tarif.tarif_beschreibung_funktion_zb }}</strong>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Wurzelbehandlung -->
    <v-row>
      <v-col cols="12" md="4">
        <strong>Wurzelbehandlung</strong>
      </v-col>
      <v-col cols="12" md="8">
        <strong>{{ tarif.tarif_beschreibung_wurzel_ogkv }}</strong> | wenn GKV
        nicht leistet
        <br />
        <strong>{{ tarif.tarif_beschreibung_wurzel_mgkv }}</strong> |
        <span class="red--text">wenn GKV leistet</span>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Parodontose-Leistungen -->
    <v-row>
      <v-col cols="12" md="4">
        <strong>Parodontose-Leistungen</strong>
      </v-col>
      <v-col cols="12" md="8">
        <strong>{{ tarif.tarif_beschreibung_paro_ogkv }}</strong> | wenn GKV
        nicht leistet
        <br />
        <strong>{{ tarif.tarif_beschreibung_paro_mgkv }}</strong> |
        <span class="red--text">wenn GKV leistet</span>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
