<template>
  <div>
    <v-row>
      <v-col cols="12" md="2">
        <img width="50" :src="require('@/assets/clearment/small.png')"
      /></v-col>
      <v-col cols="12" md="10"
        ><h3 class="redtext">Cleament</h3>
        <h2>Vertragsschutz</h2>
        Der Clearment Vertragsschutz ist die Versicherung für Ihre Versicherung.
        Bei Abschluss <b>kostenlos</b> während der gesamten Vertragslaufzeit.
        <a @click="berechnungStore.setShowPop(false, 'vertragsschutz')"
          >Lesen Sie mehr dazu...</a
        >
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { useBerechnungStore } from "@/stores/berechnung";
  const berechnungStore = useBerechnungStore();
</script>
