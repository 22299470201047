<template>
  <div>
    <v-row class="mt-1">
      <v-col cols="3" class="mt-0 py-1">
        <strong class="text-green">Wechsel-Empfehlung:</strong>
      </v-col>
      <v-col cols="9" class="mt-0 py-1">
        <span v-html="nl2br(tarif.tarif_wechsel_angebot_details)"></span>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  import { useNl2Br } from "@/composable/useNl2Br";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();
  const { nl2br } = useNl2Br();
  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
