<template>
  <div>
    <span style="font-size: 18px"
      >Als <strong>"fehlende Zähne"</strong> anzugeben sind</span
    >
    <br />
    <ul>
      <li>
        unversorgte Lücken im Gebiss, an deren Stelle noch
        <strong>Platz für möglichen Zahnersatz</strong> ist
      </li>
      <li>
        <strong class="text-green">Waizmann hilft:</strong> Unser Rechner zeigt
        Ihnen auch Tarife, die auch Lücken mitversichern (kostenlos, mit
        Zuschlag oder Einschränkungen)
      </li>
    </ul>
    <span style="font-size: 18px"
      ><strong class="text-green">"nicht anzugeben"</strong> sind</span
    >
    <br />
    <ul>
      <li><strong>fehlende Weisheitszähne</strong></li>
      <li>
        bei Kindern <strong>fehlende Milchzähne</strong>, sofern die zweiten
        Zähne noch ganz normal nachwachsen
      </li>
      <li>
        bereits durch Zahnersatz <strong>ersetze Zähne</strong> (z.B. Brücken,
        Implantate oder Prothesen)
      </li>
      <li>
        ein vollständiger <strong>Lückenschluss</strong>, d.h. wenn eine ehemals
        vorhandene Lücke durch die Nachbarzähne geschlossen wurde
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "luecke-info",
    components: {},
  };
</script>

<style scoped></style>
