<template>
  <v-container>
    <AnnahmecheckLoader
      :berechnung="berechnung"
      v-if="showLoader && berechnung.ber_segment <= 1"
    />
    <StandardLoader
      :berechnung="berechnung"
      v-if="showLoader && berechnung.ber_segment > 1"
    />

    <v-row>
      <v-col cols="12" md="9">
        <RechnerStep1 @updateBerechnung="handleBerechnungUpdate" />
      </v-col>
      <v-col v-if="!sharedDataStore.isMobile()" cols="3">
        <!-- {{ berechnung }} -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
  import { ref, onMounted, getCurrentInstance } from "vue";
  import RechnerStep1 from "@/components/berechnung/form/RechnerStep1";
  import { useBerechnungStore } from "@/stores/berechnung";
  import AnnahmecheckLoader from "@/components/berechnung/form/AnnahmecheckLoader";
  import StandardLoader from "@/components/berechnung/form/StandardLoader";
  import { useSharedDataStore } from "@/stores/sharedData";
  import { useErrorStore } from "@/stores/errorStore";

  const errorStore = useErrorStore();
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const berechnungStore = useBerechnungStore();

  import { useRouter } from "vue-router";
  const router = useRouter();
  const berechnung = ref(false);
  const showLoader = ref(false);

  function handleBerechnungUpdate(data) {
    berechnung.value = data;
    // console.log("handleBerechnungUpdate", berechnung.value);
    if (berechnung.value.berGestartet) {
      showLoader.value = true;
      setTimeout(() => {
        showLoader.value = false;
        if (
          berechnung.value.ber_identity_token != "" &&
          berechnung.value.ber_identity_token !== undefined
        ) {
          router.push(
            "/vergleich/ergebnis/" + berechnung.value.ber_identity_token
          );
        } else {
          errorStore.setError(true, "Fehler beim Laden der Berechnung.");
        }
      }, 5000);
    }
  }
  onMounted(() => {
    berechnungStore.setShowFooter(false);
    proxy.$updateDocumentHead(
      "Berechnung starten - 100 % anonym",
      "Berechnung starten - 100 % anonym"
    );
  });
</script>
