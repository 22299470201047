<template>
  <div>
    <!-- Verwendung des @click-Handlers, um showTeilen zu togglen -->

    <!-- Dialog zur Anzeige des Teilens -->

    <v-card class="pa-4" style="height: 1500px">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="9">
            <h2>
              Teilen Sie dieses Ergebnis - Nr.:
              {{ berechnung.ber_id }}
            </h2>
            Kopieren und versenden Sie diesen Link, um das Berechnungsergebnis
            mit anderen Personen zu teilen:
            <br /><br />
            <!-- Verbesserter Einsatz von v-model für die Anzeige der URL -->
            <v-textarea
              readonly
              width="888"
              v-model="berechnungsLink"
            ></v-textarea>
            <br />
            <b class="text-green">Hinweis:</b> Das Ergebnis kann nur mit diesem
            verschlüsselten Link geöffnet werden. Andere Personen können das
            Ergebnis nicht öffnen.</v-col
          >
          <v-col cols="12" md="3">
            <h3>Oder scannen</h3>
            <QrcodeVue :value="berechnungsLink" :size="120"
          /></v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
  import { defineProps, computed } from "vue";
  // Stellen Sie sicher, dass die Icons korrekt importiert werden
  import QrcodeVue from "qrcode.vue";
  // Definition der props und Verwendung von ref

  const props = defineProps({
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });

  // Berechnete Eigenschaft für den Link
  const berechnungsLink = computed(
    () =>
      `https://rechner.waizmannpro.de/vergleich/ergebnis/${props.berechnung.ber_identity_token}`
  );
</script>
