// composable/useNl2Br.js
export function useNl2Br() {
  const nl2br = (str) => {
    if (!str) return "";
    let modifiedStr = str.replace(/\n/g, "<br>");
    modifiedStr = modifiedStr.replace(/{strong_red}/g, '<b class="text-red">');
    modifiedStr = modifiedStr.replace(/{strong_red_end}/g, "</b>");
    modifiedStr = modifiedStr.replace(
      /{strong_green}/g,
      '<b class="text-green">'
    );
    modifiedStr = modifiedStr.replace(/{strong_green_end}/g, "</b>");
    modifiedStr = modifiedStr.replace(/{strong}/g, "<b>");
    modifiedStr = modifiedStr.replace(/{strong_end}/g, "</b>");
    return modifiedStr;
  };

  return { nl2br };
}
