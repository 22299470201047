<template>
  <div>
    <v-row>
      <v-col cols="12"></v-col>
      <v-col cols="12" md="9"> </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { onMounted } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  async function sendMail(aid) {
    if (aid == 109629) {
      return;
    }
    let berSelect = {};
    berSelect.action = "sendEmail";
    berSelect.empfaenger = "mails738@gmail.com";
    berSelect.aid = aid;
    berSelect.tarif_name_wtb = "testTarife";

    //berSelect.text = 313;

    // berSelect.text = 148;

    berSelect.text = 313;

    try {
      const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
      if (resp.data) {
        console.log(resp.data);
      }
    } catch (err) {
      console.log("error insert");
    }
  }

  onMounted(() => {
    sendMail(109629);
  });
</script>
