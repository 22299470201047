<template>
  <div>
    <v-dialog v-model="showDialog" width="777">
      <v-card class="pa-4">
        <h2>Vorab-Annahmecheck | bitte haben Sie ein bisschen Geduld</h2>

        Wir fragen jetzt anonym die Annahmebedingungen der Versicherungen ab.
        <br /><br />

        <v-row v-for="insurance in insurances" :key="insurance.name">
          <v-col cols="12" md="12">
            <v-progress-linear
              style="max-width: 540px"
              height="35"
              color="green"
              v-model="insurance.value"
              :buffer-value="bufferValue"
            >
              <strong
                >{{ insurance.value }}% - Annahmecheck
                {{ insurance.name }}</strong
              >
              | {{ insurance.criteriaCount }} Kriterien wurden geprüft
            </v-progress-linear>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
  import { ref, reactive, onMounted, onBeforeUnmount } from "vue";

  const showDialog = ref(true);
  const bufferValue = ref(100);
  const insurances = reactive([
    { name: "Bayerische", value: ref(10), criteriaCount: 20 },
    { name: "Arag", value: ref(10), criteriaCount: 36 },
    { name: "Ergo Direkt", value: ref(10), criteriaCount: 12 },
    { name: "Barmenia", value: ref(10), criteriaCount: 23 },
    { name: "Signal Iduna", value: ref(10), criteriaCount: 33 },
    { name: "AXA", value: ref(10), criteriaCount: 37 },
    { name: "DFV", value: ref(10), criteriaCount: 37 },
    { name: "OttoNova", value: ref(10), criteriaCount: 42 },
    { name: "Gothaer", value: ref(10), criteriaCount: 26 },
  ]);

  const incrementValues = () => {
    const increment = (insurance) => {
      const interval = setInterval(() => {
        if (insurance.value < 100) {
          insurance.value += Math.ceil(Math.random() * 10 + 5);
        }
        if (insurance.value >= 100) {
          insurance.value = 100;
          clearInterval(interval);
        }
      }, 700);
      onBeforeUnmount(() => clearInterval(interval));
    };

    insurances.forEach((insurance) => increment(insurance));
  };

  onMounted(() => {
    incrementValues();
  });
</script>
