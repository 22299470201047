<template>
  <div style="max-width: 888px">
   <v-row>
    <v-col cols="12" md="8"><StartZahnNew />
      <StartZusatzNew />
    </v-col>
    <v-col cols="4" class="pl-6" v-if="!sharedDataStore.isMobile()">
      
      <v-row >
        <v-col cols="3"><img :src="require('@/assets/clearment/small.png')" /></v-col>
        <v-col cols="9" class="pt-4" >
          <div style="border-right: 3px solid #d84315;" class="pr-2">
          <h3 class="my-0">Clearment</h3>
          <h2 class="my-0">Vertragsschutz</h2>
          Der Clearment Vertragsschutz
              <a 
                >www.clearment.de</a
              >
              <b> sichert</b> Ihre vertraglich zugesicherten Leistungen ab.
              Damit Ihre Versicherung auch wirklich leistet, wenn Sie diese
              brauchen.
              <br><br>
              <h3>Kostenlos für gesamte Vertragslaufzeit</h3>
            Den Clearment VertragsSchutz erhalten Sie hier kostenlos für die gesamte Vertragslaufzeit (bei ausgewählten Tarifen).
        </div>
        </v-col>
      </v-row>
    
    </v-col>
   </v-row>
  
>
  </div>
</template>

<script setup>
  import { getCurrentInstance, onMounted } from "vue";
  import StartZahnNew from "@/views/rechner/mini/StartZahnNew";
  import StartZusatzNew from "@/views/rechner/mini/StartZusatzNew";
  import { useBerechnungStore } from "@/stores/berechnung";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const berechnungStore = useBerechnungStore();
  const { proxy } = getCurrentInstance();
  onMounted(() => {
    berechnungStore.setShowFooter(false);
    proxy.$updateDocumentHead(
      "Welche Zusatzversicherung wollen Sie vergleichen?",
      "Alle WaizmannTabelle Zusatzversicherungs-Rechner"
    );
  });
</script>
