<template>
  <div>
    <!-- 'Heilpraktiker' Card -->
    <v-card 
      @click="startRechner('heilpraktiker')"
      class="mx-auto pa-0 mt-6"
      :class="{ 'hovered-card': hoverState.heilpraktiker }"
      @mouseover="hoverState.heilpraktiker = true"
      @mouseleave="hoverState.heilpraktiker = false"
      style="cursor: pointer;"
    >
      <v-row>
        <v-col cols="1" class="pa-6">
          <v-icon
            color="#088A85"
            style="font-size: 36px;"
            :icon="hoverState.heilpraktiker ? 'mdi-meditation' : 'mdi-meditation'"
          ></v-icon>
        </v-col>
        <v-col cols="10" class="pa-6">
          <h2>Heilpraktiker Zusatzversicherung</h2>
          Schwerpunkt Heilpraktiker & Naturheilverfahren
        </v-col>
      </v-row>
      <!-- Show v-card-actions only on hover -->
      <v-card-actions
        v-show="hoverState.heilpraktiker"
        class="pa-0 mt-0"
        style="background-color: #f5f5f5;"
      >
        <v-spacer></v-spacer>
        <span class="mr-4" v-if="!sharedDataStore.isMobile()">in nur <b>30 Sekunden</b> erhalten Sie Ihren Vergleich</span>
        <v-btn
          variant="outlined"
          color="#088A85"
          style="background-color: #fff;"
          class="nocaps mr-4"
        >
          Jetzt vergleichen
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- 'Brille' Card -->
    <v-card 
      @click="startRechner('brille')"
      class="mx-auto pa-0 mt-6"
      :class="{ 'hovered-card': hoverState.brille }"
      @mouseover="hoverState.brille = true"
      @mouseleave="hoverState.brille = false"
      style="cursor: pointer;"
    >
      <v-row>
        <v-col cols="1" class="pa-6">
          <v-icon
            color="#FF8000"
            style="font-size: 36px;"
            :icon="hoverState.brille ? 'mdi-glasses' : 'mdi-glasses'"
          ></v-icon>
        </v-col>
        <v-col cols="10" class="pa-6">
          <h2>Brillen Zusatzversicherung</h2>
          Schwerpunkt Brillen, Sehhilfen & augenärztliche Behandlungen
        </v-col>
      </v-row>
      <!-- Show v-card-actions only on hover -->
      <v-card-actions
        v-show="hoverState.brille"
        class="pa-0 mt-0"
        style="background-color: #f5f5f5;"
      >
        <v-spacer></v-spacer>
        <span class="mr-4" v-if="!sharedDataStore.isMobile()">in nur <b>30 Sekunden</b> erhalten Sie Ihren Vergleich</span>
        <v-btn
          variant="outlined"
          color="#FF8000"
          style="background-color: #fff;"
          class="nocaps mr-4"
        >
          Jetzt vergleichen
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- 'Krankenhaus' Card -->
    <v-card 
      @click="startRechner('krankenhaus')"
      class="mx-auto pa-0 mt-6"
      :class="{ 'hovered-card': hoverState.krankenhaus }"
      @mouseover="hoverState.krankenhaus = true"
      @mouseleave="hoverState.krankenhaus = false"
      style="cursor: pointer;"
    >
      <v-row>
        <v-col cols="1" class="pa-6">
          <v-icon
            color="#00BCD4"
            style="font-size: 36px;"
            :icon="hoverState.krankenhaus ? 'mdi-hospital-building' : 'mdi-hospital-building'"
          ></v-icon>
        </v-col>
        <v-col cols="10" class="pa-6">
          <h2>Krankenhaus Zusatzversicherung</h2>
          Schwerpunkt stationäre Behandlungen & Chefarztbehandlungen
        </v-col>
      </v-row>
      <!-- Show v-card-actions only on hover -->
      <v-card-actions
        v-show="hoverState.krankenhaus"
        class="pa-0 mt-0"
        style="background-color: #f5f5f5;"
      >
        <v-spacer></v-spacer>
        <span class="mr-4" v-if="!sharedDataStore.isMobile()">in nur <b>30 Sekunden</b> erhalten Sie Ihren Vergleich</span>
        <v-btn
          variant="outlined"
          color="#00BCD4"
          style="background-color: #fff;"
          class="nocaps mr-4"
        >
          Jetzt vergleichen
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script setup>
  import {reactive} from "vue";
  import { useRouter } from "vue-router";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const hoverState = reactive({
  brille: false, // Set to true for initial visibility
  heilpraktiker: false,
  krankenhaus: false,
});
  const router = useRouter();

  function startRechner(segment) {
    switch (segment) {
      case "brille":
        router.push("/vergleich/starten/brille");
        break;
      case "heilpraktiker":
        router.push("/vergleich/starten/heilpraktiker");
        break;
      case "krankenhaus":
        router.push("/vergleich/starten/krankenhaus");
        break;
      default:
        console.error("Unbekanntes Segment:", segment);
    }
  }
</script>

<style scoped>
.hovered-card {
  transform: scale(1.05);
  transition: transform 0.2s;
}
</style>
