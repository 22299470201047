<template>
  <div>
    <v-row>
      <v-col cols="12"
        ><h2 class="mb-4">Zahnzusatzversicherung & Parandontose</h2>
        <h3>1. Bereits erfolgreich gestoppte Parodontose-Behandlungen</h3>

        Wenn Ihre Parodontose erfolgreich gestoppt wurde, ist ein Abschluss
        einer Zahnzusatzversicherung möglich. Achten Sie aber unbedingt auf eine
        “positive” Parodontose-Klausel bei Ihrer neuen Zahnzusatzversicherung.
        Bei dieser Klausel werden die Folgen der Grunderkrankung (Parodontose)
        nicht explizit vom Versicherungsschutz ausgeschlossen. D.h. sobald eine
        Parodontose-Behandlungen erfolgreich gestoppt wurde, wird für alle
        folgenden Erkrankungen erstattet. <br /><br />
        Hat ein Tarif diese Klausel nicht, kann er im Zweifel immer die Leistung
        verweigern und sich auf die Grunderkrankung berufen.
        <br /><br />
        Eine Parodontose-Behandlung ist erst dann als erfolgreich gestoppt zu
        werten, wenn keine Nachbehandlungen oder Nachkontrollen mehr angeraten
        sind.
        <br /><br />
        <h3>
          2. Es wurde eine Parodontose diagnostiziert / Sie befinden sich in
          bzw. vor einer Parodontose-Behandlung
        </h3>
        Bei laufenden bzw. angeratenen Parodontose-Behandlungen können Sie
        grundsätzlich keine Versicherungsansprüche geltend machen.
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  //   import { useBerechnungStore } from "@/stores/berechnung";
  //   import { useSharedDataStore } from "@/stores/sharedData";
  //   const sharedDataStore = useSharedDataStore();
  //   const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
