<template>
  <div>
    <v-row>
      <v-col cols="12" md="2"><b>Tarif</b></v-col>
      <v-col cols="12" md="2"><b>Preis</b></v-col>
      <v-col cols="12" md="2"
        ><b>Ø-Erstattung</b>
        <span v-if="berechnung.ber_algo_view == 2"><br />KFO/Kind</span>
        <span v-if="berechnung.ber_algo_view != 2"
          ><br />Zahnersatz/Zahnerhalt</span
        >
      </v-col>
      <v-col cols="12" md="2"
        ><b>Clearment</b><br />
        Vertragsschutz</v-col
      >
      <v-col cols="12" md="2"
        ><b>Clearment</b><br />
        Zertififzierung</v-col
      >
      <v-col cols="12" md="2"><b>Infopaket</b></v-col>
    </v-row>
    <v-row v-for="item in alletarife" :key="item.tarif_id">
      <v-col cols="12" md="2"
        ><router-link v-if="item.antraglink" :to="item.antraglink"
          ><a
            ><b
              >{{ item.un_name }}
              <span v-if="item.empfehlung_type > 0">
                <sup class="text-green">Tipp</sup>
              </span></b
            >
          </a></router-link
        ><br />
        {{ item.tarif_title }}</v-col
      >

      <v-col cols="12" md="2"
        ><b>{{ item.preis_value }}€</b></v-col
      >
      <v-col cols="12" md="2"
        ><b v-if="berechnung.ber_algo_view == 2"
          >{{ item.tarif_kwert_gesamt }}%</b
        >
        <b v-if="berechnung.ber_algo_view != 2"
          >{{ item.tarif_waizmannwert }}%</b
        >
      </v-col>
      <v-col cols="12" md="2">
        <b v-if="item.tarif_clearment_vs > 0" class="text-green"
          >Ja, kostenlos</b
        >
        <b v-if="item.tarif_clearment_vs == 0" class="text-red"
          >Nein, nicht verfügbar</b
        ></v-col
      >
      <v-col cols="12" md="2">
        <span :class="item.tarif_show_lsk_style">
          <b>{{ item.tarif_show_lsk_word }}</b>
        </span>
      </v-col>
      <v-col cols="12" md="2">
        <router-link v-if="item.antraglink" :to="item.antraglink">
          <a class="noUnderline"
            ><b>Angebot</b>
            <span v-if="item.empfehlung_type > 0">
              <sup class="text-green">Tipp</sup>
            </span></a
          >
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    alletarife: {
      type: Array,
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
