<template>
  <div>
    <v-row>
      <v-col cols="12" md="12"
        ><h2>Ärztlich festgestellte Zahn- Kieferfehlstellung</h2>
        Hier ist der Zusatz “ärztlich festgestellt” tatsächlich entscheidend. In
        dem Moment, wo der Zahnarzt oder Kieferorthopäde eine Zahn- /
        Kieferfehlstellung erstmals ärztlich diagnostiziert, tritt der
        Versicherungsfall ein. Das gilt unabhängig davon, ob zu diesem Zeitpunkt
        bereits eine Behandlungsnotwendigkeit bestanden hat oder nicht.<br /><br />Ab
        diesem Zeitpunkt muss die entsprechende Gesundheitsfrage im Antrag auch
        mit “Ja” beantwortet werden. Das bedeutet dann nichts anders, als dass
        der kieferorthopädische Bereich grundsätzlich NICHT mehr versicherbar
        ist.
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
