<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Erwachsenen Card -->
        <v-card
          @click="startRechner('erwachsene')"
          class="mx-auto pa-0"
          :class="{ 'hovered-card': hoverState.erwachsene }"
          @mouseover="hoverState.erwachsene = true"
          @mouseleave="hoverState.erwachsene = false"
          style="cursor: pointer;"
        >
          <v-row>
            <v-col cols="1" class="pa-6">
              <v-icon
                color="#2196f3"
                style="font-size: 36px;"
                :icon="hoverState.erwachsene ? 'mdi-tooth' : 'mdi-tooth-outline'"
              ></v-icon>
            </v-col>
            <v-col cols="10" class="pa-6">
              <h2>Zahnzusatzversicherung für Erwachsene</h2>
              Schwerpunkt Zahnersatz & Zahnerhalt
            </v-col>
          </v-row>
          <v-card-actions
            v-if="hoverState.erwachsene"
            class="pa-0 mt-0"
            style="background-color: #f5f5f5;"
          >
            <v-spacer></v-spacer>
            <span class="mr-4" v-if="!sharedDataStore.isMobile()">in nur <b>30 Sekunden</b> erhalten Sie Ihren Vergleich</span>
            <v-btn
              variant="outlined"
              color="#2196f3"
              style="background-color: #fff;"
              class="nocaps mr-4"
            >
              jetzt Vergleich starten
            </v-btn>
          </v-card-actions>
        </v-card>

        <!-- Kinder Card -->
        <v-card
          @click="startRechner('kind')"
          class="mx-auto pa-0 mt-6"
          :class="{ 'hovered-card': hoverState.kinder }"
          @mouseover="hoverState.kinder = true"
          @mouseleave="hoverState.kinder = false"
          style="cursor: pointer;"
        >
          <v-row>
            <v-col cols="1" class="pa-6">
              <v-icon
                color="#fecf0c"
                style="font-size: 36px;"
                :icon="hoverState.kinder ? 'mdi-tooth' : 'mdi-tooth-outline'"
              ></v-icon>
            </v-col>
            <v-col cols="10" class="pa-6">
              <h2>Zahnzusatzversicherung für Kinder</h2>
              Schwerpunkt Kieferorthopädie & Kinderleistungen
            </v-col>
          </v-row>
          <v-card-actions   v-if="hoverState.kinder"  class="pa-0 mt-0" style="background-color: #f5f5f5;">
            <v-spacer></v-spacer>
            <span v-if="hoverState.kinder && !sharedDataStore.isMobile()" class="mr-4">in nur <b>30 Sekunden</b> erhalten Sie Ihren Vergleich</span>
            <v-btn
              variant="outlined"
              color="#fecf0c"
              style="background-color: #fff;"
              class="nocaps mr-4"
            >
            jetzt Vergleich starten
            </v-btn>
          </v-card-actions>
        </v-card>

        <!-- 60 Plus Card -->
        <v-card
          @click="startRechner('60plus')"
          class="mx-auto pa-0 mt-6"
          :class="{ 'hovered-card': hoverState.plus60 }"
          @mouseover="hoverState.plus60 = true"
          @mouseleave="hoverState.plus60 = false"
          style="cursor: pointer;"
        >
          <v-row>
            <v-col cols="1" class="pa-6">
              <v-icon
                color="#513b83"
                style="font-size: 36px;"
                :icon="hoverState.plus60 ? 'mdi-tooth' : 'mdi-tooth-outline'"
              ></v-icon>
            </v-col>
            <v-col cols="10" class="pa-6">
              <h2>Zahnzusatzversicherung 60 Plus</h2>
              Schwerpunkt Zahnerhalt & Prothetik
            </v-col>
          </v-row>
          <v-card-actions   v-if="hoverState.plus60" class="pa-0 mt-0" style="background-color: #f5f5f5;">
            <v-spacer></v-spacer>
            <span v-if="hoverState.plus60  && !sharedDataStore.isMobile()" class="mr-4">in nur <b>30 Sekunden</b> erhalten Sie Ihren Vergleich</span>
            <v-btn
              variant="outlined"
              color="#513b83"
              style="background-color: #fff;"
              class="nocaps mr-4"
            >
            jetzt Vergleich starten
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useSharedDataStore } from "@/stores/sharedData";
const sharedDataStore = useSharedDataStore();

const router = useRouter();

const hoverState = reactive({
  erwachsene: true, // Setzen auf true für initiale Sichtbarkeit
  kinder: false,
  plus60: false,
});

function startRechner(segment) {
  switch (segment) {
    case 'erwachsene':
      router.push('/vergleich/starten');
      break;
    case 'kind':
      router.push('/vergleich/starten/kind');
      break;
    case '60plus':
      router.push('/vergleich/starten/60plus');
      break;
    default:
      console.error('Unbekanntes Segment:', segment);
  }
}
</script>

<style scoped>
.hovered-card {
  transform: scale(1.05);
  transition: transform 0.2s;
}
</style>
