<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <v-card rounded="0" @click="startRechner('heilpraktiker')">
          <v-sheet color="#088A85" height="200px" class="d-flex align-end">
            <v-card-title class="text-white pa-4">
              Heilpraktiker <br />Zusatzversicherung
            </v-card-title>
          </v-sheet>

          <v-card-actions>
            <h2>Vergleich starten</h2>
            <v-spacer></v-spacer>

            <v-btn color="medium-emphasis" icon="mdi-play"></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card rounded="0" @click="startRechner('brille')">
          <v-sheet color="#FF8000" height="200px" class="d-flex align-end">
            <v-card-title class="text-white pa-4">
              Brillen <br />Zusatzversicherung
            </v-card-title>
          </v-sheet>

          <v-card-actions>
            <h2>Vergleich starten</h2>
            <v-spacer></v-spacer>

            <v-btn color="medium-emphasis" icon="mdi-play"></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card rounded="0" @click="startRechner('krankenhaus')">
          <v-sheet color="#00BCD4" height="200px" class="d-flex align-end">
            <v-card-title class="text-white pa-4">
              Krankenhaus <br />Zusatzversicherung
            </v-card-title>
          </v-sheet>

          <v-card-actions>
            <h2>Vergleich starten</h2>
            <v-spacer></v-spacer>

            <v-btn color="medium-emphasis" icon="mdi-play"></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import {} from "vue";
  import { useRouter } from "vue-router";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();

  const router = useRouter();

  function startRechner(segment) {
    switch (segment) {
      case "brille":
        router.push("/vergleich/starten/brille");
        break;
      case "heilpraktiker":
        router.push("/vergleich/starten/heilpraktiker");
        break;
      case "krankenhaus":
        router.push("/vergleich/starten/krankenhaus");
        break;
      default:
        console.error("Unbekanntes Segment:", segment);
    }
  }
</script>
