<template>
  <div>
    <h3>
      Clearment Sicherheitsklasse |
      <a @click="berechnungStore.setShowPop(tarif, 'clearmentlsk')">Info</a>
    </h3>
    <v-row>
      <v-col cols="12" md="8">
        Die {{ tarif.un_name }} {{ tarif.tarif_title }} hat
        <span v-if="tarif.tarif_clearment_lsk == 5">
          <b class="text-green">die höchstmögliche</b> Clearment
          Leistungs-Sicherheitsklasse
        </span>

        <span v-if="tarif.tarif_clearment_lsk == 3"
          >die zweithöchste Clearment Leistungs-Sicherheitsklasse
        </span>
        <span v-if="tarif.tarif_clearment_lsk == 1"
          >die Clearment Leistungs-Sicherheitsklasse <b>1</b>.
        </span>
      </v-col>
      <v-col cols="12" md="4">
        <span v-if="tarif.tarif_clearment_lsk == 5">
          <b class="text-green" style="font-size: 18px">LSK 5</b><br />
          <span class="text-grey" style="font-size: 14px">LSK 3</span><br />
          <span class="text-grey" style="font-size: 14px">LSK 1</span>
        </span>
        <span v-if="tarif.tarif_clearment_lsk == 3">
          <span class="text-grey" style="font-size: 14px">LSK 5</span><br />
          <b class="text-orange" style="font-size: 18px">LSK 3</b><br />
          <span class="text-grey" style="font-size: 14px">LSK 1</span>
        </span>
        <span v-if="tarif.tarif_clearment_lsk == 1">
          <span class="text-grey" style="font-size: 14px">LSK 5</span><br />
          <span class="text-grey" style="font-size: 14px">LSK 3</span><br />
          <b class="text-grey" style="font-size: 18px">LSK 1</b>
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  const berechnungStore = useBerechnungStore();
  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
