<template>
  <div>
    <v-table>
      <thead>
        <tr style="vertical-align: top">
          <th>Tarif</th>
          <th>Preis</th>
          <th>Ø-Erstattung</th>
          <th>Angebot</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in alletarife"
          :key="item.tarif_id"
          style="vertical-align: top"
        >
          <td>
            <strong>
              <a :href="item.antraglink">{{ item.un_name }}</a>
            </strong>
            <br />
            {{ item.tarif_title }}
            <span v-if="item.empfehlung_type > 0">
              <br />
              <strong class="text-green">Empfehlung</strong>
            </span>
          </td>

          <td>{{ item.preis_value }} €</td>
          <td>{{ item.kh_ww }} %</td>

          <td>
            <a v-if="item.antraglink" :href="item.antraglink">Angebot</a>
            <span v-if="item.empfehlung_type > 0">
              <sup class="text-green">Tipp</sup>
            </span>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    alletarife: {
      type: Array,
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
