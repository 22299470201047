<template>
  <div>
    <h3>KFO-Erstattung (Kieferorthopädie)</h3>
    <!-- KFO Tarifleistung -->
    <v-row>
      <v-col cols="12" md="4">
        <strong>KFO Tarifleistung</strong>
      </v-col>
      <v-col cols="12" md="8">
        <strong>{{ tarif.tarif_leistung_kfo_ogkv }} % | KIG 1-2</strong>
        <br />
        <strong>{{ tarif.tarif_leistung_kfo_mgkv }} % | KIG 3-5</strong>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Conditional Block for Specific Tarif ID -->
    <v-row v-if="tarif.tarif_id == 276">
      <v-col cols="12" md="4">
        <strong>Sofortleistung</strong>
      </v-col>
      <v-col cols="12" md="8">
        Der Tarif "Kieferorthopädie Sofort" leistet als einziger Tarif für
        bereits angeratene oder sogar laufende Behandlungen bei Kindern und
        Jugendlichen im kieferorthopädischen Bereich. Eine Leistung ist sofort
        nach Versicherungbeginn ohne Wartezeit im Rahmen der anfänglichen
        Summenbegrenzungen möglich. Der Tarif leistet für den KFO-Bereich mit
        50% der Restkosten. Wird der Tarif in den ersten vier
        Versicherungsjahren nicht in Anspruch genommen, erhöht sich die Leistung
        auf 75% der Restkosten. Erbringt die Krankenkasse für die KFO-Behandlung
        keine Vorleistung, ist die Leistung aus dem Tarif auf max. 250,- Euro
        pro Versicherungsjahr begrenzt.
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row>
      <v-col cols="12" md="4">
        <strong>KFO Ø-Begrenzung</strong>
      </v-col>
      <v-col cols="12" md="8">
        <strong>{{ tarif.tarif_leistung_kfo_beg_ogkv }} | KIG 1-2</strong>
        <span v-if="tarif.tarif_leistung_kfo_med_not == 1">
          <br />
          <b class="text-green">KFO-Sonderklausel vorhanden</b>
          <br />Im Bereich Kieferorthopädie bei Kindern leistet dieser Tarif
          auch unabhängig von der medizinischen Notwendigkeit für
          kieferorthopädische Maßnahmen schon bei leichten Fehlstellungen.
        </span>
        <br />
        <strong>{{ tarif.tarif_leistung_kfo_beg_mgkv }} | KIG 3-5</strong>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- KFO Wartezeit -->
    <v-row>
      <v-col cols="12" md="4">
        <strong>KFO Wartezeit</strong>
      </v-col>
      <v-col cols="12" md="8">
        <strong>{{ tarif.tarif_beschreibung_wz_kfo }}</strong>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Kinder-Zahnarztleistungen -->
    <v-row>
      <v-col cols="12" md="4">
        <strong>Fissurenversiegelung</strong>
      </v-col>
      <v-col cols="12" md="8">
        {{ tarif.tarif_leistung_speziell_fis }}
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Kinder-Prophylaxe -->
    <v-row>
      <v-col cols="12" md="4">
        <strong>Kinder-Prophylaxe</strong>
      </v-col>
      <v-col cols="12" md="8">
        {{ tarif.tarif_leistung_speziell_pzr_k }}
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Funktionstechnische Analyse KFO -->
    <div v-for="(item, index) in treatmentSections" :key="index">
      <v-row
        v-if="tarif[item.description] && tarif[item.description].length > 1"
      >
        <v-col cols="12" md="4">
          <TooltipHelper :toolkey="item.key">
            <template v-slot:toolTipText>
              <a
                ><span class="caption">{{ item.title }}</span></a
              >
            </template>
          </TooltipHelper>
        </v-col>
        <v-col cols="12" md="8">
          {{ tarif[item.description] }}
        </v-col>
        <v-divider :thickness="1"></v-divider>
      </v-row>
    </div>
  </div>
</template>

<script setup>
  import { ref, defineProps } from "vue";
  import TooltipHelper from "@/components/base/TooltipHelper"; // Achten Sie darauf, den korrekten Pfad anzugeben

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
  const treatmentSections = ref([
    {
      key: "invisa",
      title: "Invisalign",
      description: "tarif_leistung_invisalign",
      text: "Herausnehmbare und transparente Alternative zur festen Zahnsspange",
    },
    {
      key: "aufb",
      title: "Aufbiss-Schienen",
      description: "tarif_leistung_aufbiss",
      text: "Meistens ist damit die sog. Knirscherschiene gemeint, dient dem Schutz der Zahnsubstanz bei nächtlichem Knirschen",
    },
    {
      key: "vect",
      title: "VECTOR-Technologie",
      description: "tarif_leistung_vector",
      text: "Unterstützende Behandlungsmethode im Rahmen der Behandlung einer Zahnbetterkrankung mittels Ultraschal",
    },

    {
      key: "fis",
      title: "Fissurenversieglung",
      description: "tarif_leistung_fis",
      text: "Verschließen der Furchen und Grübchen der Zähne mittels dünnflüssigem Kunststoff",
    },
    {
      key: "kpzr",
      title: "Kinder-Prophylaxe",
      description: "tarif_leistung_kpzr",
      text: "Vorbeugende Maßnahmen zur Verhinderung von Krankheiten der Zähne und des Zahnbettes",
    },
    {
      key: "kfo_funk",
      title: "Funktionsanalyse KFO",
      description: "tarif_leistung_kfo_funk",
      text: "Bewährtes Verfahren zur kieferorthopädischen Befunderhebung und Therapiekontrolle",
    },
    {
      key: "mini_brack",
      title: "Mini-Brackets",
      description: "tarif_leistung_mini_brack",
      text: "Benötigen weniger Fläche auf dem Zahn und sind daher leichter zu reinigen",
    },
    {
      key: "kunst_brack",
      title: "Kunststoff-Brackets",
      description: "tarif_leistung_kunst_brack",
      text: "Zahnfarbene Brackets aus Kunststoff haben rein ästhetischen Charakter",
    },
    {
      key: "farbl_bogen",
      title: "farblose Bögen",
      description: "tarif_leistung_farbl_bogen",
      text: "Werden in Verbindung mit zahnfarbenen Kunststoff-Brackets verwendet",
    },
    {
      key: "lingua",
      title: "Lingualtechnik",
      description: "tarif_leistung_lingua",
      text: "Feste Zahnspange auf der Innenseite der Zähne, für Außenstehende nicht sichtbar",
    },
    {
      key: "retainer",
      title: "Retainer",
      description: "tarif_leistung_retainer",
      text: "Dient der Fixierung erfolgreich kieferorthopädisch behandelter Zähne",
    },
  ]);
</script>
