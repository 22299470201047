<template>
  <div style="overflow-wrap: break-word" class="pr-1">
    <span v-if="berechnung">
      <v-row>
        <v-col cols="12">
          <span style="font-size: 12px"
            >Beste Leistung | {{ berechnung.ber_best_emp_preis }} €</span
          >
          <h3>
            {{ berechnung.ber_best_emp }}
          </h3>

          <router-link
            @click="berechnungStore.showPopApp = false"
            :to="`/angebot/kostenlos/${berechnung.ber_beste_leistung_tarif_id}/${berechnung.ber_identity_token}`"
            >Infopaket anfordern</router-link
          >
        </v-col>
      </v-row>
      <v-divider v-if="berechnung.ber_alt_emp != ''" class="my-4"></v-divider>
      <v-row v-if="berechnung.ber_alt_emp != ''">
        <v-col cols="12">
          <span style="font-size: 12px">
            Sehr gute Alternative | {{ berechnung.ber_alt_emp_preis }} €</span
          >
          <h3>
            {{ berechnung.ber_alt_emp }}
          </h3>
          <!-- <router-link
          :to="`/angebot/kostenlos/${berechnung.ber_beste_alternative_tarif_id}/${berechnung.ber_identity_token}`"
        >
          <v-btn
            color="blue"
            rounded="0"
            variant="outlined"
            class="mt-2 button_caps"
            >Infopaket anfordern</v-btn
          ></router-link
        > -->

          <router-link
            @click="berechnungStore.showPopApp = false"
            :to="`/angebot/kostenlos/${berechnung.ber_beste_alternative_tarif_id}/${berechnung.ber_identity_token}`"
            >Infopaket anfordern</router-link
          >
        </v-col>
      </v-row>
      <v-divider
        v-if="guestStore.guest.guest_count_ber > 0"
        class="my-4"
      ></v-divider>
      <v-row v-if="guestStore.guest.guest_count_ber > 0">
        <v-col cols="12">
          <h3>Ihre Berechnungen</h3>
          <ul>
            <li>
              <router-link
                @click="berechnungStore.showPopApp = false"
                :to="`/vergleich/ergebnis/${berechnung.ber_identity_token}`"
                >Ihre <b>neueste</b> Berechnung anzeigen</router-link
              >
            </li>
            <li v-if="guestStore.guest.guest_count_ber > 1">
              <a @click="berechnungStore.setShowPopApp('alleberechnungen')"
                >Ihre {{ guestStore.guest.guest_count_ber }} Berechnungen</a
              >
            </li>
          </ul>
        </v-col>
      </v-row>
    </span>
    <v-row>
      <v-col cols="12"
        ><h3>Rechner starten</h3>
        Vergleichen Sie die leistungsstärksten Zahnzusatz-versicherungen.
        <router-link
          @click="berechnungStore.showPopApp = false"
          :to="`/rechner/${guest.guest_user_id}/starten`"
          ><br />
          <v-btn color="blue" rounded="0" class="mt-2 button_caps"
            >Vergleich starten</v-btn
          ></router-link
        >
        <v-divider :thickness="2" class="my-6"></v-divider>
        <h3>Alle Rechner</h3>
        <ul>
          <li>
            <router-link
              @click="berechnungStore.showPopApp = false"
              :to="`/rechner/${guest.guest_user_id}/starten`"
              >Zahnzusatz</router-link
            >
          </li>
          <li>
            <router-link
              @click="berechnungStore.showPopApp = false"
              :to="`/rechner/${guest.guest_user_id}/brille`"
              >Brillenversicherung</router-link
            >
          </li>
          <li>
            <router-link
              @click="berechnungStore.showPopApp = false"
              :to="`/rechner/${guest.guest_user_id}/heilpraktiker`"
              >Heilpraktiker</router-link
            >
          </li>
          <li>
            <router-link
              @click="berechnungStore.showPopApp = false"
              :to="`/rechner/${guest.guest_user_id}/krankenhaus`"
              >Krankenhaus</router-link
            >
          </li>
        </ul>

        <v-divider :thickness="2" class="my-6"></v-divider>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps, onMounted, ref } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  import { useGuestStore } from "@/stores/guest";
  import BaseCalls from "@/services/BaseCalls";
  const guestStore = useGuestStore();
  const berechnungStore = useBerechnungStore();
  const props = defineProps({
    berechnung: {
      type: Object,
    },
  });
  const guest = ref(false);
  console.log(props.berechnung);
  async function getGuest() {
    let guestSelect = {};
    guestSelect.action = "getGuest";
    try {
      const resp = await BaseCalls.postGuest(guestSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.guest) {
        guest.value = resp.data.guest;
        guestStore.setGuest(resp.data.guest);
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }

  onMounted(() => {
    getGuest();
  });
</script>
