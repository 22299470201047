const baseplugin = {
  install(app) {
    // Funktion zur Aktualisierung des Dokumentkopfs
    app.config.globalProperties.$updateDocumentHead = (
      siteTitle,
      description
    ) => {
      document.title = siteTitle;
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) {
        metaDescription.setAttribute("content", description);
      }
    };

    // Funktion zum Loggen von Fehlern in der Konsole
    app.config.globalProperties.$logError = (error) => {
      console.error("LogError: ", error);
    };

    // Funktion zur Berechnung des Alters basierend auf Geburtsdatum
    app.config.globalProperties.$calcAge = (day, month, year) => {
      const currentDate = new Date();
      let age = currentDate.getFullYear() - year;

      if (
        currentDate.getMonth() < month - 1 ||
        (month - 1 === currentDate.getMonth() && currentDate.getDate() < day)
      ) {
        age--;
      }

      return age;
    };

    app.config.globalProperties.$validateEmail = (email) => {
      var showError = false;
      var textError = "";
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!re.test(String(email).toLowerCase())) {
        textError =
          "Bitte geben Sie eine gültige E-Mail an. Sonst können wir Ihnen Ihre Anfrage nicht bearbeiten.";
        showError = true;
      }

      if (showError) {
        return { isValid: false, error: textError };
      } else {
        return { isValid: true };
      }
    };

    // Weitere Funktionen können hier in ähnlicher Weise hinzugefügt werden
  },
};

export default baseplugin;
