<template>
  <div>
    <v-checkbox
      :model-value="isChecked"
      @update:model-value="handleChange"
      color="green"
    >
      <template v-slot:label> {{ labelData }} </template>
    </v-checkbox>
  </div>
</template>

<script>
  import { ref, watch, defineComponent, toRefs } from "vue";

  export default defineComponent({
    props: {
      labelData: {
        type: String,
        default: "",
      },
      // Das Prop, das entweder eine Zahl 1 oder einen String "1" annehmen kann
      input: {
        type: [Number, String],
        required: true,
      },
    },

    emits: ["update:input"],

    setup(props, { emit }) {
      // Destructuring, um reaktive Referenzen für Props zu erstellen
      const { input } = toRefs(props);

      // Initialisiert isChecked basierend auf dem input Prop
      const isChecked = ref(input.value === 1 || input.value === "1");

      // Watcher, der auf Änderungen von input reagiert
      watch(input, (newValue) => {
        if (newValue === 0 || newValue === "0") {
          isChecked.value = false;
        } else {
          isChecked.value = newValue === 1 || newValue === "1";
        }
      });

      // Funktion, die aufgerufen wird, wenn der Wert der Checkbox geändert wird.
      // Diese Funktion konvertiert den boolean Wert zurück in "1" oder "0" (als String),
      // und emittiert dieses neue Wert nach außen.
      const handleChange = (newValue) => {
        emit("update:input", newValue ? "1" : "0");
      };

      return {
        isChecked,
        handleChange,
      };
    },
  });
</script>
