<template>
  <div>
    <h2 class="mb-2">
      <span class="text-green">Sofort-Leistung:</span> {{ tarif.un_name }}
      {{ tarif.tarif_title }}
    </h2>

    <span v-if="tarif.tarif_sofort == 3">
      Die SDK Tarife ZP1 und ZP9 sind empfehlenswert, wenn schon vor
      Versicherungsabschluss für bis zu drei bereits fehlenden Zähne
      Zahnersatz-Maßnahmen angeraten sind.

      <h3 class="mt-2">Geleistet wird für:</h3>
      Bereits vor Versicherungsabschluss angeraten oder vorgesehene
      Zahnersatz-Maßnahmen. Achtung: Behandlung darf erst nach
      Versicherungsbeginn begonnen werden!

      <h3 class="mt-2">Nicht geleistet wird für:</h3>
      Bereits vor Beginn des Versicherungsschutzes durchgeführte Behandlungen.

      <h3 class="mt-2">Wichtige Tarifinformationen:</h3>
      <ul>
        <li>
          Das erste Versicherungsjahr ist ein sog. “Rumpfjahr” und endet am
          31.12. des Abschlussjahres. Ab dem darauffolgenden 01.01. befindet
          sich der Versicherte bezüglich der anfänglichen Summenbegrenzungen
          bereits im zweiten Versicherungsjahr.
        </li>
      </ul>
    </span>

    <span v-if="tarif.tarif_sofort == 2">
      Der Tarif ZahnPRIVAT Premium der Union Krankenversicherung ist besonders
      empfehlenswert, wenn schon an bis zu drei bereits fehlenden oder zu
      ziehenden Zähnen Implantat-Versorgungen angeraten sind.

      <h3 class="mt-2">Geleistet wird für:</h3>
      Die Implantat-Versorgung von bereits vor Versicherungsabschluss fehlenden
      oder zu ziehenden Zähnen. Achtung: Behandlung darf erst nach
      Versicherungsbeginn begonnen werden!
      <h3 class="mt-2">Nicht geleistet wird für:</h3>
      Bereits vor Beginn des Versicherungsschutzes durchgeführte Behandlungen.
      Die Extraktion an sich gilt dabei allerdings nicht als bereits
      durchgeführte Behandlung, da Kassenleistung.

      <h3 class="mt-2">Wichtige Tarifinformationen:</h3>
      <ol>
        <li>
          Der ZahnPRIVAT Premium versichert bis zu drei vor Abschluss bereits
          fehlende oder zu ziehende Zähne gegen einen Risikozuschlag von 8,60 €
          monatlich mit. Die Kosten für die Implantat-Versorgung sind dafür
          tarifgemäß mitversichert.
        </li>
        <li>
          Der ZahnPRIVAT Premium kann nur abgeschlossen werden, wenn neben der
          bereits angeratenen Lücken-Versorgung keine weiteren Maßnahmen
          angeraten, geplant oder am Laufen sind.
        </li>
        <li>
          Der ZahnPRIVAT Premium kann zwar auch abgeschlossen werden, wenn die
          Implantat-Versorgung bereits begonnen wurde, allerdings leistet der
          Tarif nur für Behandlungen, die nach Beginn des Versicherungsschutzes
          durchgeführt wurden.
        </li>
        <li>
          Die Frage im Antrag des ZahnPRIVAT Premium nach bereits angeratenen
          Maßnahmen ist zwingend zu verneinen, da außer der bereits angeratenen
          Implantat-Versorgung keine weiteren Maßnahmen angeraten sein dürfen.
        </li>
        <li>
          Das erste Versicherungsjahr ist ein sog. “Rumpfjahr” und endet am
          31.12. des Abschlussjahres. Ab dem darauffolgenden 01.01. befindet
          sich der Versicherte bezüglich der anfänglichen Summenbegrenzungen
          bereits im zweiten Versicherungsjahr.
        </li>
        <li>
          Wann mit der Behandlung begonnen wird, ist dem Versicherten
          überlassen. Theoretisch kann mit der Behandlung sogar bis nach Ablauf
          der anfänglichen Summenbegrenzungen gewartet werden.
        </li>
      </ol>
    </span>

    <span v-if="tarif.tarif_sofort == 1">
      Der Baustein ZAHN Sofort kann für monatlich 29,90 Euro zusätzlich zu den
      Haupttarifen ZAHN Smart, Komfort, Prestige und Prestige Plus abgeschlossen
      werden. Die Leistung des ZAHN Sofort entspricht dem jeweils
      abgeschlossenen Haupttarif. Der Baustein hat keine Wartezeit und endet
      automatisch nach 24 Monaten.

      <h3 class="mt-2">Geleistet wird für:</h3>
      Bereits angeratene bzw. bereits begonnene Behandlungen in den Bereichen
      Zahnersatz und Zahnerhalt.
      <h3 class="mt-2">Nicht geleistet wird für:</h3>
      Bereits abgeschlossene bzw. bereits in Rechnung gestellte Behandlungen
      sowie für Kieferorthopädie
      <h3 class="mt-2">Leistungsbegrenzung:</h3>
      Der Tarif leistet mit max. 1.500 € über die gesamte Vertragslaufzeit, max.
      750€ je Kalenderjahr.
      <h3 class="mt-2">Wichtige Tarifinformationen:</h3>
      <ol>
        <li>
          Der ZAHN Sofort kann nur in Verbindung mit einem Haupttarif (ZAHN
          Smart/Komfort/Prestige/Prestige Plus) abgeschlossen werden. Ist der
          Haupttarif auf Basis der Gesundheitsfragen nicht abschließbar, kann
          auch der ZAHN Sofort nicht abgeschlossen werden.
        </li>
        <li>
          Der ZAHN Sofort Baustein kann ohne zusätzliche Gesundheitsfragen
          abgeschlossen werden.
        </li>
        <li>
          Der ZAHN Sofort Baustein hat eine feste Vertragslaufzeit von 24
          Monaten und entfällt danach automatisch. Es bedarf keiner Kündigung.
          Der jeweilige Haupttarif ZAHN Smart, Komfort, Prestige oder Prestige
          Plus läuft einfach weiter.
        </li>

        <li>
          Der ZAHN Sofort leistet wie der Haupttarif für die Bereiche Zahnersatz
          und Zahnerhalt, wenn die entsprechenden Behandlungen bereits vor
          Abschluss der Versicherung angeraten, geplant oder am Laufen waren.
          Der Baustein leistet nicht für vor Versicherungsabschluss
          durchgeführte Prophylaxe Leistungen.
        </li>
      </ol>
    </span>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
