<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        KFO Ø-Erstattung
        <br />
        <span class="caption">(Kieferorthopädie)</span>
      </v-col>
      <v-col cols="12" md="4">
        <strong class="blue--text">
          <a @click="berechnungStore.setShowPop(tarif, 'kinderwert')"
            >{{ tarif.tarif_kwert_1 }} %</a
          >
        </strong>
        |
        <TooltipHelper toolkey="kig1">
          <template v-slot:toolTipText>
            <a><span class="caption">KIG 1-2</span></a>
          </template>
        </TooltipHelper>
        <br />
        <span class="caption">GKV leistet nicht</span>
      </v-col>
      <v-col cols="12" md="4">
        <strong class="blue--text">
          <a @click="berechnungStore.setShowPop(tarif, 'kinderwert')"
            >{{ tarif.tarif_kwert_3 }} %</a
          >
        </strong>
        |
        <TooltipHelper toolkey="kig3">
          <template v-slot:toolTipText>
            <a><span class="caption">KIG 3-5</span></a>
          </template>
        </TooltipHelper>
        <br />
        <span class="caption">GKV leistet teilweise</span>
      </v-col>
    </v-row>
    <v-row v-if="tarif.tarif_id == 276">
      <v-col cols="12" md="12">
        Der Tarif "Kieferorthopädie Sofort" leistet als einziger Tarif für
        bereits angeratene oder sogar laufende Behandlungen bei Kindern und
        Jugendlichen im kieferorthopädischen Bereich. Eine Leistung ist sofort
        nach Versicherungbeginn ohne Wartezeit im Rahmen der anfänglichen
        Summenbegrenzungen möglich. Der Tarif leistet für den KFO-Bereich mit
        50% der Restkosten. Wird der Tarif in den ersten vier
        Versicherungsjahren nicht in Anspruch genommen, erhöht sich die Leistung
        auf 75% der Restkosten. Erbringt die Krankenkasse für die KFO-Behandlung
        keine Vorleistung, ist die Leistung aus dem Tarif auf max. 250,- Euro
        pro Versicherungsjahr begrenzt.
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row>
      <v-col cols="4">
        Tarifliche KFO
        <br />
        <span class="caption">Leistung | Begrenzung</span>
      </v-col>
      <v-col cols="4">
        <strong>{{ tarif.tarif_leistung_kfo_ogkv }} %</strong>
        |
        <strong>{{ tarif.tarif_leistung_kfo_beg_ogkv }}</strong>
        <br />
        <TooltipHelper toolkey="kig1">
          <template v-slot:toolTipText>
            <span class="caption">KIG 1-2</span>
          </template>
        </TooltipHelper>
      </v-col>
      <v-col cols="4">
        <strong>{{ tarif.tarif_leistung_kfo_mgkv }} %</strong>
        |
        <strong>{{ tarif.tarif_leistung_kfo_beg_mgkv }}</strong>
        <br />
        <TooltipHelper toolkey="kig3">
          <template v-slot:toolTipText>
            <span class="caption">KIG 3-5</span>
          </template>
        </TooltipHelper>
      </v-col>
    </v-row>

    <!-- Ende KFO Begrenzungen -->
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- KFO Wartezeiten -->
    <v-row>
      <v-col cols="4">KFO Wartezeiten:</v-col>
      <v-col cols="8">
        <strong>{{ tarif.tarif_beschreibung_wz_kfo }}</strong>
      </v-col>
    </v-row>
    <!-- Ende KFO Wartezeiten -->
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- KinderLog -->
    <v-row>
      <v-col cols="12">
        <a @click="show_kind = !show_kind" style="font-weight: bold">
          Leistet für {{ tarif.tarif_count_kind }}
        </a>
        von 11 Kinderzahnarzt-Leistungen
        <ul v-if="show_kind" class="ml-6">
          <li v-for="item in tarif.kindspezial" :key="item.id">
            <span v-if="item.ja">{{ item.value }}</span>
            <span
              v-if="!item.ja"
              class="text-red"
              style="text-decoration: line-through"
              >{{ item.value }}</span
            >
          </li>
          <li>
            <a @click="berechnungStore.setShowPop(tarif, 'vergleichkinder')">
              Vergleich zu anderen Tarifen
            </a>
          </li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { ref, defineProps } from "vue";
  import TooltipHelper from "@/components/base/TooltipHelper.vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  //   import { useSharedDataStore } from "@/stores/sharedData";
  //   const sharedDataStore = useSharedDataStore();
  const berechnungStore = useBerechnungStore();
  const show_kind = ref(false);
  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
