import { defineStore } from "pinia";

export const useErrorStore = defineStore("errorStore", {
  state: () => ({
    error: null,
    message: "Ein unbekannter  Fehler ist aufgetreten.", // Standardfehlermeldung
  }),
  actions: {
    setError(error, message = "") {
      this.error = error;
      if (message) this.message = message; // Verwende die übergebene Nachricht oder die Standardnachricht
    },

    clearError() {
      this.error = null;
      this.message = "Ein unbekanntere Fehler ist aufgetreten."; // Setze die Nachricht zurück
    },
  },
});
