<template>
  <v-overlay v-model="show" class="justify-center">
    <v-card width="666" class="mt-12 pa-4">
      <v-row>
        <v-col cols="12" md="9"> <h2 class="text-red">Fehler</h2></v-col>
        <v-col cols="12" md="3">
          <v-btn color="primary" @click="clearError()">
            schliessen
          </v-btn></v-col
        >
      </v-row>
      <v-row>
        <v-col cols="12" md="9">
          {{ errorMessage }}
        </v-col>
      </v-row>
    </v-card>
  </v-overlay>
</template>

<script setup>
  import { computed } from "vue";
  import { useErrorStore } from "@/stores/errorStore";

  const errorStore = useErrorStore();

  const show = computed({
    get: () => !!errorStore.error,
    set: (value) => !value && errorStore.clearError(),
  });

  const errorMessage = computed(
    () => errorStore.message || "Ein unbekannter Fehler ist aufgetreten."
  );

  function clearError() {
    errorStore.clearError();
  }
</script>
