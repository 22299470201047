<template>
  <div class="pl-4">
    <h2 class="text-green">
      {{ tarif.un_name }}
    </h2>
    <h3 class="text-green">{{ tarif.tarif_title }}</h3>
    <ul class="mt-2">
      <li>
        Eintrittsalter:
        <strong>{{ berechnung.ber_alter }} Jahre</strong>.
      </li>
      <li>
        Monatlicher Beitrag:
        <strong>{{ tarif.preis_value }} €</strong>.
      </li>
      <li v-if="berechnung.ber_segment < 2">
        Clearment Vertragsschutz:
        <strong class="text-green">kostenlos</strong> für gesamte
        Vertragslaufzeit
      </li>
    </ul>

    <br />
    <br />
    <h3>Ihr Antrag &amp; Infopaket</h3>
    Das kostenlose Informations- und Angebotspaket enthält:
    <ol class="mt-2">
      <li>
        Einen professionell geprüften &amp; vorbereiteten Versicherungsantrag.
      </li>
      <li>
        Eine ausführliche Tarifbeschreibung und wichtige Serviceinformationen.
      </li>
      <li v-if="berechnung.ber_segment < 2">Clearment Zugangsdaten</li>
    </ol>

    <span v-if="berechnung.ber_segment < 2 && tarif.tarif_clearment_vs == 1">
      <br />
      <br />
      <h3>
        Clearment VertragsSchutz
        <sup class="text-green">Tipp</sup>
      </h3>
      Sie erhalten innnerhalb weniger Stunden Ihr Clearment Passwort per E-Mail.
      Sie können sich sofort kostenlos einloggen.
    </span>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
