<template>
  <v-container>
    <h2>Der monatliche Beitrag: {{ tarif.un_name }} {{ tarif.tarif_title }}</h2>
    <span v-if="tarif.tarif_altersrueckstellung == 1">
      Die Beiträge der {{ tarif.un_name }} {{ tarif.tarif_title }} sind
      <b>ohne</b> Altersrückstellungen kalkuliert. Die Beiträge werden
      regelmäßig entsprechend Ihres Alters angepasst.

      <span v-if="preisSpruenge">
        <br /><br />
        <b>Ab diesem Alter wird der Beitrag angepasst:</b><br />
        <span v-for="p in preisSpruenge" :key="p.preis_alter" class="ma-2">
          ab {{ p.preis_alter }} Jahre: {{ p.preis_value }} € <br
        /></span>
      </span>
    </span>
    <span v-if="tarif.tarif_altersrueckstellung == 0">
      Die Beiträge der {{ tarif.un_name }} {{ tarif.tarif_title }} sind
      <b>mit</b> Altersrückstellungen kalkuliert. Dadurch bleiben die Beiträge
      nach Abschluss stabil.
    </span>
    <v-divider :thickness="2" class="my-4"></v-divider>
    <h2>Die Beitragsentwicklung im Vergleich</h2>
    So werden sich die Beiträge in den nächsten 10 Jahren voraussichtlich
    entwickeln. (Ausnahme: außerordentliche Beitragsanpassungen).
    <br />

    <v-table>
      <thead>
        <tr>
          <th>Tarif</th>
          <th>Heute</th>
          <th>nächstes Jahr</th>
          <th>in 5 Jahren</th>
          <th>in 10 Jahren</th>
          <th>Angebot</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="tarif.tarif_id" style="background-color: #f2f2f2">
          <td>
            <strong>
              <router-link v-if="tarif.antraglink" :to="tarif.antraglink">
                <a class="noUnderline">{{ tarif.un_name }}</a>
                <span v-if="tarif.empfehlung_type > 0">
                  <sup class="text-green">Tipp</sup>
                </span>
              </router-link>
            </strong>
            <br />
            {{ tarif.tarif_title }}
          </td>
          <td>{{ tarif.preis_value }} €</td>
          <td>{{ tarif.preis_value_plus_1 }} €</td>
          <td>{{ tarif.preis_value_plus_5 }} €</td>
          <td>{{ tarif.preis_value_plus_10 }} €</td>
          <td>
            <router-link v-if="tarif.antraglink" :to="tarif.antraglink">
              <b><a class="noUnderline">Angebot</a></b>
            </router-link>
          </td>
        </tr>
        <tr
          v-for="item in formattedTarife"
          :key="item.tarif_id"
          style="vertical-align: top"
        >
          <td>
            <strong>
              <router-link v-if="item.antraglink" :to="item.antraglink">
                <a class="noUnderline">{{ item.un_name }}</a>
                <span v-if="item.empfehlung_type > 0">
                  <sup class="text-green">Tipp</sup>
                </span>
              </router-link>
            </strong>
            <br />
            {{ item.tarif_title }}
          </td>
          <td>{{ item.preis_value }} €</td>
          <td>{{ item.preis_value_plus_1 }} €</td>
          <td>{{ item.preis_value_plus_5 }} €</td>
          <td>{{ item.preis_value_plus_10 }} €</td>
          <td>
            <router-link v-if="item.antraglink" :to="item.antraglink">
              <b><a class="noUnderline">Angebot</a></b>
            </router-link>
          </td>
        </tr>
      </tbody>
    </v-table>
  </v-container>
</template>

<script setup>
  import { computed, ref, defineProps, onMounted } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  const props = defineProps({
    alletarife: Array,
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  const preisSpruenge = ref(false);
  const formattedTarife = computed(() => {
    if (props.tarif) {
      return props.alletarife.map((tarif) => ({
        ...tarif,
        antraglink: tarif.antraglink || "#",
      }));
    } else {
      return props.alletarife;
    }
  });
  async function getBeitragsEntwicklung() {
    console.log("getTarif");
    let select = {};
    select.action = "getTarifPreisSpruenge";
    select.tarif_id = props.tarif.tarif_id;

    try {
      const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.preisSpruenge) {
        preisSpruenge.value = resp.data.preisSpruenge;
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }
  onMounted(() => {
    if (props.tarif.tarif_altersrueckstellung == 1) {
      getBeitragsEntwicklung();
    }
  });
</script>

<style scoped>
  .noUnderline {
    text-decoration: none;
  }
</style>
