<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h3>Ihr Ergebnis:</h3>
        <span class="text-blue"
          >{{ berechnung.show_ber_geschlecht }},
          {{ berechnung.show_ber_geburtstag }}</span
        >

        {{ berechnung.showBerechnungName }}en | sortiert nach höchster Leistung
        | Preis-Leistung
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row>
      <v-col cols="6">
        <a @click="showVorgaben = !showVorgaben">Vorgaben anzeigen</a>
      </v-col>
    </v-row>
    <div class="my-2" v-if="showVorgaben">
      <br />
      <b>Ihre Berechnungsvorgaben</b>

      <ul>
        <li
          v-for="(item, index) in berechnung.berlog"
          v-bind:key="index"
          v-html="nl2br(item.value)"
        ></li>
        <li>
          <router-link to="/vergleich/starten"
            >Berechnungsvorgaben anpassen</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
  import { ref, defineProps } from "vue";
  import { useNl2Br } from "@/composable/useNl2Br";
  const { nl2br } = useNl2Br();
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  const showVorgaben = ref(false);
  console.log(props.tarif);
</script>
