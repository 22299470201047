<template>
  <div>
    <span style="font-size: 18px">Mit <b>"Ja"</b> beantworten, wenn</span>
    <br />
    <ul>
      <li>
        Ihr Zahnarzt Ihnen eine Aufbiss-Schiene bzw. Aufbissbehelf angefertigt
        hat
      </li>
      <li>
        Ihr Zahnarzt die Behandlung noch nicht als "<strong>beendet</strong>" in
        Ihrer Patientenakte eingetragen hat
      </li>
      <li>
        <strong class="text-green">Waizmann hilft:</strong> Unser Rechner zeigt
        Ihnen Tarife, die auch für vorhandene Aufbiss-Schienen leisten
      </li>
    </ul>
    <span style="font-size: 18px"
      >Mit <strong class="text-green">"Nein"</strong> beantworten, wenn</span
    >
    <br />
    <ul>
      <li>Sie keine Aufbiss-Schiene verordnet bekommen haben</li>
      <li>
        wenn Ihr Zahnarzt die Behandlung mit der Schiene als abgeschlossen in
        Ihrer Patientenakte vermerkt hat
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "aufbiss-info",
    components: {},
  };
</script>

<style scoped></style>
