<template>
  <v-tooltip bottom open-on-hover>
    <template v-slot:activator="{ props }">
      <span v-bind="props">
        <!-- Benutzerdefinierter Text über Slot -->
        <slot name="toolTipText">{{ toolText }}</slot>
      </span>
    </template>
    <div
      style="background-color: #ffffff !important; max-width: 400px"
      class="pa-4"
    >
      <strong v-if="toolTitle" class="title text-primary">
        {{ toolTitle }}
        <br />
      </strong>
      <span class="text-body-2" v-html="toolText"></span>
    </div>
  </v-tooltip>
</template>

<script setup>
  import { ref, defineProps } from "vue";
  import { useTooltipStore } from "@/stores/tooltipStore";

  // Properties definieren
  const props = defineProps({
    toolkey: String,
  });

  const toolTitle = ref("");
  const toolText = ref("");

  const { getTooltip } = useTooltipStore();

  // Tooltip-Daten bei der Erstellung der Komponente abrufen
  if (props.toolkey) {
    const tooltip = getTooltip(props.toolkey);
    toolTitle.value = tooltip.title;
    toolText.value = tooltip.text;
  }
</script>

<style scoped>
  ::v-deep .v-tooltip.v-overlay > .v-overlay__content {
    background-color: #ffffff !important;
  }
  .custom-tooltip {
    max-width: 300px; /* Maximale Breite des Tooltips */
    background-color: #ffffff !important; /* Hintergrundfarbe des Tooltips */
    color: black; /* Textfarbe, hier als schwarz definiert */
  }
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .text-primary {
    color: #1976d2; /* Beispiel für eine primäre Farbe */
  }
  .text-body-2 {
    font-size: 14px;
    color: black;
  }
</style>
