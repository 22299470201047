<template>
  <div>
    <v-row class="mb-4">
      <v-col cols="12" md="4">
        <strong>Beitrag</strong><br />
        <span class="caption">monatlich</span>
      </v-col>
      <v-col cols="12" md="8">
        <strong>{{ tarif.preis_value }} €</strong>
        <span v-if="tarif.zuschlag_zahn > 0 && berechnung.ber_segment <= 1"
          >&nbsp;|&nbsp;inkl. {{ tarif.zuschlag_zahn }} € Zuschlag für fehlende
          Zähne</span
        >
        <span v-if="tarif.zuschlag_sehhilfe > 0 && berechnung.ber_segment > 1"
          >&nbsp;|&nbsp;inkl. {{ tarif.zuschlag_sehhilfe }} € Zuschlag für
          Sehhilfe</span
        >
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row v-if="tarif.preis_value_plus_5" class="mb-4">
      <v-col cols="12" md="4">
        <strong>Beitrag</strong><br />
        <span class="caption">in 5 Jahren</span>
      </v-col>
      <v-col cols="12" md="8">
        <strong>{{ tarif.preis_value_plus_5 }} €</strong>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row v-if="tarif.preis_value_plus_10" class="mb-4">
      <v-col cols="12" md="4">
        <strong>Beitrag</strong><br />
        <span class="caption">in 10 Jahren</span>
      </v-col>
      <v-col cols="12" md="8">
        <strong>{{ tarif.preis_value_plus_10 }} €</strong>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row class="mb-4" v-if="berechnung.ber_segment <= 1">
      <v-col cols="12" md="4">
        <strong>Clearment Vertragsschutz</strong>
      </v-col>
      <v-col cols="12" md="8">
        <strong class="text-green">Ja, kostenlos</strong>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";

  //   import { useBerechnungStore } from "@/stores/berechnung";
  //   import { useSharedDataStore } from "@/stores/sharedData";
  //   const sharedDataStore = useSharedDataStore();
  //   const berechnungStore = useBerechnungStore();
  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
