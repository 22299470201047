<template>
  <v-container grey lighten-5>
    <v-row>
      <v-col cols="12" md="8">
        <div class="subheading">
          <slot name="title"></slot>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="pl-2">
          <slot name="input"></slot>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
  /* Deine Styles hier. Da v-row und v-col das Layout kontrollieren,
       könntest du Anpassungen für deine spezifischen Bedürfnisse hinzufügen. */
</style>
