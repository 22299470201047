<template>
  <div>
    <h3>Downloads & Avb`s</h3>
    <ul>
      <li v-for="a in avb" v-bind:key="a.vdocs_id">
        <a :href="'/download/' + a.vdoc_dateiname" target="_blank">{{
          a.vdoc_text
        }}</a>
      </li>
    </ul>
  </div>
</template>

<script setup>
  import { ref, defineProps, onMounted } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    showSignup: {
      type: String,
    },
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  const avb = ref(false);
  async function getAbv() {
    let berSelect = {};
    berSelect.showSignup = props.showSignup;
    berSelect.action = "getTarifAvb";
    berSelect.vdoc_wtb_id = props.tarif.tarif_id;
    try {
      const resp = await BaseCalls.postTarif(berSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.avb) {
        avb.value = resp.data.avb;
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }

  console.log(props.tarif);

  onMounted(() => {
    getAbv();
  });
</script>
