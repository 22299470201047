<template>
  <div>
    <div
      class="my-8"
      style="border-left: 4px solid #f2f2f2; border-bottom: 4px solid #f2f2f2"
    >
      <v-row>
        <v-col cols="12" md="1" class="pt-0">
          <RankInfo :tarif="tarif" :berechnung="berechnung" />
        </v-col>
        <v-col cols="12" md="7" class="pt-0">
          <v-row class="pt-0">
            <v-col cols="12" md="7">
              <h3 style="font-size: 28px; font-weight: bold">
                {{ tarif.un_name }}
              </h3>
              {{ tarif.tarif_title }} | Tarif-Nr.: {{ tarif.tarif_id }}
            </v-col>
            <v-col cols="12" md="3">
              <h3 class="text-blue">
                <a
                  @click="
                    berechnungStore.setShowPop(tarif, 'beitragsentwicklung')
                  "
                  style="font-size: 24px; font-weight: bold"
                  >{{ tarif.preis_value }} €</a
                >&nbsp;
                <span style="font-size: 12px; font-weight: normal">mtl.</span>
              </h3>
              <a
                class="caption"
                @click="
                  berechnungStore.setShowPop(tarif, 'beitragsentwicklung')
                "
              >
                Beitragsentwicklung
              </a>
              <span
                v-if="berechnung.ber_segment <= 1 && tarif.zuschlag_zahn > 0"
                style="font-size: 12px"
              >
                <br />
                inkl. {{ tarif.zuschlag_zahn }} € Zuschlag für
                <span v-if="berechnung.ber_luecke == 1">1 fehlenden Zahn</span>
                <span v-if="berechnung.ber_luecke > 1"
                  >{{ berechnung.ber_luecke }} fehlende Zähne</span
                ></span
              >
              <span
                v-if="
                  (berechnung.ber_segment == 3 ||
                    berechnung.ber_segment == 2) &&
                  tarif.zuschlag_sehhilfe > 0
                "
                style="font-size: 12px"
              >
                <br />
                inkl. {{ tarif.zuschlag_sehhilfe }} € Zuschlag für Sehhilfen
              </span>
            </v-col>
            <v-col cols="12" md="2" class="pa-4">
              <router-link v-if="tarif.antraglink" :to="tarif.antraglink">
                <v-icon style="font-size: 36px" :class="berechnung.fontClass"
                  >mdi-cart</v-icon
                ></router-link
              ></v-col
            >
          </v-row>
          <v-divider :thickness="3" class="my-2"></v-divider>
          <v-row v-if="tarif.showBap == 1">
            <v-col cols="12" md="12"
              ><strong class="text-red">Hinweis Beitragserhöhung:</strong>
              {{ tarif.tarif_bap_hinweis }}
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <LueckeLeistung
                v-if="
                  berechnung.ber_segment <= 1 &&
                  berechnung.ber_algo_view != 2 &&
                  berechnung.ber_alter > 17 &&
                  berechnung.ber_luecke > 0 &&
                  berechnung.ber_luecke_vers == 1
                "
                :tarif="tarif"
                :berechnung="berechnung"
              />

              <RumpfJahr
                v-if="tarif.show_begrenzung_vorteil"
                :tarif="tarif"
                :berechnung="berechnung"
              />
              <WechselOption
                v-if="
                  berechnung.ber_wechsel == 1 && tarif.tarif_wechsel_angebot > 0
                "
                :tarif="tarif"
                :berechnung="berechnung"
              />
              <SofortLeistung
                v-if="tarif.sofortversichert"
                :tarif="tarif"
                :berechnung="berechnung"
              />
              <WaizmannWert :tarif="tarif" :berechnung="berechnung" />

              <KfoInfo
                v-if="
                  berechnung.ber_segment <= 1 && berechnung.ber_algo_view == 2
                "
                :tarif="tarif"
                :berechnung="berechnung"
              />
              <ErwachsenInfo
                v-if="
                  berechnung.ber_segment <= 1 && berechnung.ber_algo_view != 2
                "
                :tarif="tarif"
                :berechnung="berechnung"
              />
              <BrillenInfo
                v-if="berechnung.ber_segment == 2"
                :tarif="tarif"
                :berechnung="berechnung"
              />
              <HeilInfo
                v-if="berechnung.ber_segment == 3"
                :tarif="tarif"
                :berechnung="berechnung"
              />
              <KrankInfo
                v-if="berechnung.ber_segment == 4"
                :tarif="tarif"
                :berechnung="berechnung"
              />
              <ClearmentInkl
                v-if="berechnung.ber_segment <= 1"
                :tarif="tarif"
                :berechnung="berechnung"
              />
              <WaizmannEmpfehlung
                v-if="tarif.empfehlung_type > 0"
                :tarif="tarif"
                :berechnung="berechnung"
              />
            </v-col>
          </v-row>
        </v-col>

        <!--Spalte recht-->
        <v-col cols="12" md="4">
          <HighlightsTarif
            class="pa-2"
            style="background-color: #f7f7f7"
            :showMobile="false"
            :tarif="tarif"
            :berechnung="berechnung"
          />
          <ClearmentLsk
            class="mt-4 ml-4"
            v-if="berechnung.ber_segment <= 1 && tarif.tarif_clearment_lsk > 0"
            :tarif="tarif"
          />
          <div v-if="tarif.antraglink" class="px-2 mt-2">
            <h3>
              <router-link :to="tarif.antraglink"
                >jetzt
                <span style="text-decoration: underline"
                  >Antrag & Infopaket</span
                >
                anfordern</router-link
              >
              <span v-if="tarif.empfehlung_type > 0"> </span>
            </h3>
          </div>
        </v-col>
      </v-row>
      <!--oben ende-->
      <v-row class="mb-1">
        <v-col cols="12" md="1"> </v-col>
        <v-col cols="12" md="11">
          <v-row>
            <v-col v v-if="tarif.antraglink">
              <v-btn
                :to="tarif.antraglink"
                elevation="1"
                rounded="0"
                :color="berechnung.fontColorVue"
                class="button_caps"
                style="color: white !important; font-size: 16px"
              >
                Antrag & Infopaket anfordern
                <span v-if="tarif.empfehlung_type > 0" class="white--text"
                  >| kostenlos</span
                >
              </v-btn>
              <span
                style="font-size: 14px"
                v-if="
                  berechnung.ber_segment < 1 &&
                  tarif.empfehlung_type > 0 &&
                  tarif.antraglink
                "
              >
                <br />Fordern Sie Ihr
                <router-link :to="tarif.antraglink">
                  <a class="noUnderline"
                    >Infopaket mit <b>Clearment Zugangsdaten </b></a
                  >
                </router-link>
                an.
              </span>
            </v-col>
            <v-col>
              <v-btn
                @click="berechnungStore.setShowPop(tarif, 'dreivergleich')"
                elevation="1"
                rounded="0"
                color="bg-grey-lighten-2"
                class="button_caps"
                style="font-size: 16px"
                >3 Tarife vergleichen
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                @click="berechnungStore.setShowPop(tarif, 'tarifbeschreibung')"
                elevation="1"
                rounded="0"
                color="bg-grey-lighten-2"
                class="button_caps"
                style="font-size: 16px"
                >Tarifbeschreibung
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>

  <!-- | {{ tarif.tarif_id }} |
  {{ tarif.empfehlung_type }} -->
</template>

<script setup>
  import { defineProps } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  import ClearmentLsk from "@/components/tarif/mini/ClearmentLsk";
  import ClearmentInkl from "@/components/tarif/mini/ClearmentInkl";
  import HighlightsTarif from "@/components/tarif/mini/HighlightsTarif";
  import WaizmannEmpfehlung from "@/components/tarif/mini/WaizmannEmpfehlung";
  import KfoInfo from "@/components/tarif/mini/zahn/KfoInfo";
  import ErwachsenInfo from "@/components/tarif/mini/zahn/ErwachsenInfo";
  import BrillenInfo from "@/components/tarif/mini/brille/BrillenInfo";
  import HeilInfo from "@/components/tarif/mini/heil/HeilInfo";
  import KrankInfo from "@/components/tarif/mini/krank/KrankInfo";
  import RumpfJahr from "@/components/tarif/mini/zahn/RumpfJahr";
  import WechselOption from "@/components/tarif/mini/zahn/WechselOption";
  import SofortLeistung from "@/components/tarif/mini/zahn/SofortLeistung";
  import LueckeLeistung from "@/components/tarif/mini/zahn/LueckeLeistung";
  import RankInfo from "@/components/tarif/mini/RankInfo";
  import WaizmannWert from "@/components/tarif/mini/WaizmannWert";
  const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });

  console.log(props.tarif);
</script>
