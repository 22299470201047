<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">{{
        marketingDataStore.marketingData.buttonStep1Claim
      }}</v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  import { useMarketingDataStore } from "@/stores/marketingData";
  const marketingDataStore = useMarketingDataStore();
  //   import { useBerechnungStore } from "@/stores/berechnung";
  //   import { useSharedDataStore } from "@/stores/sharedData";
  //   const sharedDataStore = useSharedDataStore();
  //   const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
