<template>
  <div>
    <v-card style="min-height: 800px">
      <v-card-title v-show="!sharedDataStore.isMobile()" class="headline">
        Vergleichen Sie 3 Tarife miteinander
      </v-card-title>

      <v-card-text>
        <v-alert
          v-if="sharedDataStore.isMobile()"
          border="top"
          colored-border
          type="info"
          elevation="2"
        >
          Drehen Sie bitte Ihr Handy um diesen Vergleich vollständig nutzen zu
          können.
        </v-alert>

        <v-table class="my-table">
          <thead>
            <tr>
              <th style="font-size: 14px">Tarif</th>
              <th style="width: 300px; font-size: 14px" class="blue--text">
                {{ tarif1.un_name }} {{ tarif1.tarif_title }}
              </th>
              <th style="width: 300px">
                <select
                  class="blue--text"
                  style="padding-left: 4px; font-size: 14px"
                  @change="pushTarifToVergleich($event, 2)"
                >
                  <option value selected>Tarif 2 wählen</option>
                  <option
                    style="padding-left: 10px"
                    v-for="tarif2 in alletarifeFiltered"
                    :value="tarif2.tarif_id"
                    :key="tarif2.tarif_id"
                  >
                    {{ tarif2.un_name }} {{ tarif2.tarif_title }}
                  </option>
                </select>
              </th>
              <th style="width: 300px">
                <select
                  class="blue--text"
                  style="padding-left: 4px; font-size: 14px"
                  @change="pushTarifToVergleich($event, 3)"
                >
                  <option value selected>Tarif 3 wählen</option>
                  <option
                    style="padding-left: 10px"
                    v-for="tarif3 in alletarifeFiltered"
                    :value="tarif3.tarif_id"
                    :key="tarif3.tarif_id"
                  >
                    {{ tarif3.un_name }} {{ tarif3.tarif_title }}
                  </option>
                </select>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Monatlicher Beitrag</td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                <template v-if="tarif">
                  <strong>{{ tarif.preis_value }} €</strong>

                  <span v-if="tarif.empfehlung_type == 3"
                    >| Empfehlung "beste Leistung"</span
                  >
                  <span
                    v-if="
                      tarif.empfehlung_type == 1 || tarif.empfehlung_type == 2
                    "
                    >| Alternativ-Empfehlung
                  </span>

                  <span v-if="tarif.zuschlag_sehhilfen > 0"
                    ><br />inkl.
                    {{ tarif.zuschlag_sehhilfen }}
                    Zuschlag für Sehhilfen</span
                  >
                </template>
              </td>
            </tr>
            <tr>
              <td>
                <strong class="pb-0 mb-0">Infopaket</strong>
                <br />
                <span class="caption m">kostenlos</span>
              </td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                <template v-if="tarif">
                  <v-btn
                    v-if="tarif.antraglink"
                    :href="tarif.antraglink"
                    :color="berechnung.fontColorVue"
                    style="color: white !important"
                    class="button_caps mt-1"
                  >
                    Antrag & Infopaket
                    <sup v-if="tarif.empfehlung_type > 0" class="white--text"
                      >Tipp</sup
                    >
                  </v-btn>
                </template>
              </td>
            </tr>
            <tr v-for="feature in tarifFeatures" :key="feature.id">
              <td>
                <strong>{{ feature.title }}</strong>
              </td>
              <td
                v-for="tarif in tarifs"
                :key="tarif.tarif_id + '-' + feature.id"
              >
                <!-- Prüfen, ob ein spezieller Formatierungsbedarf besteht -->
                {{ formatFeatureValue(tarif[feature.value]) }}
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
  import { ref, onMounted, watch, reactive, computed, defineProps } from "vue";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  // Props declaration with defineProps if needed in <script setup>
  const props = defineProps(["tarif1", "alletarife", "berechnung"]);
  console.log(props.tarif1.tarif_id);
  // State and reactivity
  //   const showSofortPop1 = ref(false);
  //   const showSofortPop2 = ref(false);
  //   const showSofortPop3 = ref(false);
  //   const valueSelected = ref(0);
  //   const tarifNrSelected = ref(0);
  const alletarifeFiltered = ref([]);
  // const showTable = ref(false);
  const showColspan = ref(2);

  const state = reactive({
    select: {
      tarif_id: props.tarif1.tarif_id,
      action: "beitragsentwicklung",
      berechnung: props.berechnung,
      tarif: props.tarif1,
    },
    tarif2: null, // Corrected to 'null'
    tarif3: null, // Corrected to 'null'
  });

  function formatFeatureValue(value) {
    if (value === 0) {
      return "Nein.";
    } else if (value === 1) {
      return "Ja.";
    } else {
      return value;
    }
  }
  const tarifFeatures = ref([
    { id: 1, title: "KH-WaizmannWert", value: "kh_ww" },
    { id: 2, title: "Wartezeit", value: "kh_wz" },
    { id: 3, title: "1/2-Bett-Zimmer", value: "kh_bett" },
    { id: 4, title: "Freie Krankenhauswahl", value: "kh_fkhwahl" },
    {
      id: 5,
      title: "Tagegeld bei Verzicht auf 1/2-Bett-Zimmer",
      value: "kh_tgverzbett",
    },
    {
      id: 6,
      title: "Tagegeld bei Verzicht auf Chefarztbehandlung",
      value: "kh_tgverzca",
    },
    { id: 7, title: "Leistung bei ambulanten OPs", value: "kh_ambops" },
    {
      id: 8,
      title: "Leistung bei Vor- und Nachbehandlungen",
      value: "kh_vornachbeh",
    },
    {
      id: 9,
      title: "Leistung bis zu einem bestimmten Höchstsatz",
      value: "kh_hoechstsatz",
    },
    { id: 10, title: "Leistung bei Kur oder Reha", value: "kh_kurreha" },
    { id: 11, title: "Leistung in Privatklinik", value: "kh_privatklinik" },
    { id: 12, title: "Leistung in Europa", value: "kh_europa" },
    { id: 13, title: "Leistung weltweit", value: "kh_welt" },
    {
      id: 14,
      title: "Leistung für Mehrkosten bei Krankentransport",
      value: "kh_ktransport",
    },
    {
      id: 15,
      title: "Leistung für Krankenrücktransport aus Ausland",
      value: "kh_ktransport_ausland",
    },
    { id: 16, title: "Mitteilungsfrist", value: "kh_frist" },
    { id: 17, title: "Karte zur Anmeldung / Abrechnung", value: "kh_karte" },
  ]);

  const tarifs = computed(() => {
    let tarifsArray = [props.tarif1]; // Start with tarif1 always present
    if (state.tarif2 !== null) {
      tarifsArray.push(state.tarif2); // Add tarif3 only if tarif2 is present and tarif3 itself is not null
    }
    if (state.tarif3 !== null) {
      tarifsArray.push(state.tarif3); // Add tarif3 only if tarif2 is present and tarif3 itself is not null
    }

    return tarifsArray;
  });

  // Equivalent of the created lifecycle hook
  onMounted(() => {
    filterAlle();
  });

  // Methods
  function filterAlle() {
    alletarifeFiltered.value = props.alletarife.filter(
      (x) => x.tarif_id !== parseInt(props.tarif1.tarif_id)
    );
  }

  function pushTarifToVergleich(event, tarif_nr) {
    if (tarif_nr === 2) {
      const treffer2 = props.alletarife.filter(
        (x) => parseInt(x.tarif_id) === parseInt(event.target.value)
      );
      state.tarif2 = treffer2[0];
    } else if (tarif_nr === 3) {
      const treffer3 = props.alletarife.filter(
        (x) => parseInt(x.tarif_id) === parseInt(event.target.value)
      );
      state.tarif3 = treffer3[0];
    }
  }
  watch(
    () => tarifs.value.length, // This is the source reactive property to watch
    (newLength) => {
      showColspan.value = newLength + 1;
    },
    {
      immediate: true, // This option will trigger the handler immediately with the current value
    }
  );
</script>

<style>
  .my-table td {
    vertical-align: top;
  }
</style>
