<template>
  <div>
    <v-row>
      <v-col cols="12" md="9">
        <h3>
          <strong class="text-green">Kundenschutz:</strong>
          Sie gehen kein Risiko oder Verpflichtungen ein!
        </h3>
        <ul>
          <li>
            mit dem Absenden dieses Formulars gehen Sie
            <strong>keinerlei</strong> rechtliche Verpflichtungen ein
          </li>
          <li>
            Sie werden von uns
            <strong>generell nicht zu Werbezwecken angerufen</strong>. Ausnahme:
            Falls im Zuge der Antragsabwicklung ausnahmsweise etwas gekl&auml;rt
            werden mu&szlig;.
          </li>
          <li>
            Ihre Daten werden
            <strong>garantiert niemals an Dritte zu Werbezwecken</strong>
            weitergegeben.
          </li>
        </ul></v-col
      >
      <v-col cols="12" md="3"> </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
