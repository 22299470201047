<template>
  <div>
    <v-row>
      <v-col cols="12" md="12"
        ><h2>Familienversicherung - Versicherung mehrerer Personen</h2>

        Im Gegensatz zur gesetzlichen Krankenversicherung gibt es bei der
        privaten Krankenversicherung - also auch bei der Zahnzusatzversicherung
        - grundsätzlich keine Familienversicherung. Daher muss jedes
        Familienmitglied einzeln versichert werden. Nachlässe für die
        Versicherung mehrerer Familienmitglieder im gleichen Tarif oder beim
        gleichen Versicherer gibt es ebenfalls nicht. Jede zu versichernde
        Person bedingt für den Versicherer ein eigenes Risiko, das separat
        versichert wird und daher jeweils einen eigenen Beitrag generiert.
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
