<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">Sehhilfen</v-col>
      <v-col cols="12" md="9"
        ><strong> {{ tarif.bri_sehhilfe_zahl }} %</strong>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">Max. Erstattung:</v-col>
      <v-col cols="12" md="9">
        <strong>{{ tarif.bri_erstattung_max_eur }}</strong> €
        {{ tarif.bri_erstattung_max_zeit }}</v-col
      >
    </v-row>
    <v-row>
      <v-col cols="12" md="3">Anfängliche Begrenzungen:</v-col>
      <v-col cols="12" md="9">
        {{ tarif.bri_begrenzung_zusatz_erste_jahre }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">Wartezeiten:</v-col>
      <v-col cols="12" md="9">
        <span v-if="tarif.bri_wartezeit">{{ tarif.bri_wartezeit }}</span>
        <span v-if="!tarif.bri_wartezeit">keine Wartezeit</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">Zusätzliche Leistungen:</v-col>
      <v-col cols="12" md="9">
        <span v-if="tarif.bri_count_umfang == 0"
          >keine zusätzlichen Leistungen</span
        >
        <span v-if="tarif.bri_count_umfang == 1">1 zusätzliche Leistung</span>
        <span v-if="tarif.bri_count_umfang > 1"
          >{{ tarif.bri_count_umfang }} zusätzliche Leistungen</span
        >
        <span v-if="tarif.bri_count_umfang > 0">
          <a @click="show_umfang = !show_umfang">&nbsp;...anzeigen...</a>
        </span>
        <span v-if="show_umfang">
          <br />
          <br />
          <strong>Zusätzliche Leistungen</strong>
          <br />
          <ul class="body-2">
            <li v-for="item in tarif.bri_umfang" :key="item.id">
              <span>
                <span
                  v-if="item.no_value"
                  class="red--text"
                  v-html="item.leistung"
                ></span>
                <span v-if="!item.no_value" v-html="item.leistung"></span>

                <span v-if="item.details">
                  ...
                  <a @click="item.show_detail = !item.show_detail">mehr...</a>
                  <span v-if="item.show_detail" class="pa-8">
                    <br />
                    {{ item.details }}
                  </span>
                </span>
              </span>
            </li>
          </ul>
        </span>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
  </div>
</template>

<script setup>
  import { ref, defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  const show_umfang = ref(false);
  console.log(props.tarif);
</script>
