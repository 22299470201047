<template>
  <div>
    <v-divider class="my-2" thickness="2"></v-divider>
    <h2 v-if="antragfragen && tarif">
      Antragsfragen: {{ tarif.un_name }} {{ tarif.tarif_title }}
    </h2>
    <!-- <ul>
      <li v-for="a in antragfragen" :key="a.antrag_id">
        {{ a.antrag_id }} | {{ a.antrag_input_default }} |
        {{ a.showFrageError }} | {{ a.resetInputValue }}
      </li>
    </ul> -->
    <div class="my-4" v-if="!antragfragen && tarif">
      <h3>
        <span class="text-green">Hinweis:</span>
      </h3>
      Die {{ tarif.un_name }} {{ tarif.tarif_title }} verfügt über eine
      angenehme Ausnahme. Er hat
      <b class="text-green">keine Gesundheitsfragen</b>. Sie können direkt mit
      Schritt 2 von 2 fortfahren.
    </div>
    <v-divider class="my-2" thickness="2"></v-divider>
    <v-form ref="antragform">
      <div
        v-for="(frage, index) in antragfragen"
        :key="frage.antrag_id"
        :index="index"
      >
        <!-- {{ frage.antrag_id }} | {{ frage.antrag_art }} -->
        <!-- jA/nein feld -->
        <!-- {{ frage }} -->
        <v-row v-if="error_frage[frage.antrag_id]">
          <v-col cols="12" md="12">
            <h3 class="subtitle red--text">
              {{ error_frage[frage.antrag_id] }} | Fehler-Code:
              {{ error_frage_nr[frage.antrag_id] }}
            </h3>
          </v-col>
        </v-row>

        <div v-if="frage.antrag_art == 1">
          <v-row class="flex-column flex-sm-row">
            <v-col cols="12" md="9">
              <span v-html="frage.antrag_text" style="font-size: 16px"></span>
              <a
                v-if="frage.showFrageInfoExist != ''"
                @click="frage.show_antrag_info = !frage.show_antrag_info"
                >&nbsp;Erklärung anzeigen...</a
              >
              <span v-if="frage.disabled" class="caption text-green">
                <br />Diese Antwort basiert auf Ihrer letzten Berechnung
                <!--  |
                            <router-link to="/vergleich/starten">
                              <a>ändern</a>
                            </router-link> -->
              </span>
              <v-alert
                class="my-4"
                border="bottom"
                colored-border
                color="blue"
                dismissible
                v-if="
                  frage.showFrageInfoExist &&
                  (frage.show_antrag_info ||
                    (frage.antrag_desc_show_wenn_ja == 1 &&
                      frage.antrag_input_default == 'ja') ||
                    frage.antrag_desc_show_wenn_ja == 2)
                "
                >{{ frage.antrag_desc }}</v-alert
              >
            </v-col>
            <v-col cols="12" md="3" class="pt-2">
              <span v-if="!frage.disabled">
                <v-radio-group
                  class="ma-0"
                  v-model="frage.antrag_input_default"
                  v-on:change="
                    resetUnterfrage(
                      frage.antrag_vue_index_unterfrage,
                      frage.antrag_input_default,
                      frage.antrag_oberfrage
                    )
                  "
                  :rules="[checkFrageJaNein(frage)]"
                >
                  <v-row style="max-width: 300px">
                    <v-col cols="6">
                      <v-radio key="1" label="Ja" value="ja"></v-radio>
                    </v-col>
                    <v-col cols="6">
                      <v-radio key="0" label="Nein" value="nein"></v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </span>

              <span v-if="frage.disabled">
                <v-radio-group
                  disabled
                  class="ma-0"
                  v-model="frage.antrag_input_default"
                >
                  <v-row style="max-width: 300px">
                    <v-col cols="6">
                      <v-radio key="1" label="Ja" value="ja"></v-radio>
                    </v-col>
                    <v-col cols="6">
                      <v-radio key="0" label="Nein" value="nein"></v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </span>
            </v-col>
          </v-row>
        </div>
        <!-- Frage 2 -->
        <div
          v-if="
            frage.antrag_art == 3 &&
            frage.antrag_oberfrage > 0 &&
            frage.antrag_vue_index_oberfrage < 99 &&
            antragfragen[frage.antrag_vue_index_oberfrage]
              .antrag_input_default == 'ja'
          "
        >
          <v-row>
            <v-col cols="12" md="8" xs="12">
              <span v-html="frage.antrag_text" style="font-size: 16px"></span>
              <a
                v-if="frage.showFrageInfoExist"
                v-on:click="frage.show_antrag_info = !frage.show_antrag_info"
                >Erklärung anzeigen...</a
              >
              <v-alert
                border="bottom"
                colored-border
                color="blue"
                dismissible
                v-if="
                  frage.showFrageInfoExist &&
                  (frage.show_antrag_info ||
                    (frage.antrag_desc_show_wenn_ja == 1 &&
                      frage.antrag_input_default == 'ja') ||
                    frage.antrag_desc_show_wenn_ja == 2)
                "
                >{{ frage.antrag_desc }}</v-alert
              >
            </v-col>
            <v-col cols="12" md="4" xs="12">
              <v-textarea
                :error-messages="errorMessageTextDefault"
                v-model="frage.antrag_input_default"
                v-on:change="
                  resetOberfrageText(
                    frage.antrag_vue_index_oberfrage,
                    frage.antrag_input_default
                  )
                "
                :rules="[
                  checkUnterFrageTextFeld(
                    frage,
                    antragfragen[frage.antrag_vue_index_oberfrage]
                      .antrag_input_default
                  ),
                ]"
                label="Ihre Antwort"
                type="text"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-divider :thickness="4" class="my-4" color="blue"></v-divider>
        </div>
        <!-- frage -->
        <div v-if="frage.antrag_art == 2 && frage.antrag_oberfrage == 0">
          <v-row class="flex-column flex-sm-row">
            <v-col cols="9">
              <span v-html="frage.antrag_text" style="font-size: 16px"></span>
              <a
                v-if="frage.showFrageInfoExist"
                v-on:click="frage.show_antrag_info = !frage.show_antrag_info"
                >Erklärung anzeigen...</a
              >
              <span v-if="frage.disabled" class="caption text-green">
                Diese Antwort basiert auf Ihrer letzten Berechnung
                <!-- <router-link to="/vergleich/starten">
                              <a>ändern</a>
                            </router-link> -->
                <br />
              </span>
              <v-alert
                border="bottom"
                colored-border
                color="blue"
                dismissible
                v-if="
                  frage.showFrageInfoExist &&
                  (frage.show_antrag_info ||
                    (frage.antrag_desc_show_wenn_ja == 1 &&
                      frage.antrag_input_default == 'ja') ||
                    frage.antrag_desc_show_wenn_ja == 2)
                "
                >{{ frage.antrag_desc }}</v-alert
              >
            </v-col>
            <v-col cols="3" style="max-width: 300px">
              <span v-if="!frage.disabled">
                <v-text-field
                  v-model="frage.antrag_input_default"
                  type="number"
                  :rules="[checkFrageZahlFeld(frage)]"
                ></v-text-field>
              </span>
              <span v-if="frage.disabled">
                <v-text-field
                  disabled
                  v-model="frage.antrag_input_default"
                  type="number"
                ></v-text-field>
              </span>
            </v-col>
          </v-row>
          <v-divider :thickness="4" class="my-4" color="blue"></v-divider>
        </div>

        <div
          class="pt-4 pl-12"
          v-if="
            frage.antrag_art == 2 &&
            frage.antrag_oberfrage > 0 &&
            frage.antrag_vue_index_oberfrage < 99 &&
            antragfragen[frage.antrag_vue_index_oberfrage]
              .antrag_input_default == 'ja'
          "
        >
          <v-row class="flex-column flex-sm-row">
            <v-col cols="9">
              <span v-html="frage.antrag_text" style="font-size: 16px"></span>
              <span v-if="frage.disabled" class="caption text-green">
                <br />Diese Antwort basiert auf Ihrer letzten Berechnung
                <!--  |
                            <router-link to="/vergleich/starten">
                              <a>ändern</a>
                            </router-link> -->
              </span>
              <v-alert
                border="bottom"
                colored-border
                color="blue"
                dismissible
                v-if="
                  frage.showFrageInfoExist &&
                  (frage.show_antrag_info ||
                    (frage.antrag_desc_show_wenn_ja == 1 &&
                      frage.antrag_input_default == 'ja') ||
                    frage.antrag_desc_show_wenn_ja == 2)
                "
                >{{ frage.antrag_desc }}</v-alert
              >
            </v-col>
            <v-col cols="3" style="max-width: 300px">
              <span v-if="frage.disabled">
                <v-text-field
                  disabled
                  v-model="frage.antrag_input_default"
                  label="Anzahl"
                  type="number"
                ></v-text-field>
              </span>
              <span v-if="!frage.disabled">
                <v-text-field
                  v-model="frage.antrag_input_default"
                  v-on:change="
                    resetOberfrageZahl(
                      frage,
                      antragfragen[frage.antrag_vue_index_oberfrage]
                        .antrag_input_default,
                      frage.antrag_vue_index_oberfrage
                    )
                  "
                  :rules="[
                    checkUnterFrageZahlFeld(
                      frage,
                      antragfragen[frage.antrag_vue_index_oberfrage]
                        .antrag_input_default,
                      frage.antrag_vue_index_oberfrage
                    ),
                  ]"
                  label="Anzahl"
                  type="number"
                ></v-text-field>
              </span>
            </v-col>
          </v-row>
          <v-divider :thickness="4" class="my-4" color="blue"></v-divider>
        </div>

        <div v-if="frage.antrag_art == 3 && frage.antrag_oberfrage == 0">
          <v-row>
            <v-col cols="8">
              <span style="font-size: 16px">{{ frage.antrag_text }}</span>
              <a
                v-if="frage.showFrageInfoExist"
                v-on:click="frage.show_antrag_info = !frage.show_antrag_info"
                >Erklärung anzeigen...</a
              >
              <v-alert
                border="bottom"
                colored-border
                color="blue"
                dismissible
                v-if="
                  frage.showFrageInfoExist &&
                  (frage.show_antrag_info ||
                    (frage.antrag_desc_show_wenn_ja == 1 &&
                      frage.antrag_input_default == 'ja') ||
                    frage.antrag_desc_show_wenn_ja == 2)
                "
                >{{ frage.antrag_desc }}</v-alert
              >
              <v-textarea
                :error-messages="errorMessageTextDefault"
                v-model="frage.antrag_input_default"
                :rules="[checkFrageTextFeld(frage)]"
                label="Ihre Antwort"
                type="text"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-divider :thickness="4" class="my-4" color="blue"></v-divider>
        </div>
        <!-- Frage 2 -->
      </div>
    </v-form>
  </div>
</template>

<script setup>
  import { ref, watch, defineProps, onMounted, defineEmits } from "vue";

  import BaseCalls from "@/services/BaseCalls";
  import { useLoaderStore } from "@/stores/loaderStore";
  import { useRoute } from "vue-router";
  import { useErrorStore } from "@/stores/errorStore";
  const errorStore = useErrorStore();
  const loaderStore = useLoaderStore();
  const route = useRoute();
  const emit = defineEmits([
    "updatedTarif",
    "updateAntrag",
    "updateBerechnung",
  ]);

  const props = defineProps({
    token: {
      type: String,
    },
    tarif_id: {
      type: String,
    },
  });

  const tarif = ref(false);

  const antragfragen = ref(false);
  const antragfragen_count = ref(0);
  const berechnung = ref(false);
  const errorMessageTextDefault = ref(false);
  const error_frage = ref([]);
  const error_frage_nr = ref([false]);

  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }

  const setShowFrageError = (antrag_id, showError) => {
    const frage = antragfragen.value.find((f) => f.antrag_id === antrag_id);
    if (frage) {
      frage.showFrageError = showError;
    }
  };
  //import { reactive, ref } from "vue";
  function resetOberfrageText(oberfrage_index, value) {
    // Prüft, ob der Wert falsy ist (0, '', null, undefined) und ob der Index gültig ist

    if (
      (parseInt(value) === 0 ||
        value === null ||
        value === undefined ||
        value === "") &&
      oberfrage_index < 99
    ) {
      antragfragen.value[oberfrage_index].antrag_input_default = "nein";

      console.log(
        "change oberfragefrage back to nein: " +
          oberfrage_index +
          " weil " +
          value
      );
    }
  }

  function resetUnterfrage(subQuestionIndex, value, oberfrage) {
    if (oberfrage == 0) {
      console.log("reset UNterfrage");
      if (value === "nein" && subQuestionIndex < 99) {
        const question = antragfragen.value[subQuestionIndex];
        console.log(
          "resetUnterfrageId: " + antragfragen.value[subQuestionIndex].antrag_id
        );

        if (typeof question.antrag_input_default === "number") {
          question.antrag_input_default = 0;
          console.log(
            `Reset number field for sub-question at index ${subQuestionIndex}`
          );
        } else {
          setShowFrageError(
            antragfragen.value[subQuestionIndex].antrag_id,
            false
          );
          console.log(
            "changeBackTo: " +
              antragfragen.value[subQuestionIndex].resetInputValue
          );
          question.antrag_input_default =
            antragfragen.value[subQuestionIndex].resetInputValue;

          console.log(
            `Reset text field for sub-question at index ${subQuestionIndex}`
          );
        }
      }
    }
  }
  function resetOberfrageZahl(frage, valueOberfrage, indexOberfrage) {
    const inputNumber = parseInt(frage.antrag_input_default, 10);

    if (valueOberfrage === "ja") {
      if (inputNumber === 0) {
        console.log("Obefrage wieder auf nein stellen: " + indexOberfrage);
        antragfragen.value[indexOberfrage].antrag_input_default = "nein";
        //return "Bitte beantworten Sie diese Frage.";
      } else if (isNaN(inputNumber)) {
        antragfragen.value[indexOberfrage].antrag_input_default = "nein";
      }
    }
  }
  function checkUnterFrageZahlFeld(frage, valueOberfrage) {
    const inputNumber = parseInt(frage.antrag_input_default, 10);
    const checkNumber = parseInt(frage.antrag_input_max, 10);
    console.log(
      "checkUnterFrageZahlFeld - Input: " + inputNumber + "Max:  " + checkNumber
    );
    // console.log("antragsfragen " + antragfragen.value);
    if (valueOberfrage === "ja") {
      if (inputNumber < 0) {
        setShowFrageError(frage.antrag_id, true);
        return "Bitte beantworten Sie diese Frage mit einer Zahl > 0.";
      }
      if (inputNumber == 0) {
        setShowFrageError(frage.antrag_id, true);
        return "Bitte beantworten Sie diese Frage mit einer Zahl > 0.";
      }
      if (isNaN(inputNumber)) {
        setShowFrageError(frage.antrag_id, true);
        return "Bitte beantworten Sie diese Frage mit einer Zahl > 0.";
      }
      if (checkNumber > 0 && inputNumber > checkNumber) {
        setShowFrageError(frage.antrag_id, true);
        return `Nur bis maximal ${checkNumber} abschließbar.`;
      }
    }
    setShowFrageError(frage.antrag_id, false);
    return true;
  }

  function checkFrageZahlFeld(frage) {
    const inputNumber = parseInt(frage.antrag_input_default, 10);
    const checkNumber = parseInt(frage.antrag_input_max, 10);

    if (checkNumber > 0 && inputNumber > checkNumber) {
      setShowFrageError(frage.antrag_id, true);
      return `Nur bis maximal ${checkNumber} abschließbar.`;
    }
    setShowFrageError(frage.antrag_id, false);
    return true;
  }

  function checkUnterFrageTextFeld(frage, valueOberfrage) {
    console.log("valueOberfrage " + valueOberfrage);
    console.log("checkUnterFrageTextFeld " + frage.antrag_input_default);
    if (valueOberfrage == "ja" && frage.antrag_input_default === "") {
      console.log(
        "checkUnterFrageTextFeld kein Unterfeld " + frage.antrag_input_default
      );
      setShowFrageError(frage.antrag_id, true);
      return "Bitte beantworten Sie diese Frage.";
    }
    setShowFrageError(frage.antrag_id, false);
    return true;
  }
  function checkFrageTextFeld(frage) {
    console.log("checkFrageTextFeld " + frage.antrag_input_default);
    if (
      frage.antrag_text_must == 1 &&
      (frage.antrag_input_default === "" || frage.antrag_input_default == null)
    ) {
      setShowFrageError(frage.antrag_id, true);
      errorMessageTextDefault.value = "Bitte beantworten Sie diese Frage.";
      return "Bitte beantworten Sie diese Frage.";
    }
    errorMessageTextDefault.value = false;
    setShowFrageError(frage.antrag_id, false);
    return true;
  }
  function checkFrageJaNein(frage) {
    if (frage.antrag_input_default === "") {
      setShowFrageError(frage.antrag_id, true);
      return "Bitte beantworten Sie diese Frage.";
    }

    if (
      frage.antrag_input_default === "ja" &&
      frage.antrag_input_ja === "nein"
    ) {
      setShowFrageError(frage.antrag_id, true);
      return "Tarif nur abschließbar, wenn Sie diese Frage mit 'Nein' beantworten können.";
    }

    if (
      frage.antrag_input_default === "nein" &&
      frage.antrag_input_ja === "ja"
    ) {
      setShowFrageError(frage.antrag_id, true);
      return "Tarif nur abschließbar, wenn Sie diese Frage mit 'Ja' beantworten können.";
    }
    setShowFrageError(frage.antrag_id, false);
    return true;
  }

  async function getVergleich() {
    setLoading(true);
    let berSelect = {};
    berSelect.action = "getTarifAntragBerechnung";
    berSelect.ber_identity_token = props.token;
    berSelect.tarif_id = props.tarif_id;
    berSelect.showPreisJahrePlus = 1;
    console.log("getVergleich " + berSelect.tarif_id);
    try {
      const resp = await BaseCalls.postTarif(berSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.berechnung) {
        tarif.value = resp.data.tarif;
        berechnung.value = resp.data.berechnung;
        antragfragen_count.value = resp.data.antragfragen_count;
        antragfragen.value = resp.data.antragfragen;
        emit("updatedTarif", resp.data.tarif);
        emit("updateBerechnung", resp.data.berechnung);
        setLoading(false);
      } else {
        setLoading(false);
        errorStore.setError(true, "Dieser Tarif ist nicht beantragbar.");
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }

  watch(antragfragen, (newValue) => {
    emit("updateAntrag", newValue); // Emit das Update Event mit dem neuen Wert
  });
  watch(
    () => route.params,
    () => {
      // Die Funktion checkRouteChange wird aufgerufen, wenn sich die Route-Parameter ändern
      getVergleich();
    },
    { deep: true }
  );
  onMounted(() => {
    console.log(props.token);
    getVergleich();
  });
</script>
