<template>
  <div>
    <v-row class="mb-2">
      <v-col cols="12" md="4">
        <strong>Erstattung für Sehhilfen</strong>
      </v-col>
      <v-col cols="12" md="8">
        <strong>{{ tarif.bri_sehhilfe_zahl }} %</strong> | tarifliche Erstattung
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row class="mb-2">
      <v-col cols="12" md="4">
        <strong>Maximale Erstattung</strong>
      </v-col>
      <v-col cols="12" md="8">
        <strong>{{ tarif.bri_erstattung_max_eur }} €</strong> |
        {{ tarif.bri_erstattung_max_zeit }}<br />
        {{ tarif.bri_sehhilfe_detail }}
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row class="mb-2">
      <v-col cols="12" md="4">
        <strong>Anfängliche Begrenzungen</strong>
      </v-col>
      <v-col cols="12" md="8">
        {{ tarif.bri_begrenzung_zusatz_erste_jahre }}
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row class="mb-2">
      <v-col cols="12" md="4">
        <strong>Wartezeiten</strong>
      </v-col>
      <v-col cols="12" md="8">
        <span v-if="tarif.bri_wartezeit">{{ tarif.bri_wartezeit }}</span>
        <span v-if="!tarif.bri_wartezeit">keine Wartezeit</span>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row class="mb-2">
      <v-col cols="12" md="4">
        <strong>Erstattung für Sonnenbrillen</strong>
      </v-col>
      <v-col cols="12" md="8">
        {{ tarif.bri_erstattung_sonnenbrille_short }}
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row class="mb-2">
      <v-col cols="12" md="4">
        <strong>Erstattung für Gleitsichtbrille</strong>
      </v-col>
      <v-col cols="12" md="8">
        <span v-if="tarif.bri_gleitsicht == 1">Ja</span>
        <span v-if="tarif.bri_gleitsicht == 0">Nein</span>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row class="mb-2">
      <v-col cols="12" md="4">
        <strong>Erstattung für Kontaktlinsen</strong>
      </v-col>
      <v-col cols="12" md="8">
        <span v-if="tarif.bri_erstattung_linse == 1">Ja</span>
        <span v-if="tarif.bri_erstattung_linse == 0">Nein</span>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row class="mb-2">
      <v-col cols="12" md="4">
        <strong>Erstattung für Prismenbrille</strong>
      </v-col>
      <v-col cols="12" md="8">
        {{ tarif.bri_erstattung_prisma_short }}
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row class="mb-2">
      <v-col cols="12" md="4">
        <strong>Erstattung für Glaukom-Vorsorge</strong>
      </v-col>
      <v-col cols="12" md="8">
        {{ tarif.bri_glaukom }}
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row class="mb-2">
      <v-col cols="12" md="4">
        <strong>Erstattung für Lasik</strong>
      </v-col>
      <v-col cols="12" md="8">
        <span v-if="tarif.bri_lasik_leistung == 1">{{
          tarif.bri_lasik_begrenzung
        }}</span>
        <span v-if="tarif.bri_lasik_leistung == 0">Nein</span>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row class="mb-2">
      <v-col cols="12" md="4">
        <strong>Erstattung für Vorsorge-Untersuchungen</strong>
      </v-col>
      <v-col cols="12" md="8">
        {{ tarif.bri_zusatz_vorsorge_short }}
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row class="mb-2">
      <v-col cols="12" md="4">
        <strong>Ersattung für Zusatzimpfungen</strong>
      </v-col>
      <v-col cols="12" md="8">
        {{ tarif.bri_zusatz_impfung_short }}
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row class="mb-2">
      <v-col cols="12" md="4">
        <strong>Zusätzliche Leistungen</strong>
      </v-col>
      <v-col cols="12" md="8">
        <ul>
          <li v-for="item in tarif.bri_umfang" :key="item.id">
            <span
              v-if="item.no_value"
              class="red--text"
              v-html="item.leistung"
            ></span>
            <span v-if="!item.no_value" v-html="item.leistung"></span>

            <span v-if="item.details">
              ...
              <a @click="item.show_detail = !item.show_detail">mehr...</a>
              <span v-if="item.show_detail" class="pa-8">
                <br />
                {{ item.details }}
              </span>
            </span>
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row class="mb-2">
      <v-col cols="12" md="4">
        <strong>Leistungen für Hörgeräte</strong>
      </v-col>
      <v-col cols="12" md="8">
        {{ tarif.bri_zusatz_hoergeraet_zahl_short }}<br />
        {{ tarif.bri_zusatz_hoergeraet_detail }}
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row class="mb-2">
      <v-col cols="12" md="4">
        <strong
          >Vertragsmodalitäten des {{ tarif.un_name }}
          {{ tarif.tarif_title }}</strong
        >
      </v-col>
      <v-col cols="12" md="8">
        <strong>Mindestvertragslaufzeit der Brillenversicherung</strong><br />
        {{ tarif.bri_vertrag_Mindestvertragszeit }}
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
    <v-row class="mb-2" v-if="tarif.tarif_int_kuendigung_details != ''">
      <v-col cols="12" md="4">
        <strong>Kündigungsfrist der Brillenversicherung</strong>
      </v-col>
      <v-col cols="12" md="8">
        {{ tarif.tarif_int_kuendigung_details }}<br />
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
