// windowSizePlugin.js
import { reactive } from "vue";

export default {
  install(app) {
    const windowSize = reactive({
      width: window.innerWidth,
      height: window.innerHeight,
      size: "m", // Standardwert basierend auf deiner initialen Logik
    });

    function updateWindowSize() {
      windowSize.width = window.innerWidth;
      windowSize.height = window.innerHeight;
      // Aktualisiere die Größe basierend auf der neuen Fensterbreite
      windowSize.size = windowSize.width < 780 ? "s" : "m";
    }

    // Füge den Event Listener hinzu
    window.addEventListener("resize", updateWindowSize);
    // Initialer Aufruf, um die korrekte Größe basierend auf dem aktuellen Fenster zu setzen
    updateWindowSize();

    // Bereinige den Event Listener bei Entfernen/Unmounting (Hier muss eine andere Strategie gewählt werden)
    // Für globale Plugins könnte es notwendig sein, eine manuelle Bereinigungsfunktion bereitzustellen.

    // Stelle das reaktive Objekt bereit, anstatt nur den String
    app.config.globalProperties.$windowSize = windowSize;
  },
};
