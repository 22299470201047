<template>
  <div>
    <v-row>
      <v-col cols="12" md="12"
        ><h2 class="text-red">Dieser Tarif ist nicht beantragbar</h2>

        <span v-if="!tarif"
          >Aufgrund Ihrer Angaben im Rechner würde Ihr Antrag abgelehnt werden.
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
