<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2>
          WaizmannWert Krankenhaus-Zusatzversicherung Durchschnittserstattung
        </h2>
        Der Krankenhaus-WaizmannWert ist ein Analyseverfahren zur Bewertung von
        Krankenhausversicherungen und zeigt die Leistungsstärke der einzelnen
        Krankenhaus-Tarife übersichtlich und anschaulich. Der KH-WaizmannWert
        wird aus einem Punktesystem errechnet, bei dem die einzelnen Leistungen
        einer stationären Zusatzversicherung, wie z.B. Ein-/Zweibettzimmer oder
        Chefarztbehandlung, mit unterschiedlich hohen Punktwerten be- und
        gewertet werden. Der Krankenhaus-Zusatztarif mit der höchsten Punktzahl
        erhält dabei 100% KH-WaizmannWert, die Krankenhausversicherungen mit
        einer geringeren Punktzahl erhalten entsprechend umgerechnet einen
        Krankenhaus-WaizmannWert kleiner 100%.
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
