<template>
  <div>
    <v-row>
      <v-col cols="12" md="12"
        ><h2>Keine KFO-Leistung mit Zahn-/Kieferfehlstellung</h2>
        Es gibt keine Zahnzusatzversicherung mit KFO-Leistungen , die bei
        bereits mit vorhandener Zahn-/Kieferfehlstellung beantragbar sind, bzw.
        für noch für eine KFO-Behandlung noch leisten würden.
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
