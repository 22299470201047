<template>
  <div>
    <v-row>
      <v-col cols="12" md="9"
        ><h2>Wohin sollen wir Ihr Infopaket senden?</h2>
        Geben Sie jetzt Ihre Versicherungsdaten ein. Dann haben Sie alle
        Schritte erfolgreich abgeschlossen. Sie bekommen in den 1-2 Tagen das
        gewünschte Infopaket per Post. Per E-Mail an Werktagen innerhalb 2-3
        Stunden. <b>Kostenlos und 100 % unverbindlich</b>.
      </v-col>
      <v-col cols="12" md="3"
        ><h2 class="text-green">Schritt 2 von 2</h2>
        Gleich haben Sie es geschafft.</v-col
      >
    </v-row>
    <v-divider :thickness="4" color="blue" class="my-4"></v-divider>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
