<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <strong>Lücken</strong><br />
        <span class="caption">bzw. fehlende Zähne</span>
      </v-col>
      <v-col cols="12" md="9">
        <template v-if="tarif.un_name">
          <span v-if="berechnung.ber_luecke == 1">
            Ihr <strong>fehlender Zahn</strong> ist
          </span>
          <span v-if="berechnung.ber_luecke > 1">
            Ihre
            <strong>{{ berechnung.ber_luecke }} fehlenden Zähne</strong> sind
          </span>
          <span v-if="tarif.LueckeVersicherung == 1">
            <strong class="text-green">kostenlos mitversichert</strong>.
          </span>
          <span
            v-if="
              tarif.LueckeVersicherung == 2 || tarif.LueckeVersicherung == 3
            "
          >
            gegen einen Zuschlag von {{ tarif.zuschlag_zahn }} € mitversichert.
            Der Zuschlag ist im Monatsbeitrag von {{ tarif.preis_value }} €
            bereits enthalten.
          </span>
          <span v-if="tarif.LueckeVersicherung == 4">
            ohne Aufschlag mitversichert. Allerdings verlängern sich die
            anfänglichen Begrenzungen.
          </span>
          <span v-if="tarif.LueckeVersicherung == 0">
            <strong class="text-red">nicht mitversichert.</strong>
          </span>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  //   import { useBerechnungStore } from "@/stores/berechnung";
  //   import { useSharedDataStore } from "@/stores/sharedData";
  //   const sharedDataStore = useSharedDataStore();
  //   const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
