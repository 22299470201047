<template>
  <div>
    <h1>
      AntragAnnahmecheck |
      <span class="text-red"
        >Negatives Ergebnis bei {{ vergleichCount.notarifeCount }} Tarifen</span
      >
    </h1>

    <br />Bei diesen Tarifen würde Ihr
    <b class="text-red">Antrag abgelehnt</b> werden. Bei uns erfahren Sie
    bereits
    <strong>vor</strong>
    der Beantragung, welche Versicherungen Ihren Antrag ablehnen bzw. annehmen.
    <br />
    <br />
    <v-row mb-4 v-for="tarif in notarife" :key="tarif.tarif_id">
      <v-col cols="12" md="12" class="mb-4">
        <strong style="font-size: 18px"
          >{{ tarif.un_name }} {{ tarif.tarif_title }}</strong
        >
        <v-row v-for="(log, value) in tarif.tarifAnnahmeFalseLog" :key="value">
          <v-col cols="2">Grund:</v-col>
          <v-col cols="10">{{ log.value }}</v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  //import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();

  const props = defineProps({
    notarife: {
      type: Object,
      default: () => ({}),
    },
    vergleichCount: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
