<template>
  <div>
    <!-- AllesOk -> {{ alleSignupChecked }} -> AlleSigns {{ alleSignupsLength }} ->
    Erfolgreich {{ countSuccessSignups }} -->
    <!-- {{ berechnung.ber_alter }} -->
    <div style="height: 1000px" v-if="!showSignUpForm && alleSignupChecked">
      <h2 class="text-green">
        Geschafft! Wir leiten Ihren Antrag jetzt an die Versicherung weiter
      </h2>
      Sie erhalten normalerweise innerhalb von 7 Tagen Ihre Police per Post.
      Danach haben Sie noch ein 14-tägiges Widerrufsrecht.
    </div>
    <div style="height: 1000px" v-if="!showSignUpForm && !alleSignupChecked">
      <h2>Es wurde kein Antrag zum unterschreiben gefunden</h2>
    </div>
    <div style="height: 1000px" v-if="showSignUpForm">
      <v-row>
        <v-col cols="12" md="10">
          <div class="mb-4">
            <v-row>
              <v-col cols="12" md="9">
                <h2>Antrag jetzt einfach online unterschreiben</h2>
                <h1>
                  <span class="text-blue"
                    >{{ tarif.un_name }} {{ tarif.tarif_title }}</span
                  >
                </h1>
                <span v-if="signData.kontoSaved"
                  >Ihre Bankverbindung wurde bereits vermerkt.
                </span>

                <ul v-if="signsDone && signsDone.length > 0">
                  <li style="list-style-type: none">
                    <b>Diese Unterschriften wurden bereits gespeichert:</b>
                  </li>
                  <li class="ml-4" v-for="s in signsDone" :key="s.del_id">
                    {{
                      sharedDataStore.getSignType(
                        s.del_sign_type,
                        signData,
                        "title"
                      )
                    }}
                  </li>
                </ul>
                <span v-if="!signData.kontoSaved"
                  >Fast geschafft. Hinterlegen Sie jetzt noch Ihre
                  Bankverbindung.</span
                >
                <span v-if="alleSignupsLength > 0"
                  >Diese Unterschriften ({{ alleSignupsLength }}) werden noch
                  benötigt:
                </span>
                <br />
              </v-col>
              <v-col cols="12" md="3" v-if="sharedDataStore.isDesktop()">
                <h2 class="text-green">Ohne Risiko</h2>
                Nach der Unterschrift erhalten Sie zuerst eine Police. Danach
                haben Sie noch <b class="text-green">14 Tage Zeit</b> vom
                Vertrag zurückzutreten.
              </v-col>
            </v-row>
          </div>
          <div
            v-if="
              !avbErhalten && !signData.avbDone && sharedDataStore.isDesktop()
            "
            class="pa-4 mb-4"
            style="background-color: #f2f2f2"
          >
            <h2>Schritt 1: Bitte laden Sie die Vertragsunterlagen runter</h2>
            <ul>
              <li v-for="a in avb" v-bind:key="a.vdocs_id">
                <a :href="'/download/' + a.vdoc_dateiname" target="_blank">{{
                  a.vdoc_text
                }}</a>
              </li>
            </ul>
            <v-btn
              class="nocaps"
              color="blue"
              @click="avbErhalten = !avbErhalten"
            >
              Ja, ich habe die AVB zur Kenntnis genommen.
            </v-btn>
          </div>
          <div v-for="(s, index) in alleSignups" :key="s.del_id">
            <SignPad
              class="pa-0 mb-4"
              :tarif="tarif"
              :signNr="index + 1"
              :kunde="signData"
              :berechnung="berechnung"
              @update-success="handleUpdateSuccess"
              @update-konto="handleUpdateKonto"
              :element="s"
            />
          </div>
        </v-col>
        <v-col cols="12" md="2" v-if="sharedDataStore.isDesktop()">
          <QrcodeVue :value="signUrl" :size="120" />
          <h3>Am <b>Smartphone</b> unterschreiben.</h3>
          Scannen Sie den QR-Code mit Ihrem Smartphone, um den Antrag auf Ihrem
          Smartphone zu unterschreiben.

          <!-- <br /><br />
          <h3>Unterschriften noch offen</h3>
          <ul>
            <li v-for="s in alleSignups" :key="s.del_id">
              {{ s.del_id }}: {{ s.del_success }}
            </li>
          </ul>

          {{ berechnung.ber_id }}<br />
          {{ signData }} -->
        </v-col>
      </v-row>
      <div v-if="appLoaded" id="appLoaded"></div>
    </div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted, computed, watch } from "vue";
  import { useRoute } from "vue-router";
  import SignPad from "@/components/berechnung/form/SignPad";
  import BaseCalls from "@/services/BaseCalls";
  import { useLoaderStore } from "@/stores/loaderStore";
  import { useErrorStore } from "@/stores/errorStore";
  import { useSharedDataStore } from "@/stores/sharedData";
  import QrcodeVue from "qrcode.vue";

  const errorStore = useErrorStore();
  const sharedDataStore = useSharedDataStore();
  const loaderStore = useLoaderStore();
  const route = useRoute();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  const avbErhalten = ref(false);
  const alleSignups = ref([]);
  const signsDone = ref([]);
  const signData = ref(false);
  const alleSignupChecked = ref(false);
  const showSignUpForm = ref(false);
  const insertTodoDone = ref(false);
  const signUrl = ref(
    "https://www.waizmanntabelle.de/signup/" +
      route.params.token +
      "/" +
      route.params.tarif_id
  );
  const tarif = ref(false);
  const berechnung = ref(false);
  const avb = ref(false);

  const alleSignupsLength = computed(() => {
    // Filtert das Array, um nur jene Elemente zu behalten, bei denen del_sign_fehlt_noch true ist
    const filteredSignups = alleSignups.value.filter(
      (signup) => signup.del_sign_fehlt_noch === true
    );
    // Gibt die Länge des gefilterten Arrays zurück
    return filteredSignups.length;
  });

  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }
  // function checkAllSigns() {
  //   if (alleSignups.value.every((s) => s.del_success === true)) {
  //     alleSignupChecked.value = true;
  //   }
  //   alleSignupChecked.value = false;
  // }

  async function getAbv() {
    let berSelect = {};
    berSelect.showSignup = "showSignup";
    berSelect.action = "getTarifAvb";
    berSelect.vdoc_wtb_id = tarif.value.tarif_id;
    try {
      const resp = await BaseCalls.postTarif(berSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.avb) {
        avb.value = resp.data.avb;
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }

  async function insertSignTodo() {
    try {
      let insertData = {
        action: "insertSignTodo",
        aid: berechnung.value.ber_kunden_id,
        abs_id: berechnung.value.ber_abs_id,
      };
      const resp = await BaseCalls.postBerechnung(insertData);
      if (resp.data && resp.data.success) {
        insertTodoDone.value = true;
      } else {
        // Behandle den Fall, dass die Operation nicht erfolgreich war
        console.error("Operation nicht erfolgreich:", resp.data);
        insertTodoDone.value = false;
      }
    } catch (error) {
      // Fehlerbehandlung, falls beim API-Aufruf ein Fehler auftritt
      console.error("Fehler beim Einfügen des Todo:", error);
      insertTodoDone.value = false;
    }
  }

  async function getSignups() {
    let select = {};
    select.tarif_id = tarif.value.tarif_id;
    select.ber_identity_token = route.params.token;
    select.action = "getAlleSignups";

    try {
      const resp = await BaseCalls.postBerechnung(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data) {
        if (resp.data.signups) {
          console.log("getSignup!s " + resp.data.signups);
          showSignUpForm.value = true;
          getAbv();
        }
        alleSignups.value = resp.data.signups;
        signsDone.value = resp.data.signsdone;
        signData.value = resp.data.kunde;
        //signData.value.kontoSaved = true;
        setLoading(false);
        //getSignData();
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }
  // const countSuccessSignups = computed(() => {
  //   if (alleSignups.value) {
  //     return alleSignups.value.filter((signup) => signup.del_success == true)
  //       .length;
  //   } else {
  //     return 0;
  //   }
  // });
  const handleUpdateKonto = () => {
    signData.value.kontoSaved = true;
  };

  const handleUpdateSuccess = (del_success, delId) => {
    const element = alleSignups.value.find((s) => s.del_id === delId);
    if (element) {
      element.del_success = del_success;
      signsDone.value.push(element);
    }
  };
  async function getVergleich() {
    setLoading(true);
    console.log("getVergleich " + route.params.tarif_id);
    let berSelect = {};
    // holt internFields
    berSelect.action = "getTarifAntragBerechnung";
    berSelect.ber_identity_token = route.params.token;
    berSelect.tarif_id = route.params.tarif_id;
    berSelect.showPreisJahrePlus = 1;
    try {
      const resp = await BaseCalls.postTarif(berSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.berechnung) {
        console.log("getVergleichErgebnis " + resp.data.berechnung.ber_id);
        tarif.value = resp.data.tarif;
        berechnung.value = resp.data.berechnung;
        if (resp.data.berechnung.ber_signed == 0) {
          getSignups();
        }
        if (resp.data.berechnung.ber_signed == 1) {
          setLoading(false);
          errorStore.setError(true, "Der Antrag wurde schon unterschrieben.");
        }
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }
  watch(
    alleSignups,
    (newSignups) => {
      // Sicherstellen, dass newSignups definiert und ein Array ist
      if (Array.isArray(newSignups)) {
        // Prüft, ob jedes Element in alleSignups den del_success auf true hat
        if (newSignups.every((signup) => signup.del_success === true)) {
          alleSignupChecked.value = true;
          showSignUpForm.value = false;
          if (!insertTodoDone.value) {
            insertSignTodo();
          }
        } else {
          alleSignupChecked.value = false;
        }
      } else {
        // Sicherheitsmaßnahme: Setze den Wert auf false, wenn newSignups ungültig ist
        alleSignupChecked.value = false;
      }
    },
    {
      deep: true, // Setzt den Watcher auf "deep", um auf tiefere Änderungen zu achten
    }
  );

  onMounted(() => {
    getVergleich();
    proxy.$updateDocumentHead("SignUp - Online-Abschluss", "");
  });
</script>
