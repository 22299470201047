<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="9" style="background-color: #fafafa">
        <!-- <ul>
          <li>
            AntragClicked: {{ antragButtonClicked }} - errHi:
            {{ showErrorHinweis }}
          </li>
          <li v-for="a in antragfragen" :key="a.antrag_id">
            {{ a.showFrageError }}
          </li>
        </ul> -->
        <KeinAntrag
          v-if="!showDoppelantrag && !tarif && berechnung"
          :tarif="tarif"
          :berechnung="berechnung"
        />
        <DoppelAntrag :tarif="tarif" v-if="showDoppelantrag && tarif" />
        <AntragStep1Header
          :tarif="tarif"
          :berechnung="berechnung"
          v-if="tarif"
        />

        <PreisSprung
          class="my-4"
          v-if="!showDoppelantrag && tarif && tarif.preissprungProzent1 > 0"
          :tarif="tarif"
          :componentKeyStatus="componentKeyStatus"
        />
        <div
          v-if="!showDoppelantrag && tarif && berechnung.ber_segment > 1"
          class="my-12"
        ></div>
        <ClearmentAuswahl
          v-if="tarif && berechnung.ber_segment <= 1"
          @updatedClearment="handleClearment"
          :tarif="tarif"
          :berechnung="berechnung"
          :componentKeyStatus="componentKeyStatus"
        />

        <SofortVersichert
          v-if="
            !showDoppelantrag &&
            tarif &&
            tarif.tarif_sofort > 0 &&
            berechnung.ber_sofort == 1
          "
          @updatedMitversicherungSofort="handleMitversicherungSofort"
          :tarif="tarif"
          :berechnung="berechnung"
          :componentKeyStatus="componentKeyStatus"
        />

        <antragfragen-form
          v-if="!showDoppelantrag"
          :token="route.params.token"
          :tarif_id="route.params.tarif_id"
          :componentKeyStatus="componentKeyStatus"
          @updatedTarif="handleTarifUpdate"
          @updateAntrag="handleAntragUpdate"
          @updateBerechnung="handleBerechnungUpdate"
        />
        <v-row v-if="showErrorHinweis && antragButtonClicked" class="my-4">
          <v-col cols="12" md="12">
            <h3 class="text-orange">Bitte überprüfen Sie Ihre Angaben</h3>
            Bitte prüfen Sie noch Ihre Angaben der
            <b class="text-red">rot-markierten</b> Felder.
          </v-col>
        </v-row>
        <v-row v-if="!showDoppelantrag && tarif">
          <v-col cols="12" md="12">
            <v-row v-if="antragSubmited">
              <v-col cols="12" md="2">
                <v-progress-circular
                  :size="50"
                  color="green"
                  indeterminate
                ></v-progress-circular
              ></v-col>
              <v-col cols="12" md="10">
                <h2 class="text-green">
                  Bitte haben Sie noch ein wenig Geduld
                </h2></v-col
              >
            </v-row>

            <v-btn
              v-if="!antragSubmited"
              @click="submitAntragStep1"
              color="blue"
              style="height: 48px; font-size: 24px"
              elevated
              tile
              rounded="0"
              type="submit"
              class="button_caps"
              tonal
              value="jetzt Berechnung starten &nbsp; Ergebnis anzeigen"
            >
              <span v-if="sharedDataStore.isMobile()">weiter zu Schritt 2</span>
              <span v-if="!sharedDataStore.isMobile()"
                >jetzt weiter zu Schritt 2 von 2</span
              >
            </v-btn>
          </v-col>
        </v-row>
        <Antrag1Button v-if="!sharedDataStore.isMobile() && tarif" />
      </v-col>
      <v-col cols="12" md="3" v-if="!sharedDataStore.isMobile()">
        <InfopaketHinweise v-if="tarif" />
        <!-- <h2>!{{ tarif.tarif_id }} | {{ berechnung.ber_id }}</h2>
        <ul>
          <li>Preis: {{ tarif.preis_value }}</li>
          <li>PreisPlus 1: {{ tarif.preis_value_plus_1 }}</li>
          <li>
            PreisSpung: {{ tarif.preissprung1 }} |
            {{ tarif.preissprungProzent1 }} %
          </li>
          <li>Submitted: {{ antragSubmited }}</li>
          <li>Mitversicheurng Sofort: {{ mitVersicherungSofort }}</li>
          <li>Clearment: {{ mitClearment }}</li>
          <li>
            Doppel: {{ berechnung.ber_kunden_id }} -
            {{ berechnung.ber_kunden_wtb_id }} - {{ showDoppelantrag }}
          </li>
        </ul>
        {{ antragfragen }} -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
  import { watch, ref, onMounted, getCurrentInstance } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import AntragfragenForm from "@/components/berechnung/form/AntragfragenForm";
  import DoppelAntrag from "@/components/berechnung/form/info/DoppelAntrag";
  import InfopaketHinweise from "@/components/berechnung/info/InfopaketHinweise";
  import SofortVersichert from "@/components/berechnung/form/info/SofortVersichert";
  import ClearmentAuswahl from "@/components/berechnung/form/info/ClearmentAuswahl";
  import KeinAntrag from "@/components/berechnung/form/KeinAntrag";
  import AntragStep1Header from "@/components/berechnung/form/AntragStep1Header";
  import PreisSprung from "@/components/tarif/info/PreisSprung";
  import Antrag1Button from "@/components/sales/Antrag1Button";
  import { useSharedDataStore } from "@/stores/sharedData";
  import { useLoaderStore } from "@/stores/loaderStore";
  import { useErrorStore } from "@/stores/errorStore";
  import BaseCalls from "@/services/BaseCalls";
  import { useBerechnungStore } from "@/stores/berechnung";
  const berechnungStore = useBerechnungStore();
  const errorStore = useErrorStore();
  const { proxy } = getCurrentInstance();
  const loaderStore = useLoaderStore();
  const sharedDataStore = useSharedDataStore();
  const route = useRoute();
  const router = useRouter();
  const componentKeyStatus = ref(0);
  const tarif = ref(false);
  const antragfragen = ref(false);
  const berechnung = ref(false);
  const antragSubmited = ref(false);
  const antragButtonClicked = ref(false);
  const showDoppelantrag = ref(false);
  const mitVersicherungSofort = ref(false);
  const mitClearment = ref(true);
  const showErrorHinweis = ref(false);

  function handleTarifUpdate(data) {
    tarif.value = data;
  }

  function handleAntragUpdate(data) {
    antragfragen.value = data;
  }
  function handleBerechnungUpdate(data) {
    berechnung.value = data;
    if (berechnung.value.ber_kunden_id > 0) {
      showDoppelantrag.value = true;
    }
  }
  function handleMitversicherungSofort(data) {
    mitVersicherungSofort.value = data;
  }
  function handleClearment(data) {
    mitClearment.value = data;
  }

  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }
  const checkAntragOk = () => {
    if (Array.isArray(antragfragen.value)) {
      return !antragfragen.value.some((frage) => frage.showFrageError);
    }
    return true;
  };

  async function submitAntragStep1() {
    antragButtonClicked.value = true;
    if (!checkAntragOk()) {
      showErrorHinweis.value = true;
      return false;
    }
    showErrorHinweis.value = false;
    antragSubmited.value = true; // eigentlich auf true
    let select = {};
    select.action = "checkAntragStep1Formular";

    //select.value.option_clearment = this.clear_ja;
    //select.value.mitversicherung_ja = this.mitversicherung_ja;
    select.antragfragen = antragfragen.value;
    select.ber_identity_token = berechnung.value.ber_identity_token;
    select.ber_alter = berechnung.value.ber_alter;
    select.tarif_id = tarif.value.tarif_id;
    select.mitClearment = mitClearment.value;
    select.mitVersicherungSofort = mitVersicherungSofort.value;
    setLoading(true);
    console.log("submit " + select.tarif_id);
    try {
      const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.success) {
        router.push(
          "/angebot/kostenlos_fertig/" +
            tarif.value.tarif_id +
            "/" +
            berechnung.value.ber_identity_token
        );
      } else {
        antragSubmited.value = false;
        setLoading(false);
        errorStore.setError(true, "Dieser Tarif ist nicht beantragbar.");
      }
    } catch (err) {
      antragSubmited.value = false;
      console.log("error berechnung");
    }
  }
  onMounted(() => {
    berechnungStore.setShowFooter(false);
    setLoading(true);
    proxy.$updateDocumentHead(
      "Infopaket anfordern - kostenlos",
      "Infopaket anfordern"
    );
  });
  watch(
    () => antragfragen.value,
    (currentValue) => {
      // Prüfe, ob das Array leer oder undefiniert ist
      if (!currentValue || currentValue.length === 0) {
        return; // Beendet die Funktion frühzeitig, wenn keine Daten vorhanden sind
      }

      // Prüfe, ob alle Elemente in antragfragen den showFrageError auf false haben
      const allFalse = currentValue.every((frage) => !frage.showFrageError);

      // Setze showErrorHinweis basierend auf der Prüfung
      if (antragButtonClicked.value) {
        showErrorHinweis.value = !allFalse;
      }
    },
    { deep: true } // Deep watching, um auf Änderungen in Objekteigenschaften zu reagieren
  );
  watch(
    () => route.params.token,
    () => {
      componentKeyStatus.value = componentKeyStatus.value + 1;
    },
    { immediate: true }
  );
</script>
