<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">Naturheilkunde:</v-col>
      <v-col cols="12" md="9">
        <strong>{{ tarif.hp_naturheilkunde_heilpraktiker }} %</strong> bei
        Heilpraktiker |
        <strong>{{ tarif.hp_naturheilkunde_arzt }} %</strong> bei Arzt
        <a @click="showDetail = !showDetail">Details</a>
        <span v-if="showDetail">
          <br />
          <ul>
            <li v-for="item in tarif.hp_leistung_array" :key="item">
              {{ item }}
            </li>
          </ul>
        </span>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row>
      <v-col cols="12" md="3">Begrenzungen:</v-col>
      <v-col cols="12" md="9">
        <strong>{{ tarif.hp_begrenzungen_hp }}</strong> für
        Naturheilkunde-Leistungen
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row>
      <v-col cols="12" md="3">Wartezeit:</v-col>
      <v-col cols="12" md="9">{{ tarif.hp_wartezeit }}</v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row>
      <v-col cols="12" md="3">Leistungs-Umfang:</v-col>
      <v-col cols="12" md="9">
        <ul class="body-2">
          <li v-for="item in tarif.hp_umfang_full" :key="item.id">
            <span>
              <span
                v-if="item.no_value"
                class="text-red"
                v-html="item.leistung"
              ></span>
              <span v-if="!item.no_value" v-html="item.leistung"></span>

              <span v-if="item.details">
                ...
                <a @click="item.show_detail = !item.show_detail">mehr...</a>
                <span v-if="item.show_detail" class="pa-8">
                  <br />
                  {{ item.details }}
                </span>
              </span>
            </span>
          </li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps, ref } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  const showDetail = ref(false);
  console.log(props.tarif);
</script>
