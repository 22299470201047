<template>
  <div>
    <v-table>
      <thead>
        <tr style="vertical-align: top">
          <th>Tarif</th>
          <th>Preis</th>
          <th>Ø-Erstattung</th>

          <th>Angebot</th>
        </tr>
      </thead>
      <tbody>
        <tr style="vertical-align: top">
          <!-- Erzeugen der Header-Zellen aus den Array-Daten -->
          <th v-for="header in headersErwachsen" :key="header.value">
            {{ header.text }}
          </th>
        </tr>
        <tr v-for="item in alletarife" :key="item.tarif_id">
          <td>
            <strong>
              <a :href="item.antraglink">{{ item.un_name }}</a>
            </strong>
            <br />
            {{ item.tarif_title }}
            <span v-if="item.empfehlung_type > 0">
              <br />
              <strong class="text-green">Empfehlung</strong>
            </span>
          </td>

          <td style="min-width: 80px">{{ item.preis_value }} €</td>
          <td style="min-width: 60px">{{ item.hp_gesamterstattung_2_4 }} %</td>

          <td>
            <a v-if="item.antraglink" :href="item.antraglink">Angebot</a>
            <span v-if="item.empfehlung_type > 0">
              <sup class="text-green">Tipp</sup>
            </span>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    alletarife: {
      type: Array,
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });

  console.log(props.tarif);
</script>
