<template>
  <div>
    <h2>Vergleich der wichtigsten Kinder Zahnarztleistungen</h2>
    <v-table>
      <thead>
        <tr style="vertical-align: top">
          <th><strong>Tarif</strong></th>
          <th>
            <TooltipHelper toolkey="kig1">
              <template v-slot:toolTipText>
                <a>KFO</a><br /><span class="caption">KIG 3-5</span>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="kig1">
              <template v-slot:toolTipText>
                <a>KFO</a><br /><span class="caption">KIG 1-2</span>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="fis">
              <template v-slot:toolTipText>
                <a>FIS</a>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="kpzr">
              <template v-slot:toolTipText>
                <a>PZR</a>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="kfo_funk">
              <template v-slot:toolTipText>
                <a>KFO-Funk</a>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="mini_brack">
              <template v-slot:toolTipText>
                <a>Mini-Brack</a>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="kunst_brack">
              <template v-slot:toolTipText>
                <a>Kunst.-Brack</a>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="farbl_bogen">
              <template v-slot:toolTipText>
                <a>farbl. Bögen</a>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="lingua">
              <template v-slot:toolTipText>
                <a>Lingua</a>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="retainer">
              <template v-slot:toolTipText>
                <a>Retainer</a>
              </template>
            </TooltipHelper>
          </th>
          <th>
            <TooltipHelper toolkey="invisa">
              <template v-slot:toolTipText>
                <a>Invisa.</a>
              </template>
            </TooltipHelper>
          </th>
          <th></th>
          <!-- Blanker Platzhalter für leeren Platz am Ende, falls benötigt -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="t in alletarife" :key="t.tarif_id">
          <td>
            <strong>
              <router-link v-if="t.antraglink" :to="t.antraglink">
                <a>{{ t.un_name }}</a>
              </router-link>
              <span v-if="!t.antraglink">{{ t.un_name }}</span>
              <span v-if="t.empfehlung_type > 0">
                <sup class="text-green">Tipp</sup>
              </span>
            </strong>
            <br />
            {{ t.tarif_title }}
          </td>
          <td>{{ t.nice_tarif_leistung_kfo_mgkv == "Ja" ? "Ja" : "Nein" }}</td>
          <td>{{ t.nice_tarif_leistung_kfo_ogkv == "Ja" ? "Ja" : "Nein" }}</td>
          <td>
            {{ t.nice_tarif_leistung_speziell_fis == "Ja" ? "Ja" : "Nein" }}
          </td>
          <td>
            {{ t.nice_tarif_leistung_speziell_pzr_k == "Ja" ? "Ja" : "Nein" }}
          </td>
          <td>
            {{
              t.nice_tarif_leistung_speziell_k_kfofta == "Ja" ? "Ja" : "Nein"
            }}
          </td>
          <td>
            {{
              t.nice_tarif_leistung_speziell_k_minibrack == "Ja" ? "Ja" : "Nein"
            }}
          </td>
          <td>
            {{
              t.nice_tarif_leistung_speziell_k_kunstbrack == "Ja"
                ? "Ja"
                : "Nein"
            }}
          </td>
          <td>
            {{
              t.nice_tarif_leistung_speziell_k_farblbogen == "Ja"
                ? "Ja"
                : "Nein"
            }}
          </td>
          <td>
            {{
              t.nice_tarif_leistung_speziell_k_lingua == "Ja" ? "Ja" : "Nein"
            }}
          </td>
          <td>
            {{
              t.nice_tarif_leistung_speziell_k_retainer == "Ja" ? "Ja" : "Nein"
            }}
          </td>
          <td>
            {{
              t.nice_tarif_leistung_speziell_k_invisa == "Ja" ? "Ja" : "Nein"
            }}
          </td>
          <td>
            <strong>
              <router-link v-if="getAntragLink" :to="getAntragLink">
                <a class="noUnderline">Antrag &amp; Infopaket</a>
              </router-link>
            </strong>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  import TooltipHelper from "@/components/base/TooltipHelper.vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
    alletarife: {
      type: Array,
    },
  });
  console.log(props.tarif);
</script>
