<template>
  <div>
    <v-card class="pa-4" height="1400px">
      <v-row
        ><v-col cols="9"></v-col
        ><v-col cols="3">
          <v-btn class="nocaps" @click="closeDialog"
            >schließen <v-icon>mdi-close</v-icon></v-btn
          >
        </v-col></v-row
      >
      <v-card-text style="max-height: 800px; overflow-y: auto">
        <v-row>
          <v-col cols="12">
            <h2 class="mb-4">
              Online Unterschrift:
              <span class="text-blue">
                {{ tarif.un_name }} {{ tarif.tarif_title }}
              </span>
            </h2>
            <h1>Unterschrift Nr. {{ nr }}</h1>
          </v-col>
          <v-col cols="12">
            <div
              ref="drawArea"
              :style="{
                position: 'relative',
                backgroundImage: `url('http://druck.waizmanntabelle.de/pdf_antrag/${element.del_tarif_id}/${element.del_background_image}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width:
                  '1230px' /* Stelle sicher, dass das Element breit genug ist */,
                height: '1740px' /* Setze eine spezifische Höhe */,
              }"
            >
              <!-- Rechteck-Overlay -->
              <div class="pa-4" :style="rectangleStyle">
                <h2 class="text-blue">Ihre Unterschrift</h2>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
  import { defineEmits, defineProps, computed } from "vue";
  //import { useSharedDataStore } from "@/stores/sharedData";
  //const sharedDataStore = useSharedDataStore();
  const emit = defineEmits(["close"]);

  function closeDialog() {
    emit("close");
  }
  const props = defineProps({
    tarif: Object,
    nr: String,
    element: Object,
  });

  // const showSignType = computed(() => {
  //   return sharedDataStore.getSignType(props.element.del_sign_type);
  // });

  // Berechnete Eigenschaft für die Rechteckstile
  const rectangleStyle = computed(() => ({
    position: "absolute",
    left: `${props.element.del_x}px`,
    top: `${props.element.del_y}px`,
    width: `${props.element.del_width}px`,
    height: `${props.element.del_height}px`,
    borderColor: "#555", // Grenzfarbe
    borderWidth: "1px",
    borderStyle: "solid",
    backgroundColor: "rgba(135, 206, 235, 0.3)", // Halbtransparentes Hellblau
  }));
</script>

<style scoped>
  .drawing-rectangle {
    /* Die eigentlichen Stile für das Rechteck werden im Script-Bereich als berechnete Eigenschaften gesetzt */
  }
</style>
