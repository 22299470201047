<template>
  <div>
    <h3>Hightech-Leistungen</h3>
    <v-row v-for="(item, index) in treatmentSections" :key="index">
      <v-col cols="12" md="4">
        <span v-if="sharedDataStore.isMobile()">
          <b>{{ item.title }}:</b>
        </span>
        <TooltipHelper v-if="!sharedDataStore.isMobile()" :toolkey="item.key">
          <template v-slot:toolTipText>
            <a
              ><span>{{ item.title }}</span></a
            >
          </template>
        </TooltipHelper>
      </v-col>
      <v-col cols="12" md="8">
        <b>{{ tarif[item.description] }}</b>
      </v-col>
      <v-divider :thickness="1"></v-divider>
    </v-row>
  </div>
</template>

<script setup>
  import { ref, defineProps } from "vue";
  import TooltipHelper from "@/components/base/TooltipHelper.vue";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
  const treatmentSections = ref([
    {
      title: "Laserbehandlung",
      tooltip: "Laserbehandlung",
      description: "tarif_leistung_trend_laser",
      key: "laser",
    },
    {
      title: "OP-Mikroskop",
      tooltip: "OP-Mikroskop",
      description: "tarif_leistung_trend_opmikro",
      key: "mikro",
    },
    {
      title: "CEREC-Behandlung",
      tooltip: "CEREC-Behandlung",
      description: "tarif_leistung_trend_cerec",
      key: "cerec",
    },
    {
      title: "Digitale Volumentomographie",
      tooltip: "Digitale Volumentomographie",
      description: "tarif_leistung_trend_dvt",
      key: "dvt",
    },
    {
      title: "Bakterien- / DNA-Test",
      tooltip: "Bakterien- / DNA-Test",
      description: "tarif_leistung_trend_dna",
      key: "dna",
    },
    {
      title: "Vollnarkose",
      tooltip: "Vollnarkose",
      description: "tarif_leistung_trend_narkose",
      key: "nark",
    },
    {
      title: "Akupunktur",
      tooltip: "Akupunktur",
      description: "tarif_leistung_trend_aku",
      key: "aku",
    },
    {
      title: "PACT - Photoaktivierte Chemotherapie",
      tooltip: "PACT",
      description: "tarif_leistung_trend_pact",
      key: "pact",
    },
    {
      title: "DROS-Schiene",
      tooltip: "DROS-Schiene",
      description: "tarif_leistung_trend_dros",
      key: "dros",
    },
    {
      title: "Aufbissschiene",
      tooltip: "Aufbissschiene",
      description: "tarif_leistung_trend_aufbiss",
      key: "aufb",
    },
    {
      title: "VECTOR-Technologie",
      tooltip: "VECTOR-Technologie",
      description: "tarif_leistung_trend_vector",
      key: "vect",
    },
    {
      title: "Invisalign",
      tooltip: "Invisalign",
      description: "tarif_leistung_trend_invisalign",
      key: "invisa",
    },
  ]);
</script>
