<template>
  <div>
    <!-- Bereichsanzeige -->
    <v-row>
      <v-col cols="12" md="3">Bereiche:</v-col>
      <v-col cols="12" md="9">
        Zahnersatz:
        <strong>{{ tarif.tarif_leistung_ze }} %</strong>
        | Zahnerhalt:
        <strong>{{ tarif.tarif_leistung_zb }} %</strong>
        <br />Prophylaxe:
        <strong>{{ tarif.show_pzr_grenze }}</strong>
      </v-col>
    </v-row>

    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Hightech-Leistungen und dynamischer Inhalt -->
    <v-row>
      <v-col cols="12" md="3"></v-col>
      <v-col cols="12" md="9">
        <a @click="showHigh = !showHigh" style="font-weight: bold"
          >Leistet für {{ tarif.tarif_count_high }}</a
        >
        von 12 Hightech-Leistungen
        <ul v-if="showHigh" class="body-2">
          <li v-for="item in tarif.highspezial" :key="item.id">
            <span v-if="item.ja">{{ item.value }}</span>
            <span
              v-if="!item.ja"
              class="text-red"
              style="text-decoration: line-through"
              >{{ item.value }}</span
            >
          </li>
          <li>
            <a @click="berechnungStore.setShowPop(tarif, 'vergleichhightech')"
              >Vergleich zu anderen Tarifen</a
            >
          </li>
        </ul>
      </v-col>
    </v-row>

    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Begrenzungen -->
    <v-row>
      <v-col cols="12" md="3">
        Begrenzungen:
        <strong
          v-if="tarif.LueckeVersicherung == 4"
          @click="berechnungStore.setShowPop(tarif, 'begrenzungzahn')"
          class="red--text"
        >
          <br />Hinweis lesen
        </strong>
      </v-col>
      <v-col cols="12" md="4">
        <strong
          ><a @click="berechnungStore.setShowPop(tarif, 'begrenzungzahn')">{{
            tarif.tarif_int_begrenzung_2
          }}</a></strong
        >
        | 1.-2. Jahr
      </v-col>
      <v-col cols="12" md="4">
        <strong
          ><a @click="berechnungStore.setShowPop(tarif, 'begrenzungzahn')">{{
            tarif.tarif_int_begrenzung_4
          }}</a></strong
        >
        | 3.-4. Jahr
      </v-col>
    </v-row>

    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Wartezeiten -->
    <v-row class="mb-1">
      <v-col cols="12" md="3">Wartezeiten:</v-col>
      <v-col cols="12" md="4">
        <strong>{{ tarif.tarif_beschreibung_wz_ze }}</strong>
        <br />
        <span class="caption">Zahnersatz</span>
      </v-col>
      <v-col cols="12" md="4">
        <strong>{{ tarif.tarif_beschreibung_wz_zb }}</strong>
        <br />
        <span class="caption">Zahnerhalt</span>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { ref, defineProps } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  //   import { useSharedDataStore } from "@/stores/sharedData";
  //   const sharedDataStore = useSharedDataStore();
  const berechnungStore = useBerechnungStore();

  const showHigh = ref(false);
  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
