<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" v-if="tarif.antraglink">
        <span v-if="sharedDataStore.isMobile()">
          <v-btn
            :to="tarif.antraglink"
            elevation="1"
            rounded="0"
            :color="berechnung.fontColorVue"
            class="button_caps"
            style="color: white !important; font-size: 18px"
          >
            Infopaket anfordern
          </v-btn>
        </span>
        <span v-if="!sharedDataStore.isMobile()">
          <v-btn
            :to="tarif.antraglink"
            elevation="1"
            rounded="0"
            :color="berechnung.fontColorVue"
            class="button_caps"
            style="color: white !important; font-size: 18px"
          >
            Antrag & Infopaket anfordern
            <span v-if="tarif.empfehlung_type > 0" class="white--text"
              >| kostenlos</span
            >
          </v-btn>
          <span
            style="font-size: 14px"
            v-if="tarif.empfehlung_type > 0 && tarif.antraglink"
          >
            <br />Fordern Sie jetzt Ihr
            <router-link :to="tarif.antraglink">
              <a class="noUnderline"
                >Infopaket mit <b>Clearment Zugangsdaten </b></a
              >
            </router-link>
            an.
          </span>
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
