import { defineStore } from "pinia";
import { ref } from "vue"; // Import von ref aus Vue

export const useMarketingDataStore = defineStore("marketingData", () => {
  const marketingData = ref({
    companyName: "WaizmannTabelle",
    buttonStep1Claim:
      "Seit 1995 von Zahnärzten empfohlen. Die Nr 1 bei Zahnärzten.",
  });

  return {
    marketingData,
  };
});
