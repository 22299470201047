<template>
  <v-dialog v-model="overlay" :width="widthDialog">
    <v-card class="mt-12 pa-4" style="height: 300px">
      <v-row>
        <v-col cols="2">
          <v-progress-circular
            :size="100"
            color="green"
            indeterminate
          ></v-progress-circular>
        </v-col>
        <v-col cols="10">
          <h2 class="text-green">{{ loaderMessage }}</h2>
          Bitte haben Sie noch einen kurzen Moment Geduld.
          <v-row> </v-row>
        </v-col>
        <v-col cols="12" md="3"> </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script setup>
  import { computed, ref } from "vue";
  import { useLoaderStore } from "@/stores/loaderStore";

  const loaderStore = useLoaderStore();
  const widthDialog = ref(666);
  const overlay = computed({
    get: () => !!loaderStore.load,
    set: (value) => !value && loaderStore.clearLoader(),
  });

  const loaderMessage = computed(
    () => loaderStore.message || "Ein unbekannter Fehler ist aufgetreten."
  );

  // function clearLoader() {
  //   loaderStore.clearLoader();
  // }
</script>
