<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <h1>Anonymer AntragAnnahmecheck | Kriterien</h1>
        Wir prüfen bereits <b>vor der Antragstellung</b>, ob Ihr Antrag
        angenommen würde. Dadurch ersparen Sie sich lästige Antragsablehnungen.
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3"><b>Antragsfrage</b></v-col>
      <v-col cols="12" md="3"><b>Ihre Angabe</b> </v-col>
      <v-col cols="12" md="2"><b>Annahme</b> </v-col>
      <v-col cols="12" md="4"><b>Antragsablehnung</b> </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">Alter</v-col>
      <v-col cols="12" md="3"
        ><b class="blue--text"
          >{{ berechnung.ber_tag }}.{{ berechnung.ber_monat }}.{{
            berechnung.ber_jahr
          }}</b
        ></v-col
      >
      <v-col cols="12" md="2"><b class="text-green">Antrag möglich</b></v-col>
      <v-col cols="12" md="4"
        >Einige Versicherungen haben einen Mindest-Eintrittsalter oder ein
        Maximal-Eintrittsalter.</v-col
      >
    </v-row>
    <!-- ENDE -->
    <span v-if="berechnung.ber_segment == 2 || berechnung.ber_segment == 3">
      <v-row>
        <v-col cols="12" md="3">Sehhilfen vorhanden</v-col>
        <v-col cols="12" md="3"
          ><b class="blue--text" v-if="berechnung.ber_brille_ja == 1">Ja</b>
          <b class="blue--text" v-if="berechnung.ber_brille_ja != 1">Nein</b>
        </v-col>
        <v-col cols="12" md="2"><b class="text-green">Antrag möglich</b></v-col>
        <v-col cols="12" md="4"
          >Einige Versicherungen lehnen einen Antrag bei vorhandenenen Sehhilfen
          ab.</v-col
        >
      </v-row>
    </span>
    <span v-if="berechnung.ber_segment == 2">
      <v-row>
        <v-col cols="12" md="3">Haben Sie Augenkrankheiten?</v-col>
        <v-col cols="12" md="3"
          ><b class="blue--text" v-if="berechnung.ber_brille_krankheit == 1"
            >Ja</b
          >
          <b class="blue--text" v-if="berechnung.ber_brille_krankheit != 1"
            >Nein</b
          >
        </v-col>
        <v-col cols="12" md="2"><b class="text-green">Antrag möglich</b></v-col>
        <v-col cols="12" md="4"
          >Bestehende Augenkrankheiten können zu Antragsablehnungen
          führen.</v-col
        >
      </v-row>
    </span>
    <span v-if="berechnung.ber_segment == 3 || berechnung.ber_segment == 4">
      <v-row>
        <v-col cols="12" md="3">Vorerkrankungen</v-col>
        <v-col cols="12" md="3"
          ><b class="blue--text" v-if="berechnung.ber_top_gesund == 1"
            >keine schwereren Vorerkrankungen</b
          >
          <b class="blue--text" v-if="berechnung.ber_top_gesund != 1"
            >Ja, Vorerkrankungen vorhanden</b
          >
        </v-col>
        <v-col cols="12" md="2"><b class="text-green">Antrag möglich</b></v-col>
        <v-col cols="12" md="4"
          >Einige Versicherungen lehnen einen Antrag bei Vorerkrankungen
          ab.</v-col
        >
      </v-row>
    </span>
    <span v-if="berechnung.ber_segment <= 1">
      <v-row>
        <v-col cols="12" md="3">Aufbiss-Schiene</v-col>
        <v-col cols="12" md="3"
          ><b class="blue--text" v-if="berechnung.ber_aufbiss == 1"
            >Ja, vorhanden</b
          >
          <b class="blue--text" v-if="berechnung.ber_aufbiss == 0"
            >Nein, nicht vorhanden</b
          >
        </v-col>
        <v-col cols="12" md="2"><b class="text-green">Antrag möglich</b></v-col>
        <v-col cols="12" md="4"
          >Einige Versicherungen lehnen einen Antrag bei vorhandenenen
          Aufbiss-Schiene ab.</v-col
        >
      </v-row>

      <!-- ENDE -->
      <v-row>
        <v-col cols="12" md="3">Laufende Behandlung</v-col>
        <v-col cols="12" md="3"
          ><b class="blue--text" v-if="berechnung.ber_zahnmass == 1"
            >Ja, laufende oder angeratene Behandlung</b
          >
          <b class="blue--text" v-if="berechnung.ber_zahnmass == 0">Nein</b>
        </v-col>
        <v-col cols="12" md="2"><b class="text-green">Antrag möglich</b></v-col>
        <v-col cols="12" md="4"
          >Bei laufenden oder angeratenen Behandlung lehnen manche
          Versicherungen den Antrag ab.
        </v-col>
      </v-row>

      <!-- ENDE -->
      <v-row>
        <v-col cols="12" md="3">Parodontosebehandlung</v-col>
        <v-col cols="12" md="3"
          ><b class="blue--text" v-if="berechnung.ber_zb_3 == 1"
            >Ja, laufende Parodontosebehandlung</b
          >
          <b class="blue--text" v-if="berechnung.ber_zb_3 == 0">Nein</b>
        </v-col>
        <v-col cols="12" md="2"><b class="text-green">Antrag möglich</b></v-col>
        <v-col cols="12" md="4"
          >Bei vorandenen Parodontosebehandlungen lehnen viele Gesellschaften
          einen Antrag ab.</v-col
        >
      </v-row>

      <!-- ENDE -->
      <v-row>
        <v-col cols="12" md="3">Zahnfehlstellung</v-col>
        <v-col cols="12" md="3"
          ><b class="blue--text" v-if="berechnung.ber_zahnfehl == 1"
            >Ja, Fehlstellung vorhanden</b
          >
          <b class="blue--text" v-if="berechnung.ber_zahnfehl == 0">Nein</b>
        </v-col>
        <v-col cols="12" md="2"><b class="text-green">Antrag möglich</b></v-col>
        <v-col cols="12" md="4"
          >Bei einer vorhandenen Zahnfehlstellung ist eine Versicherung bei
          einigen Gesellschaften nicht möglich.</v-col
        >
      </v-row>
      <!-- ENDE -->

      <!-- ENDE -->
      <v-row>
        <v-col cols="12" md="3">Fehlende Zähne</v-col>
        <v-col cols="12" md="3"
          ><b class="blue--text" v-if="berechnung.ber_ze_ja == 1"
            >Ja, fehlende Zähne</b
          >
          <b class="blue--text" v-if="berechnung.ber_ze_ja == 0">Nein</b>
        </v-col>
        <v-col cols="12" md="2"><b class="text-green">Antrag möglich</b></v-col>
        <v-col cols="12" md="4"
          >Bei vorhandenen Lücken ist eine Ablehnung des Antrages bei einigen
          Versicherungen möglich.</v-col
        >
      </v-row>

      <!-- ENDE -->
      <v-row>
        <v-col cols="12" md="3">Anzahl Lücken</v-col>
        <v-col cols="12" md="3"
          ><b class="blue--text">{{ berechnung.ber_luecke }}</b>
        </v-col>
        <v-col cols="12" md="2"><b class="text-green">Antrag möglich</b></v-col>
        <v-col cols="12" md="4"
          >Einige Versicherungen lehnen einen Antrag bei zuvielen Lücken
          ab.</v-col
        >
      </v-row>
      <!-- ENDE -->
      <v-row>
        <v-col cols="12" md="3">Mitversicherung fehlender Zähne</v-col>
        <v-col cols="12" md="3"
          ><b v-if="berechnung.ber_luecke_vers == 1"
            >Ja, fehlende Zähne sollen mitversichert werden</b
          >
          <b class="blue--text" v-if="berechnung.ber_luecke_vers == 0">Nein</b>
        </v-col>
        <v-col cols="12" md="2"><b class="text-green">Antrag möglich</b></v-col>
        <v-col cols="12" md="4"
          >Bei den meisten Versicherungen ist eine Versicherung von fehlenden
          Zähnen nicht möglich.
        </v-col>
      </v-row>

      <!-- ENDE -->
      <v-row>
        <v-col cols="12" md="3">Anzahl Prothesen</v-col>
        <v-col cols="12" md="3"
          ><b class="blue--text">{{ berechnung.ber_zahnersetzt }}</b>
        </v-col>
        <v-col cols="12" md="2"><b class="text-green">Antrag möglich</b></v-col>
        <v-col cols="12" md="4"
          >Einige Versicherungen lehnen einen Antrag bei zuvielen Prothesen
          ab.</v-col
        >
      </v-row>

      <!-- ENDE -->
      <v-row>
        <v-col cols="12" md="3">Anzahl Kronen</v-col>
        <v-col cols="12" md="3"
          ><b class="blue--text">{{ berechnung.ber_kronen }}</b>
        </v-col>
        <v-col cols="12" md="2"><b class="text-green">Antrag möglich</b></v-col>
        <v-col cols="12" md="4"
          >Einige Versicherungen lehnen einen Antrag bei zuvielen Kronen
          ab.</v-col
        >
      </v-row>

      <!-- ENDE -->
      <v-row>
        <v-col cols="12" md="3">Anzahl Brücken</v-col>
        <v-col cols="12" md="3"
          ><b class="blue--text">{{ berechnung.ber_bruecke }}</b>
        </v-col>
        <v-col cols="12" md="2"><b class="text-green">Antrag möglich</b></v-col>
        <v-col cols="12" md="4"
          >Einige Versicherungen lehnen einen Antrag bei Brücken Lücken
          ab.</v-col
        >
      </v-row>
    </span>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
