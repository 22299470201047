<template>
  <div>
    <v-row class="my-0" style="background-color: #f5f5f5">
      <v-col cols="12"
        ><h3>
          Ihre Zahnzusatzversicherung:
          <a @click="berechnungStore.setShowPop(tarif, 'dreivergleich')"
            >{{ tarif.un_name }} {{ tarif.tarif_title }} im Vergleich
          </a>
        </h3>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
