<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <h2 class="title text-red">
          Sie haben bereits einen Antrag & Infopaket für diese Berechnung
          angefordert
        </h2>
        Wir werden Ihnen das Infopaket so schnell wie möglich zusenden. Wenn Sie
        noch einen weiteren Antrag & Infopaket anfordern wollen,
        <router-link to="/zusatzversicherung"
          >klicken Sie bitte hier...</router-link
        >
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  const props = defineProps({
    tarif: {
      type: Object,
    },
  });
  console.log(props.tarif);
</script>
