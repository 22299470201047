<template>
  <div>
    <v-row>
      <v-col cols="3" class="mt-1 py-1">
        <strong class="text-green">Sofortleistung</strong><br />
        für angeratene und laufende Behandlungen
      </v-col>
      <v-col cols="9" class="mt-0 py-1">
        {{ tarif.sofort_text }}
        <a @click="berechnungStore.setShowPop(tarif, 'sofortpop')"
          >mehr Infos zur Sofort-Leistung</a
        >
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
