<template>
  <div>
    <span v-if="!sharedDataStore.isMobile()">
      <h2 v-if="tarif.empfehlung_type == 3">
        Beste Leistung <sup class="text-green">Tipp</sup>
      </h2>
      <h2 v-if="tarif.empfehlung_type == 2 || tarif.empfehlung_type == 1">
        Sehr gute Alternative <sup class="text-green">Tipp</sup>
      </h2>

      <v-divider
        v-if="tarif.empfehlung_type > 0"
        :thickness="4"
        :color="sharedDataStore.showBtnColoBer(berechnung.ber_segment)"
      ></v-divider>
    </span>

    <ul>
      <span v-if="sharedDataStore.isMobile()">
        <li v-if="tarif.empfehlung_type == 3" class="text-green">
          <strong style="font-size: 16px">Empfehlung beste Leistung</strong>
        </li>
        <li
          v-if="tarif.empfehlung_type == 2 || tarif.empfehlung_type == 1"
          class="text-green"
        >
          <strong style="font-size: 16px">Sehr gute Alternative</strong>
        </li>
      </span>
      <li
        v-for="(item, index) in truncateLog(tarif.log, tarif.empfehlung_type)"
        v-bind:key="index"
      >
        <span v-html="nl2br(item.value)"></span>

        <span v-if="item.info_pop == 'altersKhPop'"
          ><a @click="berechnungStore.setShowPopApp('altersrueck')"
            >.&nbsp;Wieso ist das wichtig?</a
          ></span
        >
        <span v-if="tarif.sofortversichert && item.info_pop == 'sofort'"
          ><a @click="berechnungStore.setShowPop(tarif, 'sofortpop')"
            >mehr Infos dazu</a
          >
        </span>
      </li>
    </ul>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const berechnungStore = useBerechnungStore();
  import { useNl2Br } from "@/composable/useNl2Br";
  const { nl2br } = useNl2Br();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    showMobile: {
      type: Boolean,
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });

  const truncateLog = (log, empfehlungType) => {
    const filteredLog = log.filter((entry) => entry.emp_only === 0);
    if (empfehlungType === 0 && log.length > 3) {
      // Von dem gefilterten Array die ersten 3 Elemente zurückgeben
      return filteredLog.slice(0, 3);
    }
    if (empfehlungType > 0) {
      return log.filter((entry) => entry.noShowEmpf === 0);
    }

    return log;
  };

  console.log(props.tarif);
</script>

<style scoped>
  .noUnderline {
    text-decoration: none !important;
  }
</style>
