<template>
  <v-container>
    <h2>1. Antragsteller (Versicherungsnehmer):</h2>
    <v-form ref="antragform" @submit.prevent="submitPersonal">
      <v-container>
        <v-row>
          <v-col cols="12" md="3">
            <strong>
              <span v-if="!sharedDataStore.isMobile()">1.</span>Ihr Geburtstag
            </strong>
          </v-col>
          <v-col cols="12" md="9">
            <input-geburtstag-select
              @update:year="(year) => handleBirthdayChange('vn', 'jahra', year)"
              @update:month="
                (month) => handleBirthdayChange('vn', 'monata', month)
              "
              @update:day="(day) => handleBirthdayChange('vn', 'taga', day)"
              :year="antragdata.jahra"
              :month="antragdata.monata"
              :day="antragdata.taga"
              :componentKeyStatus="componentKeyStatus"
            />
            <span v-if="berechnung.ber_alter < 18">
              Bitte Sie <b class="text-green">Ihren</b> Geburtstag hier. Den
              Geburtstag des <b>zu versichernden Kindes</b> (unter 18 Jahre),
              geben Sie bitte weiter unten an. Bei: "<b
                >Daten der versicherten Person</b
              >".
            </span>
          </v-col>
        </v-row>
        <v-row v-if="fehlerGeburtstagBerechnungAntragsteller">
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="9">
            <b class="text-red">
              Achtung - das Alter stimmt nicht mit Ihrer Berechnung überein </b
            ><br />
            Bitte wiederholen Sie die Berechnung mit dem richtigen Geburtsdatum
            oder geben Sie das korrekte Geburtsdatum ein.
          </v-col>
        </v-row>

        <v-row v-if="fehlerGeburtstag">
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="9">
            <b class="red--text"> Achtung - bitte ändern Sie das Datum: </b
            ><br />
            Bitte geben Sie hier den Geburtstag des Versicherungsnehmers ein.
            Nicht das der "Versicherten Person".
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col
            v-if="!sharedDataStore.isMobile()"
            cols="12"
            md="2"
            class="pt-4"
          >
            <strong>2. Geschlecht</strong>
          </v-col>
          <v-col cols="12" md="4">
            <v-radio-group v-model="antragdata.geschlecht" class="mt-0" row>
              <v-row>
                <v-col cols="6">
                  <v-radio :value="0" label="Frau" />
                </v-col>
                <v-col cols="6">
                  <v-radio :value="1" label="Mann" />
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="antragdata.staat"
              class="white"
              type="text"
              :rules="[checkTextFeld('staat')]"
            >
              <template v-slot:label>
                <strong>Staatsangehörigkeit</strong>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="12" md="2">
            <strong v-if="!sharedDataStore.isMobile()">3. Name</strong>
          </v-col>
          <v-col cols="12" md="4" class="pr-4">
            <v-text-field
              class="mt-0 pt-0 white"
              v-model="antragdata.antragstellerv"
              type="text"
              label="Vorname"
              :rules="[checkTextFeld('antragstellerv')]"
              :append-icon="getIconSuccessText('antragstellerv')"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              class="mt-0 pt-0 white"
              v-model="antragdata.antragsteller"
              type="text"
              label="Nachname"
              :rules="[checkTextFeld('antragsteller')]"
              :append-icon="getIconSuccessText('antragsteller')"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="12" md="2">
            <strong v-if="!sharedDataStore.isMobile()">4. Adresse</strong>
          </v-col>
          <v-col cols="12" md="7">
            <v-text-field
              class="mt-0 pt-0 white"
              v-model="antragdata.strasse"
              type="text"
              label="Strasse"
              :rules="[checkTextFeld('strasse')]"
              :append-icon="getIconSuccessText('strasse')"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              class="mt-0 pt-0 white"
              v-model="antragdata.hausnummer"
              type="text"
              label="Hausnummer"
              :rules="[checkTextFeld('hausnummer')]"
              :append-icon="getIconSuccessText('hausnummer')"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="2" class="hidden-sm-and-down"></v-col>
          <v-col cols="12" md="3">
            <v-text-field
              class="mt-0 pt-0"
              v-model="antragdata.plz"
              type="text"
              label="PLZ"
              :rules="[checkTextFeld('plz')]"
              :append-icon="getIconSuccessText('plz')"
              validate-on-blur
            />
          </v-col>
          <v-col cols="1" class="hidden-sm-and-down"></v-col>
          <v-col cols="12" md="6">
            <v-text-field
              class="mt-0 pt-0"
              v-model="antragdata.ort"
              type="text"
              label="Wohnort"
              :rules="[checkTextFeld('ort')]"
              :append-icon="getIconSuccessText('ort')"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="12" md="2">
            <strong v-if="!sharedDataStore.isMobile()">5. E-Mail</strong>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field
              class="mt-0 pt-0"
              v-model="antragdata.email"
              type="text"
              label="E-Mail"
              :rules="[validateEmail(antragdata.email)]"
              validate-on-blur
              :append-icon="getIconSuccess()"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="12" md="2">
            <strong v-if="!sharedDataStore.isMobile()">6. Handy</strong>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field
              class="mt-0 pt-0"
              v-model="antragdata.tel_handy"
              type="text"
              label="Handy-Nummer"
              :rules="[checkTextFeld('tel_handy')]"
              :append-icon="getIconSuccessText('tel_handy')"
              validate-on-blur
            >
            </v-text-field>
            <span class="caption pt-0">
              Bitte geben Sie Ihre Handy-Nummer an. Wir rufen Sie nur im
              absoluten Notfall an z.B. bei wichtigen Rückfragen. Niemals zu
              Werbezwecken!
            </span>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="12" md="2">
            <strong>
              <span v-if="!sharedDataStore.isMobile()">7.</span>Beruf
            </strong>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              class="mt-0 pt-0"
              label="Status Beruf"
              v-model="antragdata.beruf"
              :items="beruf"
              item-value="id"
              item-title="name"
              single-line
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              class="mt-0 pt-0"
              v-model="antragdata.berufb"
              type="text"
              label="Berufsbezeichung"
              :rules="[checkTextFeld('berufb')]"
              :append-icon="getIconSuccessText('berufb')"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-divider :thickness="4" color="blue"></v-divider>
      <div v-if="tarif.tarif_only_vn == 0" class="mt-5">
        <h2>1.2. Wen möchten Sie versichern?</h2>
        <v-container>
          <v-row>
            <v-col>
              <v-radio-group v-model="antragdata.person">
                <v-radio :value="1" label="sich selbst" />
                <v-radio
                  @click="changeVpDefault"
                  :value="2"
                  label="andere Person (z.B. Ihr Kind, Ihre Ehefrau...)"
                />
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-divider :thickness="4" color="blue"></v-divider>
      <!--  person 2 angaben --->
      <div
        v-if="antragdata.person == 2 && tarif.tarif_only_vn == 0"
        class="mt-10"
      >
        <h2>Daten der versicherten Person z.B. Kind, Ehepartner</h2>
        <v-row>
          <v-col cols="12" md="12">
            Diese Felder bitte nur ausfüllen wenn der Antragsteller eine
            <strong>andere Person</strong> als der Versicherungsnehmer ist. Also
            die Person für die der Versicherungsschutz, gelten soll.
            <strong class="text-green"
              >Kinder und Personen unter 18 Jahren bitte hier eintragen:</strong
            >
          </v-col>
        </v-row>

        <v-container>
          <v-row>
            <v-col cols="3" v-if="!sharedDataStore.isMobile()">
              <strong>
                <span>1.</span>Geburtstag der versicherten Person
              </strong>
            </v-col>
            <v-col cols="7">
              <input-geburtstag-select
                @update:year="
                  (year) => handleBirthdayChange('VP', 'jahrv', year)
                "
                @update:month="
                  (month) => handleBirthdayChange('VP', 'monatv', month)
                "
                @update:day="(day) => handleBirthdayChange('VO', 'tagv', day)"
                :year="antragdata.jahrv"
                :month="antragdata.monatv"
                :day="antragdata.tagv"
                :componentKeyStatus="componentKeyStatus"
              />
            </v-col>
          </v-row>

          <v-row v-if="fehlerGeburtstagBerechnungVersicherungsnehmer">
            <v-col cols="12" md="3"></v-col>
            <v-col cols="12" md="9">
              <b class="text-red">
                Achtung - das Alter stimmt nicht mit Ihrer Berechnung überein </b
              ><br />
              Bitte wiederholen Sie die Berechnung mit dem richtigen
              Geburtsdatum oder geben Sie das korrekte Geburtsdatum ein.
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col
              v-if="!sharedDataStore.isMobile()"
              cols="12"
              md="2"
              class="pt-4"
            >
              <strong>2. Geschlecht</strong>
            </v-col>
            <v-col cols="12" md="4">
              <v-radio-group
                class="mt-0 pt-0"
                v-model="antragdata.geschlechtv"
                row
              >
                <v-row>
                  <v-col cols="6">
                    <v-radio :value="0" label="Frau" />
                  </v-col>
                  <v-col cols="6">
                    <v-radio :value="1" label="Mann" />
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="antragdata.vstaat"
                class="white"
                type="text"
                :rules="[checkTextFeldVP('vstaat')]"
              >
                <template v-slot:label>
                  <strong>Staatsangehörigkeit</strong>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col cols="12" md="2">
              <strong v-if="!sharedDataStore.isMobile()">3. Name</strong>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                class="mt-0 pt-0 white"
                v-model="antragdata.vversicherungsnehmer"
                type="text"
                label="Vorname"
                :rules="[checkTextFeldVP('vversicherungsnehmer')]"
                :append-icon="getIconSuccessTextVP('vversicherungsnehmer')"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                class="mt-0 pt-0 white"
                v-model="antragdata.versicherungsnehmer"
                type="text"
                label="Nachname"
                :rules="[checkTextFeldVP('versicherungsnehmer')]"
                :append-icon="getIconSuccessTextVP('versicherungsnehmer')"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col cols="12" md="2">
              <strong>
                <span v-if="!sharedDataStore.isMobile()">4.</span>Beruf
              </strong>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                class="mt-0 pt-0 white"
                label="Status Beruf"
                v-model="antragdata.vberuf"
                :items="beruf"
                item-value="id"
                item-title="name"
                single-line
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                class="mt-0 pt-0 white"
                v-model="antragdata.berufbv"
                type="text"
                label="Berufsbezeichung"
                :rules="[checkTextFeldVP('berufbv')]"
                :append-icon="getIconSuccessTextVP('berufbv')"
              >
              </v-text-field>
              <span class="caption">
                Bitte geben Sie die Berufsbezeichnung der zu versichernden
                Person an, z.B. Schüler, Student, Hausfrau oder Bezeichnung des
                ausgeübten Berufes. Bei Kindern geben Sie hier einfach "Kind"
                ein.
              </span>
            </v-col>
          </v-row>
        </v-container>
        <v-divider :thickness="4" olor="blue"></v-divider>
      </div>

      <div class="mt-4">
        <h2>
          Angaben zur Krankenkasse
          <span class="text-green">(der versicherten Person)</span>
        </h2>
        <v-container>
          <v-row>
            <v-col cols="12" md="8">
              <v-text-field
                class="mt-0 pt-0 white"
                v-model="antragdata.kvgesetzlich"
                type="text"
                label="Krankenkasse der versicherten Person"
                :rules="[checkTextFeld('kvgesetzlich')]"
                :append-icon="getIconSuccessText('kvgesetzlich')"
              >
              </v-text-field>
              <span class="caption pt-0">
                Hinweis: BKK alleine ist KEINE Krankenkasse. Bitte vollständige
                Bezeichnung angeben.
              </span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                class="mt-0 pt-0 white"
                v-model="antragdata.versseit"
                type="number"
                label="Seit wann?"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <strong>Versicherungsstatus:</strong>
              <v-select
                class="mt-0 pt-0 white"
                label="Versicherungsstatus"
                v-model="antragdata.versstatus"
                :items="versicherungsStati"
                item-value="id"
                item-title="name"
                single-line
              ></v-select>
            </v-col>
            <v-col cols="12" md="8">
              Haben Sie eine ältere Zusatzversicherung
              <strong>mit Zahnleistungen</strong>, die Sie langfristig
              <strong>zusätzlich behalten</strong> wollen?
              <v-radio-group class="mt-0 pt-0" v-model="antragdata.privat" row>
                <v-row>
                  <v-col cols="6">
                    <v-radio :value="2" label="Nein." />
                  </v-col>
                  <v-col cols="6">
                    <v-radio :value="1" label="Ja." />
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row
            v-if="
              antragdata.privat == 1 && tarif.tarif_int_doppelversicherung == 2
            "
          >
            <v-col cols="12">
              Bei der <strong>{{ tarif.un_name }}</strong> müssen Sie alle
              bestehenden Zusatzversicherungen,
              <strong>die auch für Zähne leisten</strong>,
              <strong class="red--text">kündigen</strong> (sog.
              Doppelversicherungsverbot).
            </v-col>
          </v-row>
        </v-container>
        <v-divider :thickness="4" class="my-4" color="blue"></v-divider>
        <h2>Bitte prüfen Sie nochmal kurz Ihre Kontaktdaten</h2>
        <v-container>
          <v-row>
            <v-col cols="12" md="6" class="pr-4">
              <v-text-field
                class="mt-0 pt-0 white"
                v-model="antragdata.antragstellerv"
                type="text"
                label="Vorname"
                :rules="[checkTextFeld('antragstellerv')]"
                :append-icon="getIconSuccessText('antragstellerv')"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                class="mt-0 pt-0 white"
                v-model="antragdata.antragsteller"
                type="text"
                label="Nachname"
                :rules="[checkTextFeld('antragsteller')]"
                :append-icon="getIconSuccessText('antragsteller')"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row>
            <v-col cols="12" md="8">
              <v-text-field
                class="mt-0 pt-0"
                v-model="antragdata.email"
                type="text"
                label="E-Mail"
                :rules="[validateEmail(antragdata.email)]"
                validate-on-blur
                :append-icon="getIconSuccess()"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-row>
          <v-col cols="12" md="10">
            <div v-if="isAntragError" class="my-4">
              <h3 class="text-orange">
                Bitte verbessern Sie noch ein paar Kleinigkeiten
              </h3>
              Bitte prüfen Sie noch Ihre Angaben der
              <b class="text-red">rot-markierten</b> Felder.
            </div>
            <v-row v-if="insertStarted">
              <v-col cols="12" md="2">
                <v-progress-circular
                  :size="50"
                  color="green"
                  indeterminate
                ></v-progress-circular
              ></v-col>
              <v-col cols="12" md="10">
                <h2 class="text-green">
                  Bitte haben Sie noch ein wenig Geduld
                </h2></v-col
              >
            </v-row>
            <v-btn
              v-if="!insertStarted"
              @click="submitPersonal"
              color="blue"
              style="height: 48px"
              elevated
              tile
              rounded="0"
              type="submit"
              class="button_caps"
              tonal
              ><span style="font-size: 24px" v-if="!sharedDataStore.isMobile()"
                >jetzt kostenlos Infopaket anfordern
              </span>
              <span style="font-size: 24px" v-if="sharedDataStore.isMobile()"
                >Jetzt anfordern
              </span>
            </v-btn>

            <br />
            <br />
            <span
              >Wir versenden das Infopaket vorab schon per E-Mail. I.d.R. dauert
              dies nur wenige Stunden. Zusätzlich erhalten Sie die Unterlagen
              noch per Post.<br />
            </span>
          </v-col>
          <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()"
            ><v-icon class="text-green">mdi-lock</v-icon> Ihre Anforderung ist
            SSL geschützt.</v-col
          >
        </v-row>
      </div>
    </v-form>
  </v-container>
</template>

<script setup>
  import {
    ref,
    getCurrentInstance,
    defineProps,
    reactive,
    watchEffect,
    defineEmits,
    watch,
  } from "vue";
  import InputGeburtstagSelect from "@/components/berechnung/form/InputFormGeburtstagSelect";
  import { useSharedDataStore } from "@/stores/sharedData";
  import { useLoaderStore } from "@/stores/loaderStore";
  const loaderStore = useLoaderStore();

  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const emit = defineEmits(["updateAntrag"]);

  const props = defineProps({
    berechnung: {
      type: Object,
    },
    tarif: {
      type: Object,
    },
  });
  const componentKeyStatus = ref(0);
  //   const vorTypen = reactive([
  //     { id: "Keine_Vorversicherung", name: "Keine Vorversicherung" },
  //     { id: "Zahnzusatz", name: "Zahnzusatzversicherung" },
  //     { id: "Stationaere_Zusatz", name: "Stationäre Zusatzversicherung" },
  //     {
  //       id: "Voll_Krankenversicherung",
  //       name: "private Voll-Krankenversicherung",
  //     },
  //     { id: "Auslands-Krankenzusatz", name: "Auslandskrankenversicherung" },
  //     { id: "Heilpraktiker-Zusatz", name: "Heilpraktiker-Zusatzversicherung" },
  //   ]);
  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }
  const versicherungsStati = reactive([
    { id: "2", name: "pflichtversichert" },
    { id: "1", name: "freiwillig versichert" },
    { id: "3", name: "familienversichert als Ehepartner" },
    { id: "4", name: "familienversichert als Kind" },
  ]);
  const beruf = reactive([
    { id: "1", name: "angestellt" },
    { id: "2", name: "selbständig" },
    { id: "7", name: "Schüler" },
    { id: "11", name: "Kind" },
    { id: "10", name: "Auszubildender" },
    { id: "3", name: "Student" },
    { id: "4", name: "Beamte/r" },
    { id: "5", name: "angestellt im öffentlichen Dienst" },
    { id: "6", name: "Hausmann/Hausfrau" },
    { id: "8", name: "Rentner" },
    { id: "9", name: "keine Erwerbstätigkeit" },
  ]);

  const fehlerGeburtstagBerechnungAntragsteller = ref(false);
  const fehlerGeburtstagBerechnungVersicherungsnehmer = ref(false);

  const antragform = ref();
  const fehlerGeburtstag = ref(false);
  const insertStarted = ref(false);
  const antragdata = ref({
    startInsert: false,
    vorvers_t: "Keine_Vorversicherung",
    wechsel_zzv: 2,
    person: 1,
    privat: 2,
    abschluss_email: 2,
    antragstellerv: "",
    antragsteller: "",
    strasse: "",
    hausnummer: "",
    plz: "",
    ort: "",
    staat: "deutsch",
    vstaat: "deutsch",
    email: "",
    arbeitgeber: "",
    varbeitgeber: "",
    tel_handy: "",
    beruf: "1",
    vberuf: "7",
    berufb: "",
    berufbv: "",
    geschlechtv: 1,
    geschlecht: 0,
    vversicherungsnehmer: "",
    versicherungsnehmer: "",
    versseit: "2020",
    versstatus: "2",
    vorvers: "",
    bemerkung: "",
    wechsel_detail: "",
    taga: 12,
    monata: 6,
    jahra: 1984,
    tagv: 12,
    monatv: 6,
    jahrv: 2014,
    kvgesetzlich: "",
  });

  const isAntragError = ref(false);

  function checkVNFormular() {
    let fields = [
      "antragstellerv",
      "antragsteller",
      "strasse",
      "ort",
      "tel_handy",
      "staat",
      "berufb",
      "kvgesetzlich",
      "plz",
    ];

    // Stellen Sie sicher, dass antragdata.value existiert und korrekt strukturiert ist
    if (!antragdata.value || typeof antragdata.value !== "object") {
      console.error("Fehler: antragdata.value ist nicht korrekt definiert.");
      return false;
    }

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      let validateFunction = checkTextFeld(field);
      // Annahme: antragdata.value[field] ist der Wert, den Sie überprüfen möchten
      let errorField = validateFunction(antragdata.value[field]);

      // Logik, um festzustellen, ob ein Fehler zurückgegeben wurde
      if (errorField !== true) {
        // Angenommen, checkTextFeld gibt 'true' zurück, wenn kein Fehler vorliegt
        console.log("Fehler im Feld: " + field + " - " + errorField);

        return false;
      }
    }

    // Alle Felder sind gültig
    isAntragError.value = false;
    return true;
  }

  function checkVPFormular() {
    let fields = [
      "berufbv",
      "vstaat",
      "versicherungsnehmer",
      "vversicherungsnehmer",
    ];
    // Prüft jedes Feld auf Gültigkeit und gibt false zurück, wenn ein Feld ungültig ist
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      let validateFunction = checkTextFeldVP(field);
      let fieldValue = antragdata.value[field]; // Zugriff auf den Wert des Feldes in antragdata
      let errorField = validateFunction(fieldValue); // Prüft den Wert des Feldes

      if (errorField !== true) {
        // Wenn das Ergebnis der Validierung nicht 'true' ist (d.h. wenn ein Fehler vorliegt)
        console.log(`Fehler im Feld '${field}': ${errorField}`); // Ausgabe des Fehlers in der Konsole // Setzt den Fehlerindikator auf true
        return false; // Beendet die Funktion frühzeitig, wenn ein Feld ungültig ist
      }
    }

    // Keine Fehler gefunden, Fehlerindikator zurücksetzen
    return true; // Gibt true zurück, um zu signalisieren, dass alle Felder gültig sind
  }

  function checkGeburtstagBerechnungFormular() {
    if (
      antragdata.value.person == 1 &&
      props.berechnung.ber_jahr != antragdata.value.jahra
    ) {
      fehlerGeburtstagBerechnungAntragsteller.value = true;
      return false;
    }

    if (
      antragdata.value.person == 2 &&
      props.berechnung.ber_jahr != antragdata.value.jahrv
    ) {
      fehlerGeburtstagBerechnungVersicherungsnehmer.value = true;
      return false;
    }
    return true;
  }

  async function submitPersonal() {
    if (!checkGeburtstagBerechnungFormular()) {
      isAntragError.value = true;
      console.log("error berechnungen stimmen nicht überein");
      return false;
    }

    if (!checkVNFormular()) {
      isAntragError.value = true;
      console.log("error vn form");
      return false;
    }

    if (antragdata.value.person == 2) {
      if (!checkVPFormular()) {
        isAntragError.value = true;
        console.log("error vp form");
        return false;
      }
    }

    if (!isAntragError.value) {
      if (!insertStarted.value) {
        insertStarted.value = true;
        setLoading(true);
        antragdata.value.startInsert = true;
        emit("updateAntrag", antragdata.value);
      }
    }
  }

  function changeVpDefault() {
    console.log("changeVpDefault: " + antragdata.value.person);
    antragdata.value.tagv = parseInt(props.berechnung.ber_tag);
    antragdata.value.monatv = parseInt(props.berechnung.ber_monat);
    antragdata.value.jahrv = parseInt(props.berechnung.ber_jahr);
    antragdata.value.geschlechtv = parseInt(props.berechnung.ber_geschlecht);
  }

  function setDefaultValue() {
    console.log("default: " + props.berechnung.ber_id);
    antragdata.value.age1 = props.berechnung.ber_alter;
    if (props.berechnung.ber_alter >= 18) {
      antragdata.value.taga = parseInt(props.berechnung.ber_tag);
      antragdata.value.monata = parseInt(props.berechnung.ber_monat);
      antragdata.value.jahra = parseInt(props.berechnung.ber_jahr);
      antragdata.value.geschlecht = parseInt(props.berechnung.ber_geschlecht);
    }
    if (props.berechnung.ber_alter < 18) {
      antragdata.value.taga = 15;
      antragdata.value.monata = 6;
      antragdata.value.jahra = parseInt(props.berechnung.ber_jahr) - 27;
      antragdata.value.geschlecht = parseInt(props.berechnung.ber_geschlecht);

      antragdata.value.tagv = parseInt(props.berechnung.ber_tag);
      antragdata.value.monatv = parseInt(props.berechnung.ber_monat);
      antragdata.value.jahrv = parseInt(props.berechnung.ber_jahr);
      antragdata.value.geschlechtv = parseInt(props.berechnung.ber_geschlecht);
      antragdata.value.person = 2;
    }
    componentKeyStatus.value += 1;
  }

  function getIconSuccess() {
    let check = validateEmail(antragdata.value.email);
    console.log("checkEmail" + check);
    if (check !== true) {
      return "mdi-alert-circle";
    }
    return "mdi-check-bold";
  }
  const getIconSuccessText = (feld) => {
    let validateFunction = checkTextFeld(feld);
    let errorField = validateFunction(antragdata.value[feld]);
    if (errorField !== true) {
      return "mdi-alert-circle";
    }
    return "mdi-check-bold";
  };

  const getIconSuccessTextVP = (feld) => {
    let validateFunction = checkTextFeldVP(feld);
    let errorField = validateFunction(antragdata.value[feld]);
    if (errorField !== true) {
      return "mdi-alert-circle ";
    }
    return "mdi-check-bold";
  };

  const handleBirthdayChange = (vers, type, value) => {
    fehlerGeburtstagBerechnungVersicherungsnehmer.value = false;
    fehlerGeburtstagBerechnungAntragsteller.value = false;

    console.log("change:  " + type + " value " + value);
    antragdata.value[type] = value;

    if (type.includes("VP")) {
      handleBirthdayChangedAntrVp();
    } else {
      handleBirthdayChangedAntr();
    }
  };

  const handleBirthdayChangedAntrVp = () => {
    fehlerGeburtstag.value = false;
    calcAge("vp");
  };

  const handleBirthdayChangedAntr = () => {
    fehlerGeburtstag.value = false;
    calcAge("antr");
  };

  const calcAge = (type) => {
    const day = type === "vp" ? antragdata.value.tagv : antragdata.value.taga;
    const month =
      type === "vp" ? antragdata.value.monatv : antragdata.value.monata;
    const year =
      type === "vp" ? antragdata.value.jahrv : antragdata.value.jahra;
    antragdata.value.age1 = proxy.$calcAge(day, month, year);
  };

  const checkTextFeldVP = (feld) => {
    return (value) => {
      if (antragdata.value.person === 2) {
        switch (feld) {
          case "vversicherungsnehmer":
            if (!value || value.trim() === "") {
              return "Bitte geben Sie den Vornamen der zu versichernden Person an.";
            }
            break;
          case "versicherungsnehmer":
            if (!value || value.trim() === "") {
              return "Bitte geben Sie den Nachnamen der zu versichernden Person an.";
            }
            break;
          case "vstaat":
            if (!value || value.trim() === "") {
              return "Bitte geben Sie die Staatsangehörigkeit an.";
            }
            break;
          case "berufbv":
            if (!value || value.trim() === "") {
              return "Bitte geben Sie den Beruf bzw. Tätigkeit an.";
            }
            break;
          default:
            return true;
        }
      }
      return true;
    };
  };

  const checkTextFeld = (feld) => {
    return (value) => {
      if (feld === "plz") {
        if (
          value.length !== 5 ||
          !/^\d+$/.test(value) ||
          value.trim() === "" ||
          !value
        ) {
          console.log(
            `Fehler in Feld '${feld}': Bitte geben Sie Ihre Postleitzahl 5-stellig an.`
          );
          return "Bitte geben Sie Ihre Postleitzahl 5-stellig an.";
        }

        return true;
      }

      if (!value || value.trim() === "") {
        console.log("check feld " + feld + "- " + value);
        let error = "";
        switch (feld) {
          case "antragstellerv":
            error = "Bitte geben Sie Ihren Vornamen an.";
            break;
          case "antragsteller":
            error = "Bitte geben Sie Ihren Nachnamen an.";
            break;
          case "strasse":
            error = "Bitte geben Sie Ihre Straße an.";
            break;
          case "hausnummer":
            error = "Bitte geben Sie Ihre Hausnummer an.";
            break;
          case "ort":
            error = "Bitte geben Sie Ihren Wohnort an.";
            break;
          case "tel_handy":
            error = "Bitte geben Sie Ihre Handy-Nummer an.";
            break;
          case "staat":
            error = "Bitte geben Sie Ihre Staatsangehörigkeit an.";
            break;
          case "berufb":
            error = "Bitte geben Sie Ihren Beruf an.";
            break;
          case "kvgesetzlich":
            error =
              "Bitte geben Sie die Krankenversicherung der versicherten Person an.";
            break;
          default:
            error = true;
            break;
        }
        if (error !== true) {
          console.log(`Fehler in Feld '${feld}': ${error}`);
          return error;
        }
        return true;
      }
      return true;
    };
  };

  const validateEmail = (email) => {
    const result = proxy.$validateEmail(email);
    if (result.isValid) {
      return true;
    } else {
      return result.error;
    }
  };
  watch(
    () => antragdata.value,
    () => {
      // Prüfen, ob isAntragError.value auf true gesetzt ist
      if (isAntragError.value) {
        isAntragError.value = false;
      }
    },
    { deep: true }
  );

  watch(
    () => antragdata.value.age1, // Dies verweist auf die reaktive Referenz `age1`.
    (newAge) => {
      // `newAge` und `oldAge` sind die neuen und alten Werte von `age1`.
      if (newAge < 18) {
        // Verwende `newAge` direkt, anstatt `antragdata.value.age1` erneut abzurufen.
        antragdata.value.person = 2; // Setze `person` auf 2, wenn das Alter unter 18 ist.
      }
    }
  );
  watchEffect(() => {
    setDefaultValue();
  });
</script>
<style>
  .v-icon.mdi-check-bold {
    color: green !important; /* Setzt die Farbe auf Grün und überschreibt bestehende Farbstile */
  }
  .v-icon.mdi-alert-circle {
    color: orange !important; /* Setzt die Farbe auf Grün und überschreibt bestehende Farbstile */
  }
</style>
