<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <h2>
          Laufen gerade zahnärztliche Behandlungen oder sind solche angeraten /
          geplant?
        </h2>
        Wenn Ihr Zahnarzt bereits eine Behandlung begonnen oder eine konkrete
        Behandlung angeraten hat, ist ein Wechsel i.d.R. nicht sinnvoll. In
        diesem Fall würden Sie den Versicherungsschutz für diese Behandlung
        nämlich verlieren. Lassen Sie sich am besten von Ihrem Zahnarzt das
        "Nichtvorliegen" eines zahnärztlichen Behandlungsbedarfes schriftlich
        bestätigen. Lassen Sie dieses von Ihrem Zahnarzt vor einem geplanten
        Wechsel ausfüllen und legen Sie es zu Ihren Unterlagen.
        <br /><br />
        Bitte beachten Sie – das Tragen einer Schiene oder eines Retainers ist
        versicherungsrechtlich so lange als laufende Maßnahme zu werten, bis
        diese/er nicht mehr gebraucht wird und der Zahnarzt die Behandlung als
        komplett erfolgreich abgeschlossen bezeichnen kann.
        <br /><br />
        Sollte eine Zahnbetterkrankung vorliegen (Parodontose, Parodontitis oder
        Ähnliches) ist in den meisten Fällen von einem Wechsel abzusehen.
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
