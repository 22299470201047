<template>
  <div>
    <span style="font-size: 18px"
      >Mit <strong>"Ja"</strong> beantworten, wenn</span
    >
    <br />
    <ul>
      <li>
        Ihr Zahnarzt oder Kieferorthopäde eine Zahn- / Kieferfehlstellung
        bereits <strong>ärztlich diagnostiziert</strong> hat
      </li>
      <li>
        auch wenn die Zahnfehlstellung nur diagnostiziert wurde, und noch keine
        Behandlung empfohlen wurde
      </li>
      <li>
        eine kieferorthopädische Behandlung schon <strong>begonnen</strong> oder
        <strong>konkret empfohlen</strong> wurde
      </li>
      <li>
        <strong class="text-green">Waizmann hilft:</strong> In diesem Fall ist
        eine zukünftige KFO-Behandlung nicht mehr versicherbar. Allerdings
        können Sie trotzdem sehr gute Tarife ohne KFO-Leistungen beantragen.
      </li>
    </ul>
  </div>
</template>

<script></script>

<style scoped></style>
