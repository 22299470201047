<template>
  <div>
    <v-dialog v-model="isLoading">
      <v-card>
        <v-container style="max-width: 1200px">
          <v-row>
            <v-col cols="12" md="12">
              <h2>{{ marketingDataStore.marketingData.companyName }}</h2>
              <h1>
                Anonymer AntragAnnahmecheck |
                <span class="text-green">bitte haben Sie kurz Geduld</span>
              </h1>
              Wir prüfen jetzt <b>anonym</b> die
              <b>Antrags-Annahmebedingungen</b> der Versicherungen ab. Sie
              erfahren sofort welche Zusatzversicherungen Ihren Antrag annehmen
              bzw. ablehnen werden.
            </v-col>
          </v-row>
          <!-- Fortsetzung der Rows für jeden Bedingungstyp -->
          <v-row>
            <v-col cols="12" md="3"><b>Geburtstag</b></v-col>
            <v-col cols="12" md="2"
              ><b class="blue--text"
                >{{ berechnung.ber_tag }}.{{ berechnung.ber_monat }}.{{
                  berechnung.ber_jahr
                }}</b
              ></v-col
            >
            <v-col cols="12" md="2"
              ><b class="text-green">{{ progressBars["bar1"].value }} %</b>
            </v-col>
            <v-col cols="12" md="5" class="pt-4">
              <v-progress-linear
                height="28"
                color="green"
                v-model="progressBars['bar1'].value"
                :buffer-value="progressBars['bar1'].bufferValue"
                ><b class="white--text"
                  >{{ progressBars["bar1"].versicherungen }} Versicherungen
                  geprüft</b
                >
              </v-progress-linear>
            </v-col>
          </v-row>
          <!-- ENDE -->
          <v-row>
            <v-col cols="12" md="3"><b>Aufbiss-Schiene</b></v-col>
            <v-col cols="12" md="2"
              ><b class="blue--text" v-if="berechnung.ber_aufbiss == 1"
                >Ja, vorhanden</b
              >
              <b class="blue--text" v-if="berechnung.ber_aufbiss == 0"
                >Nein, nicht vorhanden</b
              >
            </v-col>
            <v-col cols="12" md="2"
              ><b class="text-green"
                >{{ progressBars["bar2"].value }} %</b
              ></v-col
            >
            <v-col cols="12" md="5" class="pt-4">
              <v-progress-linear
                height="28"
                color="green"
                v-model="progressBars['bar2'].value"
                :buffer-value="progressBars['bar2'].bufferValue"
                ><b class="white--text"
                  >{{ progressBars["bar2"].versicherungen }} Versicherungen
                  geprüft</b
                >
              </v-progress-linear>
            </v-col>
          </v-row>

          <!-- ENDE -->
          <v-row>
            <v-col cols="12" md="3"><b>Laufende Behandlung</b></v-col>
            <v-col cols="12" md="2"
              ><b class="blue--text" v-if="berechnung.ber_zahnmass == 1"
                >Ja, laufende oder angeratene Behandlung</b
              >
              <b class="blue--text" v-if="berechnung.ber_zahnmass == 0">Nein</b>
            </v-col>
            <v-col cols="12" md="2"
              ><b class="text-green"
                >{{ progressBars["bar3"].value }} %</b
              ></v-col
            >
            <v-col cols="12" md="5" class="pt-4">
              <v-progress-linear
                height="28"
                color="green"
                v-model="progressBars['bar3'].value"
                :buffer-value="progressBars['bar3'].bufferValue"
                ><b class="white--text"
                  >{{ progressBars["bar3"].versicherungen }} Versicherungen
                  geprüft</b
                >
              </v-progress-linear>
            </v-col>
          </v-row>

          <!-- ENDE -->
          <v-row>
            <v-col cols="12" md="3"><b>Parodontosebehandlung</b></v-col>
            <v-col cols="12" md="2"
              ><b class="blue--text" v-if="berechnung.ber_zb_3 == 1"
                >Ja, laufende Parodontosebehandlung</b
              >
              <b class="blue--text" v-if="berechnung.ber_zb_3 == 0">Nein</b>
            </v-col>
            <v-col cols="12" md="2"
              ><b class="text-green"
                >{{ progressBars["bar4"].value }} %</b
              ></v-col
            >
            <v-col cols="12" md="5" class="pt-4">
              <v-progress-linear
                height="28"
                color="green"
                v-model="progressBars['bar4'].value"
                :buffer-value="progressBars['bar4'].bufferValue"
                ><b class="white--text"
                  >{{ progressBars["bar4"].versicherungen }} Versicherungen
                  geprüft</b
                >
              </v-progress-linear>
            </v-col>
          </v-row>

          <!-- ENDE -->
          <v-row>
            <v-col cols="12" md="3"><b>Zahnfehlstellung</b></v-col>
            <v-col cols="12" md="2"
              ><b class="blue--text" v-if="berechnung.ber_zahnfehl == 1"
                >Ja, Fehlstellung vorhanden</b
              >
              <b class="blue--text" v-if="berechnung.ber_zahnfehl == 0">Nein</b>
            </v-col>
            <v-col cols="12" md="2"
              ><b class="text-green"
                >{{ progressBars["bar5"].value }} %</b
              ></v-col
            >
            <v-col cols="12" md="5" class="pt-4">
              <v-progress-linear
                height="28"
                color="green"
                v-model="progressBars['bar5'].value"
                :buffer-value="progressBars['bar5'].bufferValue"
              >
                <b class="white--text"
                  >{{ progressBars["bar5"].versicherungen }} Versicherungen
                  geprüft</b
                >
              </v-progress-linear>
            </v-col>
          </v-row>
          <!-- ENDE -->

          <!-- ENDE -->
          <v-row>
            <v-col cols="12" md="3"><b>Fehlende Zähne</b></v-col>
            <v-col cols="12" md="2"
              ><b class="blue--text" v-if="berechnung.ber_ze_ja == 1"
                >Ja, fehlende Zähne</b
              >
              <b class="blue--text" v-if="berechnung.ber_ze_ja == 0">Nein</b>
            </v-col>
            <v-col cols="12" md="2"
              ><b class="text-green"
                >{{ progressBars["bar6"].value }} %</b
              ></v-col
            >
            <v-col cols="12" md="5" class="pt-4">
              <v-progress-linear
                height="28"
                color="green"
                v-model="progressBars['bar6'].value"
                :buffer-value="progressBars['bar6'].bufferValue"
                ><b class="white--text"
                  >{{ progressBars["bar6"].versicherungen }} Versicherungen
                  geprüft</b
                >
              </v-progress-linear>
            </v-col>
          </v-row>

          <!-- ENDE -->
          <v-row>
            <v-col cols="12" md="3"><b>Anzahl Lücken</b></v-col>
            <v-col cols="12" md="2"
              ><b class="blue--text">{{ berechnung.ber_luecke }}</b>
            </v-col>
            <v-col cols="12" md="2"
              ><b class="text-green"
                >{{ progressBars["bar7"].value }} %</b
              ></v-col
            >
            <v-col cols="12" md="5" class="pt-4">
              <v-progress-linear
                height="28"
                color="green"
                v-model="progressBars['bar7'].value"
                :buffer-value="progressBars['bar7'].bufferValue"
                ><b class="white--text"
                  >{{ progressBars["bar7"].versicherungen }} Versicherungen
                  geprüft</b
                >
              </v-progress-linear>
            </v-col>
          </v-row>
          <!-- ENDE -->
          <v-row>
            <v-col cols="12" md="3"
              ><b>Mitversicherung fehlender Zähne</b></v-col
            >
            <v-col cols="12" md="2"
              ><b v-if="berechnung.ber_luecke_vers == 1"
                >Ja, fehlende Zähne sollen mitversichert werden</b
              >
              <b class="blue--text" v-if="berechnung.ber_luecke_vers == 0"
                >Nein</b
              >
            </v-col>
            <v-col cols="12" md="2"
              ><b class="text-green"
                >{{ progressBars["bar8"].value }} %</b
              ></v-col
            >
            <v-col cols="12" md="5" class="pt-4">
              <v-progress-linear
                height="28"
                color="green"
                v-model="progressBars['bar8'].value"
                :buffer-value="progressBars['bar8'].bufferValue"
                ><b class="white--text"
                  >{{ progressBars["bar8"].versicherungen }} Versicherungen
                  geprüft</b
                >
              </v-progress-linear>
            </v-col>
          </v-row>

          <!-- ENDE -->
          <v-row>
            <v-col cols="12" md="3"><b>Anzahl Prothesen</b></v-col>
            <v-col cols="12" md="2"
              ><b class="blue--text">{{ berechnung.ber_zahnersetzt }}</b>
            </v-col>
            <v-col cols="12" md="2"
              ><b class="text-green"
                >{{ progressBars["bar9"].value }} %</b
              ></v-col
            >
            <v-col cols="12" md="5" class="pt-4">
              <v-progress-linear
                height="28"
                color="green"
                v-model="progressBars['bar9'].value"
                :buffer-value="progressBars['bar9'].bufferValue"
                ><b class="white--text"
                  >{{ progressBars["bar9"].versicherungen }} Versicherungen
                  geprüft</b
                >
              </v-progress-linear>
            </v-col>
          </v-row>

          <!-- ENDE -->
          <v-row>
            <v-col cols="12" md="3"><b>Anzahl Kronen</b></v-col>
            <v-col cols="12" md="2"
              ><b class="blue--text">{{ berechnung.ber_kronen }}</b>
            </v-col>
            <v-col cols="12" md="2"
              ><b class="text-green"
                >{{ progressBars["bar10"].value }} %</b
              ></v-col
            >
            <v-col cols="12" md="5" class="pt-4">
              <v-progress-linear
                height="28"
                color="green"
                v-model="progressBars['bar10'].value"
                :buffer-value="progressBars['bar10'].bufferValue"
                ><b class="white--text"
                  >{{ progressBars["bar10"].versicherungen }} Versicherungen
                  geprüft</b
                >
              </v-progress-linear>
            </v-col>
          </v-row>

          <!-- ENDE -->
          <v-row>
            <v-col cols="12" md="3"><b>Anzahl Brücken</b></v-col>
            <v-col cols="12" md="2"
              ><b class="blue--text">{{ berechnung.ber_bruecke }}</b>
            </v-col>
            <v-col cols="12" md="2"
              ><b class="text-green"
                >{{ progressBars["bar11"].value }} %</b
              ></v-col
            >
            <v-col cols="12" md="5" class="pt-4">
              <v-progress-linear
                height="28"
                color="green"
                v-model="progressBars['bar11'].value"
                :buffer-value="progressBars['bar11'].bufferValue"
                ><b class="white--text"
                  >{{ progressBars["bar11"].versicherungen }} Versicherungen
                  geprüft</b
                >
              </v-progress-linear>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
  import { reactive, ref, defineProps, onMounted, onBeforeUnmount } from "vue";
  import { useMarketingDataStore } from "@/stores/marketingData";
  const marketingDataStore = useMarketingDataStore();
  const props = defineProps({
    berechnung: Object,
  });

  console.log(props.berechnung.value);

  //const fullPage = ref(true);
  const isLoading = ref(true);

  const progressBars = reactive(
    Object.fromEntries(
      Array.from({ length: 11 }, (_, index) => [
        `bar${index + 1}`,
        { value: 10, interval: null, bufferValue: 100, versicherungen: 0 },
      ])
    )
  );

  function startBuffer(bar, abstand) {
    clearInterval(progressBars[bar].interval);
    progressBars[bar].interval = setInterval(() => {
      const barData = progressBars[bar];
      if (barData.versicherungen < 44) {
        barData.versicherungen++;
        barData.value += 2;
      }
      if (barData.versicherungen >= 44) {
        barData.versicherungen = 45;
        barData.value = 100;
      }
    }, abstand);
  }

  onMounted(() => {
    Object.entries(progressBars).forEach(([key], index) => {
      startBuffer(key, 80 + index * 20);
    });
  });

  onBeforeUnmount(() => {
    Object.values(progressBars).forEach((bar) => {
      clearInterval(bar.interval);
    });
  });
</script>
