<template>
  <div>
    <span style="font-size:18px;">Als <strong>"Brücken"</strong> anzugeben sind</span>
    <br />
    <ul>
      <li>die Anzahl der tatsächlich durch eine <strong>Brücke ersetzten Zähne</strong></li>
      <li>sowie die <strong>Nachbarzähne</strong> der durch eine Brücke ersetzten Zähne. (sog. Ankerzähne bzw. Brückenpfeiler)</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'bruecke-info',
  components: {},
}
</script>

<style scoped>
</style>
