import { defineStore } from "pinia";

export const useLoaderStore = defineStore("loaderStore", {
  state: () => ({
    loadSearch: false,
    loadVipInsert: false,
    load: false, // Initialisiert mit false, um das Laden nicht sofort anzuzeigen
    message: "Der Inhalt wird geladen.", // Standardfehlermeldung
  }),
  actions: {
    startDialog(load) {
      if (load == "search") {
        this.loadSearch = true;
      }
      if (load == "vipinsert") {
        this.loadVipInsert = true;
      }
    },
    stopDialog(load) {
      if (load == "search") {
        this.loadSearch = false;
      }
      if (load == "vipinsert") {
        this.loadVipInsert = false;
      }
    },

    setLoader(load, message = "") {
      this.load = load;
      // Verwende die übergebene Nachricht, falls vorhanden, sonst behalte die aktuelle Nachricht bei
      if (message) this.message = message;
    },
    clearLoader() {
      this.load = false; // Setzt `load` auf false statt auf null
      this.message = "Der Inhalt wird geladen."; // Setze die Nachricht zurück auf den Standardwert
    },
  },
});
